<template>
  <div class="card-body">
    <div
      class="
        d-flex
        flex-row
        chart-header
        justify-content-between
        align-content-center
      "
    >
      <div class="w-100">
        <div class="d-flex align-items-center justify-content-between">
          <h4 class="card-title no-select me-auto">
            <!-- {{ metric == "average" ? "Avg. Spend per Customer" : "Total Spend" }} -->
            Users per age
          </h4>

          <InfoPopover
            class="pb-2"
            theme="sea"
            title="Users per age"
            content="Based on the time period chosen , the number of users by age group is displayed."
          />
        </div>

        <div class="card-divider m-0 p-0 py-2 mt-1">
          <span>
            <small>{{ timePeriod }}</small></span
          >
        </div>
      </div>
    </div>

    <div v-if="data.length !== 0" class="pt-3">
      <BarChart :class="['h30v']" :chart-data="chartData" :options="options" />
    </div>

    <div v-else>
      <CardsEmptyState min-height="35" />
    </div>
  </div>
</template>

<script>
import { computed, inject, reactive, ref } from "vue";
import { BarChart } from "vue-chart-3";
import "chartjs-adapter-moment";
import { useStore } from "vuex";
import "chartjs-plugin-style";
import InfoPopover from "./InfoPopover.vue";
import CardsEmptyState from "./CardsEmptyState.vue";

export default {
  components: { BarChart, InfoPopover, CardsEmptyState },
  props: {
    metric: {
      default: "average",
    },
    timePeriod: {
      type: String,
      default: "Last 30 days",
    },
    data: {
      type: Array,
      default: () => [],
    },
    period: {
      default: 30,
    },
    loading: {
      default: false,
    },
  },
  setup(props) {
    // eslint-disable-next-line no-unused-vars
    const render = ref(0);
    const capitalizeFirstLetter = inject("capitalizeFirstLetter");
    const compactNumber = inject("compactNumber");

    const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const store = useStore();

    const currency = computed(() => {
      return store.state.currency;
    });

    const rawChartData = computed(() => {
      //[1] sort the data labels in ascending order
      let dataList = props.data ?? [];

      dataList = dataList.map((ageObject) => {
        let labelIndex = ageObject?.tenant_customers_age_label;
        labelIndex = labelIndex.split("-");

        if (!isNaN(Number(labelIndex[0]))) {
          labelIndex = Number(labelIndex[0]);
        } else if (labelIndex[0] === "below 21") {
          labelIndex = 20;
        } else if (labelIndex[0] === "Over 60") {
          labelIndex = 60;
        }

        return {
          labelIndex,
          ...ageObject,
        };
      });

      //sor the data in ascebding order based on index label
      dataList = dataList.sort((a, b) => {
        const current = a.labelIndex;
        const next = b.labelIndex;

        return current - next;
      });

      //[2] prep for the chart
      let data = [];
      let dataLabels = [];

      for (let i in dataList) {
        dataLabels.push([dataList[i].tenant_customers_age_label, "years"]);
        data.push(dataList[i].tenant_customers_count);
      }

      return { dataset: [...data], labels: [...dataLabels] };
    });

    const chartData = computed(() => ({
      labels: rawChartData.value.labels,
      datasets: [
        {
          label: "Current",
          data: rawChartData.value.dataset,
          hoverBackgroundColor: "#3f7b76",
          backgroundColor: "#469b93",
          borderWidth: 1,
          borderRadius: 5,
          tension: 0.05,
        },
      ],
    }));

    const options = ref({
      parsing: {
        xAxisKey: "label",
        yAxisKey: "value",
      },
      plugins: {
        legend: {
          display: false,
          position: "top",
          align: "start",
          reverse: true,
          labels: {
            usePointStyle: true,
            boxWidth: 10,
            boxHeight: 10,
          },
        },
        tooltip: {
          enabled: true,
          // position: "nearest",
          usePointStyle: true,
          backgroundColor: "#f8f9fb",
          titleColor: "black",
          bodyColor: "black",
          // padding: 15,
          bodyFont: {
            size: 12,
            lineHeight: 1.9,
          },
          displayColors: true,
          callbacks: {
            title: (ctx) => {
              return;
            },
            label: (context) => {
              return `     ${capitalizeFirstLetter(
                context.label.split(",")[0]
              )}    -    ${context.formattedValue} Users`;
            },
            labelPointStyle: function (context) {
              return {
                pointStyle: "circle",
                rotation: 0,
              };
            },
          },
        },
      },
      scales: {
        y: {
          min: 0,
          ticks: {
            stepSize: 1000,
            padding: 4,
            font: {
              size: 13,
            },
            color: "#200e32",
            callback: function (value, index, values) {
              return compactNumber(value);
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        x: {
          grid: {
            display: false,
            drawBorder: false,
            // drawTicks: false,
          },
          ticks: {
            padding: 4,
            font: {
              size: 13,
            },
            color: "#200e32",
            callback: function (value, index, values) {
              return [
                capitalizeFirstLetter(this.getLabelForValue(value)[0]) ?? "0",
                this.getLabelForValue(value)[1] ?? "years",
              ]; //provide an Array to Have muliple lines
            },
          },
        },
      },
    });
    return {
      chartData,
      options,
      render,
      currency,
      numberWithCommas,
    };
  },
};
</script>

<style scoped>
.chart-header {
  border-bottom: 1px solid #dee2e6;
}
.active-text {
  color: #469b93 !important;
}

.alt-text {
  color: #97aabd !important;
}
.h330 {
  height: 330px;
}
.h30v {
  height: 30vh;
}
.percentage {
  background-color: var(--sea-10);
  color: var(--sea);
  font-size: 0.8rem !important;
  padding: 3px 7px;
  border-radius: 7px;
}
.negative {
  background-color: rgba(223, 41, 53, 0.1);
  color: #df2935ec;
}
</style>
