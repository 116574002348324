<template>
  <div class="d-flex align-items-center w-100 gap-3">
    <TextInput
      :id="id"
      v-model="searchQry"
      :name="id"
      type="text"
      :placeholder="placeholder"
      class="flex-grow-1"
    />

    <ActionButton
      v-if="showButton"
      type="button"
      label="Search"
      class="btn-primary filter-btn"
      :disabled="!searchQry"
      @click="applySearch"
    />
  </div>
</template>

<script>
import { ref, watch } from "vue";
import ActionButton from "./helpers/ActionButton.vue";
import TextInput from "./helpers/TextInput.vue";
export default {
  components: { TextInput, ActionButton },
  props: {
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      default: "",
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "Serach for Brands",
    },
  },
  emits: ["search", "update:modelValue"],
  setup(props, { emit }) {
    const searchQry = ref("");

    const applySearch = () => {
      if (searchQry.value.length !== 0) emit("search", searchQry.value);
    };

    watch(searchQry, (qry) => {
      emit("update:modelValue", qry);
    });

    return { searchQry, applySearch };
  },
};
</script>

<style lang="scss" scoped>
.filter-btn {
  font-size: 0.93rem !important;
  padding: 0.8rem 2.8rem !important;
  height: 100% !important;
}
</style>
