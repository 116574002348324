<template>
  <div
    v-if="status"
    class="cp fd-label text-center px-2"
    @click.stop="$emit('on-view-status', data)"
  >
    <span>{{ status }}</span>
  </div>

  <div
    v-else
    class="cp text-center px-2"
    @click.stop="$emit('on-report', data)"
  >
    <img
      src="@/assets/images/svg/components/report_tx_icon.svg"
      alt="Report a tx icon"
      width="23"
    />
  </div>
</template>

<script>
import { computed, inject } from "vue";

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["on-view-status", "on-report"],
  setup(props) {
    const capitalizeFirstLetter = inject("capitalizeFirstLetter");

    const status = computed(() => {
      let label = props.data?.review?.status;
      if (label === null) return null;

      label = label === "pending" ? "Reported" : label;
      return capitalizeFirstLetter(label.trim());
    });

    const statusColor = computed(() => {
      let textColor = "white";
      let bgColor = "#469a93";

      if (!status.value) return { textColor, bgColor };

      switch (status.value.trim().toLowerCase()) {
        case "reported":
          textColor = "#999999";
          bgColor = "#99999920";
          break;
        case "pending":
          textColor = "#999999";
          bgColor = "#99999920";
          break;

        case "closed":
          textColor = "#e87f71";
          bgColor = "#e87f7120";
          break;

        case "updated":
          textColor = "#469a93";
          bgColor = "#469a9320";
          break;
        default:
          break;
      }

      return { textColor, bgColor };
    });

    return { statusColor, status };
  },
};
</script>

<style scoped lang="scss">
div.fd-label {
  background-color: v-bind("statusColor?.bgColor");
  border-radius: 30px;
  font-size: 0.8rem;
  line-height: 1.9rem;

  &:hover span {
    text-decoration: underline;
  }

  span {
    color: v-bind("statusColor.textColor");
  }
}
</style>
