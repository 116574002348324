<template>
  <div
    class="
      w-100
      d-flex
      justify-content-between
      align-items-center
      feedback-popup-text-value
    "
    :class="{ 'status-input': type }"
  >
    <label class="py-1 me-3">{{ label }}:</label>
    <p v-if="value" class="text-wrap">{{ value }}</p>
    <!-- <BrandImage
      v-if="brandLogo || categoryId"
      :url="'/' + brandLogo"
      :category-id="categoryId"
    /> -->
    <LogoIcon
      v-if="brandLogo || categoryId"
      :src="`${$store.getters.brandLogosBaseURL}${brandLogo}`"
      :is-brand-icon="!!brandLogo"
    />
  </div>
</template>

<script>
import LogoIcon from "./LogoIcon.vue";
// import BrandImage from "../Helpers/BrandImage.vue";

export default {
  components: { LogoIcon },
  props: {
    label: {
      type: String,
      default: "NA",
    },
    value: {
      type: String,
      default: "",
    },
    brandLogo: {
      type: String,
      default: "",
    },
    categoryId: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
      validator(value) {
        return value
          ? ["updated", "closed", "pending", "reported"].includes(value)
          : true;
      },
    },
  },
  computed: {
    statusColor() {
      let textColor = "#469a93";

      switch (this.type) {
        case "pending":
          textColor = "#999999";
          break;

        case "closed":
          textColor = "#e87f71";
          break;

        case "updated":
          textColor = "#469a93";
          break;

        default:
          break;
      }

      return { textColor };
    },
  },
};
</script>

<style scoped lang="scss">
.feedback-popup-text-value {
  font-size: 0.85rem;
  p {
    opacity: 0.5;
    word-break: break-all;
    max-height: 80px;
    overflow-y: auto;
  }
  &.status-input p {
    opacity: 1;
    color: v-bind("statusColor.textColor");
  }
}
</style>
