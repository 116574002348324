import { createStore } from "vuex";
import RestService from "@/services/RestService.js";
import authStore from "./authStore";
import overViewStore from "./overViewStore";
import usersViewStore from "./usersViewStore";
import settingsStore from "./settingsStore";

export default createStore({
  state: {
    cancelTokens: [],
    currency: "AED",
    userPermission: [],
    user: {},
    filters: {},
    showSideBar: false,
    timeFilters: {
      dropDownLabel: "Last 30 Days",
      periodFilter: 30,
    },
    pdfData: {},
    loadingDahboardData: false,
    loadingCategoriesData: false,
    loadingBrandsData: false,
    //PDF export data states
    categoriesData: {},
    brandsData: {},
  },
  getters: {
    cancelTokens(state) {
      return state.cancelTokens;
    },
    brandLogosBaseURL() {
      return process.env.VUE_APP_IMG_ORIGINAL_BASE_URL;
    },
  },
  mutations: {
    ADD_CANCEL_TOKEN(state, token) {
      state.cancelTokens.push(token);
    },
    CLEAR_CANCEL_TOKENS(state) {
      state.cancelTokens = [];
    },

    updateUser(state, payload) {
      state.user = { ...payload };
    },
    updateLoadingDahboardData(state, payload) {
      state.loadingDahboardData = payload;
    },
    updateLoadingCategories(state, payload) {
      state.loadingCategoriesData = payload;
    },
    updateLoadingBrandsData(state, payload) {
      state.loadingBrandsData = payload;
    },
    updatePdfData(state, payload) {
      state.pdfData = { ...payload };
    },
    updateCurrency(state, payload) {
      state.currency = `${payload} `;
    },
    updateUserPermission(state, payload) {
      state.userPermission = payload ?? [];
    },
    updateFilters(state, payload) {
      state.filters = { ...payload };
    },
    updateTimeFilters(state, payload) {
      state.timeFilters = { ...payload };
    },
    updateCategoriesData(state, payload) {
      state.categoriesData = { ...payload };
    },
    updateBrandsData(state, payload) {
      state.brandsData = { ...payload };
    },
    updateShowSideBar(state, payload) {
      state.showSideBar = payload;
    },
  },
  actions: {
    CANCEL_PENDING_REQUESTS(context) {
      // Cancel all request where a token exists
      context.state.cancelTokens.forEach((request, i) => {
        request?.abort();
      });

      // Reset the cancelTokens store
      context.commit("CLEAR_CANCEL_TOKENS");
    },
    logout(context) {
      //reset filters
      context.commit("updateFilters", {});
      context.commit("updateTimeFilters", {
        dropDownLabel: "Last 30 Days",
        periodFilter: 30,
      });
    },
  },
  modules: {
    overViewStore,
    usersViewStore,
    settingsStore,
    msAzureAuth: authStore.msAzureAuth,
  },
});
