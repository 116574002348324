<template>
  <div class="w-100 h-100 d-flex flex-column">
    <div v-if="sortedList.length !== 0" class="d-flex flex-column">
      <div class="d-flex gap-3 justify-content-start align-items-start">
        <BaseSortButton
          :label="
            (metricFilters?.key === 'average' ? 'Average' : 'Total') + ' Amount'
          "
          :sort-is-active="sortOption == sortOptions.spend"
          :reverse-sorting="sortOption == 'reverse-0'"
          @click="sortData(sortOptions.spend)"
        />
        <BaseSortButton
          label="No. of customers"
          :sort-is-active="sortOption == sortOptions.customers"
          :reverse-sorting="sortOption == 'reverse-1'"
          @click="sortData(sortOptions.customers)"
        />
      </div>
      <div class="py-1">
        <SpendCardTopBar
          :title="selectedBarComputed?.label"
          :component-name="componentName"
          :amount="
            metricFilters.key === 'average'
              ? selectedBarComputed?.avg_amount
              : selectedBarComputed?.total_amount
          "
          :amount-diff-sign="amountDiff?.diffSign"
          :users="selectedBarComputed?.users_count"
          :users-diff-sign="usersDiff?.diffSign"
          @amount-clicked="handleAmountClick"
          @users-clicked="handleUsersClick"
        />
      </div>
      <BaseHorizontalBarChart
        :key="renderKey"
        :data="[...sortedList]"
        @on-update="setSelectedValue"
      />
    </div>
    <BaseEmptyState v-else min-height="45vh" />

    <div
      v-if="viewAllLabel"
      class="w-100 d-flex justify-content-end align-items-start"
    >
      <BaseSmallButton
        :label="viewAllLabel"
        :end-icon="require('@/assets/images/svg/components/right-arrow.svg')"
        @click="$emit('on-viewall-click')"
      />
    </div>
  </div>
</template>

<script>
import BaseSmallButton from "./helpers/BaseSmallButton.vue";
import BaseEmptyState from "./helpers/BaseEmptyState.vue";
import SpendCardTopBar from "./SpendCardTopBar.vue";
import BaseSortButton from "./helpers/BaseSortButton.vue";
import { computed, reactive, ref, watch } from "vue";
import BaseHorizontalBarChart from "./charts/BaseHorizontalBarChart.vue";
import { useStore } from "vuex";
import { useDiffCalculator } from "@/composables/Helpers";
import { useRouter } from "vue-router";

export default {
  components: {
    BaseHorizontalBarChart,
    BaseSortButton,
    SpendCardTopBar,
    BaseEmptyState,
    BaseSmallButton,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    viewAllLabel: {
      type: String,
      default: "",
    },
    componentName: {
      type: String,
      require: true,
      default: "",
    },
  },
  emits: ["on-viewall-click"],
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const renderKey = ref(0);
    const sortOption = ref(0);
    const sortOptions = reactive({
      spend: 0,
      customers: 1,
    });
    const selectedBar = ref(null);

    const selectedBarComputed = computed(() => {
      if (sortedList.value.length !== 0)
        return { ...(selectedBar.value ?? sortedList.value[0]) };

      return { ...selectedBar.value };
    });
    const metricFilters = computed(
      () => store.state.overViewStore.metricFilters
    );
    const chartData = computed(() => {
      const dataList = props.data;

      if (dataList.length === 0) return [];

      const amountKey =
        metricFilters.value?.key === "average" ? "avg_amount" : "total_amount";

      return dataList.map((dataPoint) => {
        return {
          x: Math.round(Math.abs(dataPoint[amountKey] ?? 0)),
          y: dataPoint?.label ?? "--",
          ...dataPoint,
        };
      });
    });
    const sortedList = computed(() => {
      const propList = chartData.value;
      // exit if empty
      if (propList.length === 0) return [];

      // if (sortOption.value.toString().includes("reverse")) {
      //   return propList.reverse();
      // }

      return propList.sort((a, b) => {
        let res = 0;

        switch (sortOption.value) {
          case sortOptions.spend:
            res = Math.abs(getSpend(b)) - Math.abs(getSpend(a));
            break;

          case `reverse-${sortOptions.spend}`:
            res = Math.abs(getSpend(a)) - Math.abs(getSpend(b));
            break;

          case sortOptions.customers:
            if (a.users_count || b.users_count)
              res =
                Math.abs(b?.users_count ?? 0) - Math.abs(a?.users_count ?? 0);
            break;

          case `reverse-${sortOptions.customers}`:
            if (a.users_count || b.users_count)
              res =
                Math.abs(a?.users_count ?? 0) - Math.abs(b?.users_count ?? 0);
            break;

          default:
            break;
        }

        return res;
      });
    });

    const amountDiff = computed(() => {
      if (metricFilters.value?.key === "average") {
        return useDiffCalculator(
          selectedBarComputed.value?.avg_amount,
          selectedBarComputed.value?.rel_avg_amount
        );
      }

      return useDiffCalculator(
        selectedBarComputed.value?.total_amount,
        selectedBarComputed.value?.rel_total_amount
      );
    });
    const usersDiff = computed(() => {
      return useDiffCalculator(
        selectedBarComputed.value?.users_count,
        selectedBarComputed.value?.rel_users_count
      );
    });

    function sortData(option) {
      sortOption.value =
        sortOption.value === option ? `reverse-${option}` : option;
    }
    function getSpend(option) {
      if (metricFilters.value.key === "average") {
        return option?.avg_amount ?? 0;
      }

      return option?.total_amount ?? 0;
    }
    function setSelectedValue(val) {
      selectedBar.value = { ...val };
    }
    function viewCurrencyTxs(data) {
      store.commit("updateShowFiltersBar", true);
      router.push({
        name: "MainTransactions",
        params: {
          filters: JSON.stringify({
            multi_currency: [{ key: data?.label, value: data?.label }],
          }),
        },
      });
    }
    function viewTxTypeTxs(data) {
      store.commit("updateShowFiltersBar", true);
      router.push({
        name: "MainTransactions",
        params: {
          filters: JSON.stringify({
            transaction_types: [
              {
                key: String(data?.tenant_transaction_type_id ?? ""),
                value: String(data?.label ?? ""),
              },
            ],
          }),
        },
      });
    }
    // function viewTypesTxs(data) {}
    // function viewCurrencyUsers(data) {}
    // function viewTypesUsers(data) {}

    function handleAmountClick() {
      if (props.componentName === "currencies")
        viewCurrencyTxs(selectedBarComputed.value);
      else if (props.componentName === "types")
        viewTxTypeTxs(selectedBarComputed.value);

      // if (props.for === "types") viewTypesTxs(selectedBarComputed.value);
    }
    function handleUsersClick() {
      console.log("Users clicked");
      // console.log(selectedBarComputed.value);

      // if (props.for === "currencies")
      //   viewCurrencyUsers(selectedBarComputed.value);

      // if (props.for === "types") viewTypesUsers(selectedBarComputed.value);
    }

    watch([sortOption, () => metricFilters.value.key], () => {
      if (sortedList.value.length !== 0)
        selectedBar.value = sortedList.value[0];
      renderKey.value += 1;
    });

    return {
      chartData,
      sortedList,
      sortOption,
      sortOptions,
      sortData,
      metricFilters,
      setSelectedValue,
      renderKey,
      selectedBarComputed,
      amountDiff,
      usersDiff,
      handleAmountClick,
      handleUsersClick,
    };
  },
};
</script>

<style lang="scss" scoped></style>
