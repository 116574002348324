<template>
  <div class="card p-3" :class="{ greenBG: theme === 'green' }">
    <div
      class="
        card-body
        d-flex
        flex-column
        justify-content-between
        align-items-stretch
        gap-3
      "
    >
      <div class="d-flex justify-content-between align-items-center">
        <h4
          class="card-title no-select"
          :class="{ 'text-white': theme === 'green' }"
        >
          {{ title }}
        </h4>

        <InfoPopover
          data-html2canvas-ignore
          :theme="title === 'Users' ? 'white' : 'sea'"
          class="pb-2"
          :title="description"
          :content="
            title === 'Users'
              ? view == 'Brands'
                ? 'Based on the time period chosen, the number of users making transactions on this brand is displayed.'
                : 'Based on the time period chosen, the number of users making transactions on this category is displayed.'
              : view == 'Brands'
              ? 'Based on the time period chosen , the total number transactions on this brand is displayed.'
              : 'Based on the time period chosen , the total number transactions on this category is displayed.'
          "
        />
      </div>

      <h5 :class="{ 'text-white': theme === 'green' }">
        {{ description }}
      </h5>

      <div
        class="d-flex justify-content-between align-items-center m-0 p-0"
        :class="{ 'text-white': theme === 'green' }"
      >
        <h2>
          <small>{{ numberWithCommas(count ?? 0) }}</small>
        </h2>

        <div>
          <span
            class="percentage rounded fw-bold w-100"
            :class="{
              negative: (countPercent ?? 0) < 0 && theme !== 'green',
              'negative-green-card':
                (countPercent ?? 0) < 0 && theme === 'green',
              'text-white': theme === 'green',
              'percentage-green': theme === 'green',
            }"
          >
            {{ (countPercent ?? 0) < 0 ? "-" : "+"
            }}{{ Math.abs(countPercent).toFixed(0) ?? 0 }}%
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoPopover from "./InfoPopover.vue";

export default {
  components: { InfoPopover },
  inject: ["numberWithCommas"],
  props: {
    title: {
      type: String,
      default: "Users",
    },
    view: {
      type: String,
      default: "Users",
    },
    description: {
      type: String,
      default: "Number of users",
    },
    count: {
      type: [Number, String],
      default: 0,
    },
    countPercent: {
      type: [Number, String],
      default: 0,
    },
    theme: {
      type: String,
      default: "white",
    },
  },
};
</script>

<style scoped>
h2 {
  padding: 0 !important;
  margin: 0 !important;
}

.percentage {
  background-color: var(--sea-10);
  color: var(--sea);
  font-size: 0.7rem !important;
  padding: 3px 8px !important;
  margin: 0px !important;
  border-radius: 7px;
}
.percentage-green {
  background-color: var(--sea);
}
.negative {
  background-color: rgba(223, 41, 53, 0.1);
  color: #df2935ec !important;
}
.negative-green-card {
  background-color: rgba(223, 41, 53, 0.55);
  color: white !important;
}
.greenBG {
  background-color: var(--green) !important;
}
.text-white h2 small {
  color: white !important;
}
</style>
