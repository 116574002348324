<template>
  <div
    class="
      d-flex
      flex-row flex-wrap
      justify-content-between
      align-items-center
      gap-5 gap-lg-0
    "
  >
    <BaseSummaryCard
      class="ov-summ-card"
      title="Number of Txns"
      :amount="compactNumber(data?.account_transactions_count ?? 0)"
      :is-trending-positively="txCountDiff?.diffSign"
      :icon="require('@/assets/images/svg/components/Transactions.svg')"
      :is-clickable="true"
      @click="$emit('view-txs-clicked')"
    />
    <BaseSummaryCard
      class="ov-summ-card"
      title="Total Amount Spent"
      :amount="compactNumber(Math.abs(data?.account_total_spend ?? 0), 2)"
      :is-trending-positively="totalSpendDiff?.diffSign"
      :show-currency="true"
      :currency="$store.getters.selectedUserCurrency ?? 'AED'"
      :icon="require('@/assets/images/svg/components/Fixed-amount.svg')"
      :is-clickable="true"
      @click="$emit('view-txs-clicked')"
    />
    <BaseSummaryCard
      class="ov-summ-card"
      title="Avg Spent / Day"
      :amount="compactNumber(Math.abs(data?.account_avg_spend_per_day ?? 0), 2)"
      :is-trending-positively="avgDaySpendDiff?.diffSign"
      :show-currency="true"
      :currency="$store.getters.selectedUserCurrency ?? 'AED'"
      :icon="require('@/assets/images/svg/components/Average.svg')"
      :is-clickable="true"
      @click="$emit('view-txs-clicked')"
    />
  </div>
</template>
<script>
import BaseSummaryCard from "../components/helpers/BaseSummaryCard.vue";
import { computed, inject } from "vue";
import { useDiffCalculator } from "../composables/Helpers";
export default {
  components: { BaseSummaryCard },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["view-txs-clicked"],
  setup(props) {
    const compactNumber = inject("compactNumber");

    const txCountDiff = computed(() => {
      return useDiffCalculator(
        props.data?.account_transactions_count,
        props.data?.comparison_period_account_transactions_count
      );
    });

    const totalSpendDiff = computed(() => {
      return useDiffCalculator(
        props.data?.account_total_spend,
        props.data?.comparison_period_account_total_spend
      );
    });

    const avgDaySpendDiff = computed(() => {
      return useDiffCalculator(
        props.data?.account_avg_spend_per_day,
        props.data?.comparison_period_account_avg_spend_per_day
      );
    });

    return {
      compactNumber,
      txCountDiff,
      totalSpendDiff,
      avgDaySpendDiff,
    };
  },
};
</script>
<style scoped>
.ov-summ-card {
  /* border: 2px solid red; */
  width: fit-content;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media screen and (max-width: 890px) {
  .ov-summ-card {
    width: 45%;
  }
}
@media screen and (max-width: 750px) {
  .ov-summ-card {
    width: 100%;
  }
}
</style>
