import RestService from "../RestService";

class SettingsAPI {
  getTeamsList() {
    return RestService.getWithParams("/teams");
  }
  getRolesList() {
    return RestService.getWithParams("/roles");
  }
}

export default new SettingsAPI();
