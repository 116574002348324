// import { useMockFetch } from "@/composables/Helpers";
import RestService from "../RestService";

class TrendsAPI {
  getBrandsPattern(filters, periodRoute) {
    const endpoint = `/trends/brands/${periodRoute}`;
    return RestService.getWithParams(endpoint, filters);
  }
}

export default new TrendsAPI();
