<template>
  <div class="sidebar-link-box">
    <router-link :to="{ name: route.name }" @click="trackSideLinks(route.name)">
      <tippy v-if="!('showOnTrial' in route) || !route.showOnTrial">
        <div
          class="
            sidebar-link
            route-tile
            d-flex
            align-items-center
            w-100
            gap-2
            cp
          "
          :class="{
            'route-active': isActiveRoute(route),
            'route-with-sub-list':
              'children' in route && Object.keys(route?.children).length !== 0,
            'justify-content-center': !showSideBar,
            'show px-4': showSideBar,
          }"
        >
          <!--Route Icon-->
          <img :src="route.icon" class="route-icon-tile" alt="none" />

          <!--Route Name Shows on full-view sidebar-->
          <transition name="route-label text-nowrap">
            <h6 v-if="showSideBar" class="p-0 m-0">
              <small>{{ route.label }}</small>
            </h6>
          </transition>

          <!-- <span
            v-if="showSideBar && !isActiveRoute(route)"
            class="w-100 text-end pi pi-angle-down pe-1"
          /> -->
          <div
            v-if="
              showSideBar &&
              'children' in route &&
              Object.keys(route?.children).length !== 0
            "
            class="flex-grow-1 text-end"
          >
            <img
              class="path-icon"
              :class="{ active: isActiveRoute(route) }"
              src="@/assets/images/svg/new_sidebar/Path.svg"
              alt="path"
              width="9"
            />
          </div>
        </div>

        <!--Route Tippy Shows when closed only-->
        <template #content>
          <div v-if="!showSideBar" class="w-100 route-tippy">
            <small class="">{{ route.label }}</small>
          </div>
        </template>
      </tippy>
    </router-link>

    <div
      v-if="showSideBar"
      :class="{ selected: 'children' in route && isActiveRoute(route) }"
      class="ps-4 my-0 py-0 d-flex flex-column align-items-start sub-route-a cp"
    >
      <div
        v-for="subRoute in subRoutes"
        :key="subRoute.path"
        class="w-100 d-flex align-items-center justify-content-between gap-2"
      >
        <img :src="route.icon" class="route-icon-tile" alt="none" />
        <router-link class="w-100 d-block" :to="{ name: subRoute.name }">
          <span class="sub-route-label m-0 p-0">
            <small> {{ subRoute.label }}</small>
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    route: {
      type: Object,
      required: true,
    },
    showSideBar: {
      type: Boolean,
      required: true,
    },
    isTrialUser: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    selectedRoute() {
      return this.$route;
    },
    subRoutes() {
      if (
        "children" in this.route &&
        Object.keys(this.route?.children).length !== 0
      ) {
        let subRoutes = Object.keys(this.route.children);
        return subRoutes.map((route) => this.route.children[route]);
      }
      return [];
    },
  },
  methods: {
    trackSideLinks(route) {
      this.$gtag.event("MAY23_click_sidebar_link", {
        event_category: "Engagement",
        event_label: "MAY23_click_sidebar_link",
        clicked_link: route,
        value: 1,
      });
    },
    isActiveRoute(routeName) {
      return (
        (this.selectedRoute.matched ?? []).some((_route) =>
          _route.path.includes(routeName.path)
        ) &&
        (routeName.path != "/" ||
          (this.selectedRoute.matched ?? []).some(
            (_route) => _route.name == routeName.name
          ))
      );
    },
  },
};
</script>

<style scoped>
.sub-route-a {
  max-height: 0px;
  visibility: hidden;
}
.sub-route-a .route-icon-tile {
  visibility: hidden;
}
.sub-route-a.selected {
  max-height: 150px;
  overflow: auto;
  visibility: visible;
}

.sub-route-a a {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  width: 100%;
  font-weight: 400;
  color: var(--dark-green-60, #66827f);
  transition: var(--d-ease) !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.sub-route-a a:hover {
  color: var(--green);
  font-weight: 600;
}
.sub-route-a a.router-link-exact-active {
  color: var(--green);
  width: 100%;
  border-right: 4px solid var(--green);
  font-weight: 600;
}
.sub-route-a .sub-route-label {
  font-size: 0.93rem !important;
}
.sub-route-a .router-link-active {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  border: none;
  color: var(--green) !important;
}
</style>

<style>
.sidebar-link {
  margin-top: 0.65rem;
  margin-bottom: 0.65rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  transition: var(--d-ease);
}
.sidebar-link-box sidebar-link {
  transition: var(--d-ease);
}
.sidebar-link-box sidebar-link:hover {
  background-color: rgba(72, 255, 0, 0.8) !important;
}
.route-label-enter-active,
.route-label-leave-active {
  transition: opacity 0.15s;
}

.route-label-enter,
.route-label-leave-to {
  opacity: 0;
}
.route-active {
  background: var(--dark-green-10, #e6eaead9);
}
.route-active.show {
  color: var(--green);
  border-right: 4px solid var(--green);
}
.route-active.show.route-with-sub-list {
  border-right: none;
}
.sidebar-link-box h6 small {
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--dark-green-60, #66827f);
  transition: var(--d-ease);
}

.sidebar-link-box .route-active h6 small,
.sidebar-link-box:hover h6 small {
  font-weight: 600;
  color: var(--green);
}

.route-tile .route-icon-tile {
  width: 26px;
  height: 26px;
  filter: invert(0%) sepia(99%) saturate(20%) hue-rotate(175deg)
    brightness(125%) contrast(100%);
  transition: var(--d-ease);
}
.route-tile .route-icon-logout {
  width: 28px;
  height: 28px;
}
.route-tile:hover .route-icon-tile,
.route-active .route-icon-tile {
  filter: none;
}
.path-icon {
  opacity: 0.55;
  transition: var(--d-ease);
  transform: rotate(0deg);
}
.path-icon.active {
  opacity: 1;
  transform: rotate(180deg);
}
.route-tippy {
  background-color: white;
  color: var(--green);
  padding: 0.4rem 0.75rem;
  margin-top: 0.45rem;
  border-radius: 10px;
  box-shadow: 0px 4px 40px 0px rgba(204, 213, 212, 0.55);
}
</style>
