<template>
  <div class="h-100">
    <div v-if="members.length > 0" class="table-responsive members-table">
      <table class="table align-middle">
        <thead>
          <tr>
            <th scope="col" class="col">Name</th>
            <th scope="col" class="col">Date Added</th>
            <th scope="col" class="col">Role</th>
            <th scope="col" class="col text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="member in members" :key="member.id">
            <td class="text-nowrap">
              <div class="d-flex flex-row">
                <div id="initial-slot" class="me-3 position-relative">
                  <span
                    class="position-absolute top-50 start-50 translate-middle"
                    >{{ getInitials(member) }}</span
                  >
                </div>
                <div
                  class="
                    d-flex
                    flex-column
                    align-items-start
                    justify-content-start
                  "
                >
                  <h6 class="m-0 p-0">
                    {{ capitalizeFirstLetter(member.name ?? "NA") }}
                  </h6>
                  <span class="p-0 m-0">{{ member.email }}</span>
                </div>
              </div>
            </td>
            <td class="text-nowrap members-date">
              <h6>
                {{ member.date_added ? getDateJoined(member) : "--" }}
              </h6>
            </td>
            <td class="text-nowrap">
              <h6>
                {{ member.role ? capitalizeFirstLetter(member.role) : "--" }}
              </h6>
            </td>

            <td :key="contextKey" class="text-nowrap">
              <div class="d-flex justify-content-center align-items-center">
                <tippy class="text-center" :interactive="true">
                  <!-- :trigger-target="['.bi-three-dots', '#ctx-menu']" -->
                  <i
                    v-if="member.email !== user?.email"
                    class="bi bi-three-dots fs-5"
                  ></i>
                  <template #content>
                    <ul id="ctx-menu" class="p-3 rounded-3 bg-light shadow-sm">
                      <li
                        class="d-flex flex-row dropdown-item cp p-2"
                        @click="stageMemberForEdit(member)"
                      >
                        <span><i class="bi bi-pencil me-3"></i></span>
                        <span>Edit</span>
                      </li>
                      <li
                        class="d-flex flex-row dropdown-item cp p-2"
                        @click="deleteMember(member.id)"
                      >
                        <span><i class="bi bi-trash me-3"></i></span>
                        <span>Delete</span>
                      </li>
                      <li
                        class="d-flex flex-row dropdown-item cp p-2"
                        @click="sendPasswordResetEmail(member.email)"
                      >
                        <span><i class="bi bi-arrow-repeat me-3"></i></span>
                        <span>Reset Password</span>
                      </li>
                    </ul>
                  </template>
                </tippy>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      v-else
      class="
        w-100
        h-100
        d-flex
        flex-column
        justify-content-center
        align-items-center
      "
    >
      <h6 class="p-5">No Members To Show</h6>
    </div>
  </div>
</template>

<script>
import { roles } from "@/data/options";
import moment from "moment";

export default {
  inject: ["capitalizeFirstLetter"],
  props: {
    members: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      roleList: roles,
      contextKey: 0,
    };
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },
  methods: {
    getInitials(user) {
      let hasValidName = Boolean(user.name ?? "NA"); //&& Boolean(user.last_name);

      let subject = hasValidName ? `${user.name ?? "NA"}` : user.email ?? "NA";

      if (!hasValidName) {
        if (subject.length > 1) {
          return subject.substring(0, 2).toUpperCase();
        } else {
          return "NA";
        }
      }

      return subject
        .split(" ")
        .slice(0, 2)
        .map((n) => n.charAt(0))
        .join("")
        .toUpperCase();
    },
    getDateJoined(user) {
      let date = user.date_added;
      let dateObj = moment(date, moment.ISO_8601);

      return dateObj.format("D MMM, YYYY");
    },
    getTeamRole(role = 0) {
      let roleLabel = "";

      switch (role) {
        case 0:
          roleLabel = "Owner";
          break;

        case 1:
          roleLabel = "Admin";
          break;

        case 2:
          roleLabel = "Viewer";
          break;
      }

      return roleLabel;
    },
    stageMemberForEdit(user) {
      this.contextKey++;
      this.$emit("stageMemberForEdit", user);
    },
    deleteMember(id) {
      this.contextKey++;
      this.$emit("deleteMember", id);
    },
    sendPasswordResetEmail(email) {
      this.contextKey++;
      this.$emit("sendPasswordResetEmail", email);
    },
  },
};
</script>

<style scoped>
#initial-slot {
  width: 3vw !important;
  height: 3vw !important;
  background-color: rgba(151, 170, 189, 0.16);
  border-radius: 500px;
  color: var(--sea);
}
#initial-slot span {
  font-size: 1.2rem;
}

.members-table .table tbody tr td {
  /* line-height: 3rem; */
  padding: 1.25rem 0.5rem;
}
.members-date {
  color: var(--bluey-grey) !important;
}
.pi-minus-circle {
  font-size: 1.15rem !important;
  color: var(--error) !important;
}
.pi-minus-circle:hover {
  color: var(--green) !important;
}
</style>
