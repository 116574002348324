<template>
  <div
    class="
      d-flex
      flex-column
      justify-content-start
      align-items-start
      w-100
      h-100
    "
  >
    <div
      v-if="sortedList?.length !== 0"
      class="d-flex gap-3 justify-content-start align-items-start"
    >
      <BaseSortButton
        label="No. of Transactions"
        :sort-is-active="sortOption == sortOptions.txCount"
        :reverse-sorting="sortOption == 'reverse-0'"
        @click="sortData(sortOptions.txCount)"
      />
      <BaseSortButton
        :label="
          metric?.key === 'average'
            ? 'Average Spend Per Transaction'
            : 'Total Spend'
        "
        :sort-is-active="sortOption == sortOptions.amount"
        :reverse-sorting="sortOption == 'reverse-1'"
        @click="sortData(sortOptions.amount)"
      />
    </div>

    <UserTopCardCells
      v-if="sortedList?.length !== 0"
      :data="[...sortedList]"
      :currency="$store.getters.selectedUserCurrency ?? 'AED'"
    />
    <BaseEmptyState v-else min-height="45vh" />

    <!-- <div class="w-100 d-flex justify-content-end align-items-start">
      <BaseSmallButton
        label="All Brands"
        :end-icon="require('@/assets/images/svg/components/right-arrow.svg')"
        @click="null"
      />
    </div> -->
  </div>
</template>
<script>
import BaseSortButton from "@/components/helpers/BaseSortButton.vue";
import BaseSmallButton from "@/components/helpers/BaseSmallButton.vue";
import BaseEmptyState from "@/components/helpers/BaseEmptyState.vue";
import UserTopCardCells from "@/components/UserTopCardCells.vue";

export default {
  components: {
    BaseSortButton,
    BaseSmallButton,
    UserTopCardCells,
    BaseEmptyState,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortOption: 0,
      sortOptions: {
        txCount: 0,
        amount: 1,
      },
    };
  },
  computed: {
    sortedList() {
      const propList = this.data;
      // exit if empty
      if (propList.length === 0) return [];

      // if (this.sortOption.toString().includes("reverse")) {
      //   return propList.reverse();
      // }

      return propList.sort((a, b) => {
        let res = 0;

        switch (this.sortOption) {
          case this.sortOptions.txCount:
            res = Math.abs(b?.tx_count) - Math.abs(a?.tx_count);
            break;

          case `reverse-${this.sortOptions.txCount}`:
            res = Math.abs(a?.tx_count) - Math.abs(b?.tx_count);
            break;

          case this.sortOptions.amount:
            res = Math.abs(this.getSpend(b)) - Math.abs(this.getSpend(a));
            break;

          case `reverse-${this.sortOptions.amount}`:
            res = Math.abs(this.getSpend(a)) - Math.abs(this.getSpend(b));
            break;

          default:
            break;
        }

        return res;
      });
    },
    metric() {
      return this.$store.state.overViewStore.metricFilters;
    },
  },
  methods: {
    sortData(option) {
      this.sortOption =
        this.sortOption == option ? `reverse-${option}` : option;
    },
    getSpend(entry) {
      let value;

      if (this.metric?.key === "average") {
        value = Math.abs(entry?.avg_spend_per_tx);
      } else {
        value = Math.abs(entry?.total_spend);
      }

      return value ?? 0;
    },
  },
};
</script>
<style scoped></style>
