<template>
  <div class="d-flex flex-column justify-content-start align-items-start">
    <div class="d-flex align-items-center pb-3 w-100">
      <h4 v-if="title" class="lu-base-card-title m-0 p-0">{{ title }}</h4>
      <slot name="header" :title="title"></slot>
    </div>
    <div class="lu-base-card-content w-100 h-100">
      <div v-if="!loading" class="h-100 w-100">
        <slot name="content" :title="title"></slot>
      </div>

      <div
        v-else
        class="
          loading-container
          d-flex
          justify-content-center
          align-items-center
          w-100
          h-100
        "
      >
        <i class="pi pi-spin pi-spinner" style="color: var(--green)"></i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingHeight: {
      type: String,
      default: "47vh",
    },
    loadingIcon: {
      type: String,
      default: "2.3rem",
    },
    bgColor: {
      type: String,
      default: "white",
    },
    boxShadow: {
      type: String,
      default: "0px 4px 40px 0px rgba(204, 213, 212, 0.3)",
    },
    cardPadding: {
      type: String,
      default: "1rem",
    },
  },
};
</script>
<style scoped>
h4.lu-base-card-title {
  color: var(--green);
  font-size: 1.4rem !important;
  font-weight: 600;
  line-height: 100%;
}
.lu-base-card-content {
  padding: v-bind(cardPadding);
  border-radius: 20px;
  background-color: v-bind(bgColor);
  box-shadow: v-bind(boxShadow);
}
.pi-spin {
  opacity: 0.65;
  font-size: v-bind(loadingIcon) !important;
}
.loading-container {
  min-height: v-bind(loadingHeight);
}
</style>
