import { PublicClientApplication } from "@azure/msal-browser";
import store from "./index";

const msAzureAuth = {
  state() {
    return {
      azureUserAccount: {},
      msalConfig: {
        auth: {
          // 'Application (client) ID' of app registration in Azure portal - this value is a GUID
          clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
          // Full directory URL, in the form of https://login.microsoftonline.com/<tenant-id>
          authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AZURE_TENANT_ID}`,
          redirectUri: `${process.env.VUE_APP_LUNE_BASE_DOMAIN}/azure/auth/redirect/`,
          postLogoutRedirectUri: `${process.env.VUE_APP_LUNE_BASE_DOMAIN}/auth/login/`,
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
      },
    };
  },
  mutations: {
    updateAzureUserAccount(state, payload) {
      state.azureUserAccount = { ...payload };
    },
  },
};

//Init msalInstamce
const msalInstance = new PublicClientApplication(
  msAzureAuth.state().msalConfig
);

function handleResponse(res) {
  if (res !== null) {
    localStorage.setItem("azureUserAccount", JSON.stringify(res));
    store.commit("updateAzureUserAccount", { ...res });
    //this.$emit("login", res);
  }
}

msalInstance.handleRedirectPromise().then(handleResponse);

export default { msAzureAuth, msalInstance };
