<template>
  <div
    class="
      reset-pass-confirm-cont
      text-center
      d-flex
      justify-content-between
      align-items-center
      flex-column
      gap-2
    "
  >
    <h5>
      Thanks! If the email address you entered matches one we have on the our
      database, then we've sent you an email containing further instructions for
      resetting your password.
    </h5>

    <p>
      If you haven't received an email in 5 minutes, check your spam,
      <span class="cp" @click.stop="resendResetPassEmail">resend</span> , or
      <span class="cp" @click.stop="tryNewEmail"
        >try a different email address.</span
      >
    </p>
  </div>
</template>

<script>
import RestService from "@/services/RestService.js";

export default {
  inject: ["load", "notify"],
  data() {
    return {
      email: "",
    };
  },
  mounted() {
    this.email = this.$route.params.email;

    if (!this.email) {
      this.$router.replace({ name: "ForgetPasswordForm" });
    }
  },
  methods: {
    tryNewEmail() {
      this.$router.replace({
        name: "ForgetPasswordForm",
      });
    },
    async resendResetPassEmail() {
      const payload = {
        email: this.email,
      };

      this.load(true);
      let res = await RestService.requestPasswordReset(payload);
      this.load(false);

      if (res.success) {
        this.notify("A password reset link has been sent to your email", {
          success: true,
        });
      } else {
        this.notify("Failed to reset password", {
          success: false,
        });
      }
    },
  },
};
</script>

<style scoped>
.reset-pass-confirm-cont h5 {
  line-height: 1.7rem;
  font-size: 1.35rem;
}
.reset-pass-confirm-cont p {
  line-height: 1.7rem;
  font-size: 1.05rem !important;
  color: var(--bluey-grey);
}
.reset-pass-confirm-cont p span {
  color: var(--sea);
  font-weight: 600;
}
.reset-pass-confirm-cont p span:hover {
  text-decoration: underline;
}
</style>
