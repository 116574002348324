// import { useMockFetch } from "@/composables/Helpers";
import RestService from "../RestService";

class TransfersAPI {
  getBanksTransfers(filters, metric, requestType) {
    let type;

    switch (true) {
      case metric === "total" && requestType === "inbound":
        type = "total_inbound";
        break;
      case metric === "total" && requestType === "outbound":
        type = "total_outbound";
        break;

      case metric === "average" && requestType === "inbound":
        type = "avg_inbound";
        break;
      case metric === "average" && requestType === "outbound":
        type = "avg_outbound";
        break;
    }
    const endpoint = `/tenant_transactions/transfers_per_bank/${type}`;

    return RestService.getWithParams(endpoint, filters);
  }
  getCountriesTransfers(filters, metric, requestType) {
    let type;

    switch (true) {
      case metric === "total" && requestType === "inbound":
        type = "total_inbound";
        break;
      case metric === "total" && requestType === "outbound":
        type = "total_outbound";
        break;
      case metric === "average" && requestType === "inbound":
        type = "avg_inbound";
        break;
      case metric === "average" && requestType === "outbound":
        type = "avg_outbound";
        break;
    }
    const endpoint = `/tenant_transactions/transfers_per_country/${type}`;

    return RestService.getWithParams(endpoint, filters);
  }
  getCurrenciesTransfers(filters, metric, requestType) {
    let type;

    switch (true) {
      case metric === "total" && requestType === "inbound":
        type = "total_inbound";
        break;
      case metric === "total" && requestType === "outbound":
        type = "total_outbound";
        break;
      case metric === "average" && requestType === "inbound":
        type = "avg_inbound";
        break;
      case metric === "average" && requestType === "outbound":
        type = "avg_outbound";
        break;
    }

    const endpoint = `/tenant_transactions/transfers_per_currency/${type}`;

    return RestService.getWithParams(endpoint, filters);
  }
}

export default new TransfersAPI();
