<template>
  <DropDownInput
    id="tx-feedback-filter"
    :value="selectedValue"
    :options="options"
    label=""
    placeholder="Feedback Status"
    style="min-width: 175px"
    show-clear
    @selected-option="setValue"
  />
</template>

<script>
import DropDownInput from "./helpers/DropDownInput.vue";
import { feedbackStatusOptions } from "@/data/options";
import { ref } from "vue";

export default {
  components: { DropDownInput },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },
  emits: ["filter_updated", "update:modelValue"],
  setup(props, { emit }) {
    const selectedValue = ref();

    const options = ref(feedbackStatusOptions);
    function setValue(value) {
      selectedValue.value = value;
      emit("update:modelValue", selectedValue.value);
    }

    return { options, setValue, selectedValue };
  },
};
</script>

<style lang="scss" scoped></style>
