<template>
  <div class="card-body pb-1">
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="card-title text-start">Users by Gender</h4>

        <InfoPopover
          data-html2canvas-ignore
          class="pb-2"
          theme="sea"
          title="Users by Gender"
          content="Based on the time period chosen , the total percentage of males / females is displayed."
        />
      </div>
    </div>

    <div class="d-flex mt-1 flex-row justify-content-start">
      <span
        :class="{
          'btn-active': isMaleSelected,
          'border-dark': isMaleSelected,
        }"
        class="cp border-bottom py-2"
        @click="isMaleSelected = true"
      >
        <small>Male</small>
      </span>

      <span class="border-bottom py-2 px-3"> </span>

      <span
        class="cp border-bottom py-2"
        :class="{
          'btn-active': !isMaleSelected,
          'border-dark': !isMaleSelected,
        }"
        @click="isMaleSelected = false"
      >
        <small>Female</small>
      </span>

      <span class="flex-grow-1 border-bottom py-2"> </span>
    </div>

    <div
      class="chart-container d-flex justify-content-center align-items-center"
    >
      <DoughnutChart
        :key="render"
        class="postion-relative"
        style="z-index: 1; max-height: 40vh"
        :chart-data="chartData"
        :options="options"
      />

      <div
        class="chart-content text-center"
        :class="{ 'orange-text': !isMaleSelected }"
      >
        <div class="text-center p-0 m-0 card-percentage">
          {{
            numberWithCommas(
              ((innerValues.value / innerValues.totalUsers) * 100).toFixed(0)
            ) === "NaN"
              ? 0
              : numberWithCommas(
                  ((innerValues.value / innerValues.totalUsers) * 100).toFixed(
                    0
                  )
                )
          }}%
        </div>

        <div class="text-center">
          <span
            class="percentage rounded fw-bold w-100"
            :class="{
              negative: (innerValues.rel_perc_value ?? 0) < 0,
            }"
          >
            {{ (innerValues.rel_perc_value ?? 0) < 0 ? "-" : "+"
            }}{{ Math.abs(innerValues.rel_perc_value) }}%
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DoughnutChart } from "vue-chart-3";
import { computed, ref } from "@vue/reactivity";
import { inject, watch } from "@vue/runtime-core";
import InfoPopover from "@/components/InfoPopover.vue";

export default {
  components: { DoughnutChart, InfoPopover },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const render = ref(0);
    const isMaleSelected = ref(true);
    const numberWithCommas = inject("numberWithCommas");

    const innerValues = computed(() => {
      let data = {};
      let totalUsers = 0;

      //Calc total users
      for (let i in props.data) {
        totalUsers += props.data?.[i]?.count ?? 0;
      }
      data.totalUsers = Math.floor(totalUsers);

      if (isMaleSelected.value) {
        data.value = (props.data?.male?.count ?? 0).toFixed(0);
        data.rel_perc_value = (props.data?.male?.rel_perc_value ?? 0).toFixed(
          0
        );
      } else {
        data.value = (props.data?.female?.count ?? 0).toFixed(0);
        data.rel_perc_value = (props.data?.female?.rel_perc_value ?? 0).toFixed(
          0
        );
      }

      return data;
    });
    const chartData = computed(() => ({
      labels: ["Males", "Females"],
      datasets: [
        {
          data: [props.data?.male?.count ?? 0, props.data?.female?.count ?? 0],
          backgroundColor: isMaleSelected.value
            ? ["#469a93", "rgba(70, 154, 147, 0.1)"]
            : ["rgba(70, 154, 147, 0.1)", "#e87f71"],
          hoverOffset: 0,
          borderWidth: 0,
        },
      ],
    }));

    const options = ref({
      borderRadius: 30,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          xAlign: "center",
          yAlign: "top",
          usePointStyle: true,
          backgroundColor: "#f8f9fb",
          titleColor: "black",
          bodyColor: "black",
          padding: 12,
          bodyFont: {
            size: 14,
            lineHeight: 1,
          },
          displayColors: true,
          callbacks: {
            label: (context) => {
              return `     ${context.label}     ${context.formattedValue}`;
            },
            labelPointStyle: function (context) {
              return {
                pointStyle: "circle",
                rotation: 0,
              };
            },
          },
        },
      },
      cutout: "90%",
      radius: "75%",
    });

    watch(isMaleSelected, () => {
      render.value++;
    });

    return {
      render,
      chartData,
      options,
      isMaleSelected,
      innerValues,
      numberWithCommas,
    };
  },
};
</script>

<style scoped>
.card-divider {
  border-bottom: 1px solid #dee2e6;
  padding: 5px 0;
  cursor: pointer;
}
.card-divider span small {
  color: var(--span-grey);
  font-weight: 500;
}
.btn-active small {
  color: var(--black-two) !important;
  font-weight: bolder !important;
}
.chart-content {
  position: absolute;
  z-index: 0;
  color: var(--sea) !important;
}

.chart-content .card-percentage {
  font-size: 2rem !important;
}

.chart-content .small-percentage span {
  font-size: 0.9rem !important;
  background-color: #ffffff18;
  padding: 4px 8px;
  border-radius: 8px;
}

.percentage {
  background-color: var(--sea-10);
  color: var(--sea);
  font-size: 0.7rem !important;
  padding: 3px 8px !important;
  margin: 0px !important;
  border-radius: 7px;
}
.negative {
  background-color: rgba(223, 41, 53, 0.1);
  color: #df2935ec !important;
}

.orange-text {
  color: var(--orange) !important;
}
.orange-text .percentage {
  color: var(--orange) !important;
  background-color: #e87f7113;
}
</style>
