import { useMockFetch } from "@/composables/Helpers";
import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://nbfappmiddlewareweb.nbf.ae/gateway/NBFLune",
  headers: {
    "Content-Type": "application/json",
    "NBF-APIKey": process.env.VUE_APP_NBF_API_KEY,
  },
  //In Axios we add the Bsic Auth as a config object and then axios will encode it and add it inside the Headers Automatically
  auth: {
    username: process.env.VUE_APP_NBF_API_USERNAME,
    password: process.env.VUE_APP_NBF_API_PASSWORD,
  },
});

//Interceptors
// apiClient.interceptors.request.use((request) => {
//   console.log("Starting Request", request);
//   return request;
// });

// apiClient.interceptors.response.use((response) => {
//   console.log("Response:", JSON.stringify(response, null, 2));
//   return response;
// });

export default {
  getCustomerDetails(cloudID) {
    return process.env.VUE_APP_NAME === "Vue-Production-Retail-NBF"
      ? apiClient.get(`getCustomerDetails?CloudID=${cloudID}`)
      : useMockFetch({
          Status: "SUCCESS",
          CustomerName: "User Id: " + cloudID,
          RMName: "GAURAV MITTAL",
          CifNumber: "286347",
        });
  },
  getCustomerByCIFID(CIFId) {
    return process.env.VUE_APP_NAME === "Vue-Production-Retail-NBF"
      ? apiClient.get(`getCustomerDetails?CIFId=${CIFId}`)
      : useMockFetch({
          Status: "SUCCESS",
          CustomerName: "NAME.1 OF 477550",
          RMName: "Muhammad Umer Amjad",
          CifNumber: "477550",
          CloudID: "C20150608139697055774",
          AccountIds: ["012000891701", "012001044999", "012001757944"],
        });
  },
};
