<template>
  <div class="sidebar-link-box" @click="routeClicked">
    <div>
      <tippy>
        <div
          class="
            my-3
            py-2
            sidebar-link
            route-tile
            d-flex
            align-items-center
            w-100
            gap-2
            cp
          "
          :class="{
            'justify-content-center': !showSideBar,
            'show px-4': showSideBar,
          }"
        >
          <img
            src="@/assets/images/svg/new_sidebar/Sign_out.svg"
            class="route-icon-logout"
            alt="none"
          />

          <transition name="route-label-2">
            <h6 v-if="showSideBar" class="p-0 m-0 text-nowrap">
              <small>{{ routeData?.label }}</small>
            </h6>
          </transition>
        </div>
        <template #content>
          <div v-if="!showSideBar" class="w-100 route-tippy">
            <small>{{ routeData?.label }}</small>
          </div>
        </template>
      </tippy>
    </div>
  </div>
</template>

<script>
import RestService from "../services/RestService";
import SideBarLink from "./SideBarLink.vue";
export default {
  components: { SideBarLink },
  inject: ["load", "notify"],
  props: {
    showSideBar: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      routeData: {
        id: "sign-out",
        path: "/auth/login",
        name: "LoginForm",
        label: "Sign Out",
        icon: require("../assets/images/svg/sidebar/sign-out.svg"),
      },
    };
  },
  computed: {
    azureUser() {
      return this.$store.state.msAzureAuth.azureUserAccount;
    },
  },
  methods: {
    routeClicked() {
      if (Object.keys(this.azureUser).length !== 0) {
        this.logoutWithAzure();
      } else {
        this.logout();
      }
    },
    async logout() {
      //[1] redirect to login page + this will clear local sotrage + reset all filters
      this.$router.replace({ name: "LoginForm" });

      //[3] invalidate the current token
      // await RestService.logout();
    },
    async logoutWithAzure() {
      //this.load(true);

      try {
        //[1] invalidated the token
        // await RestService.logout();

        //[2] logout from Azure AD
        const homeId =
          this.$store.state.msAzureAuth.azureUserAccount?.account
            ?.homeAccountId;

        const logoutRequest = {
          account: this.$msalInstance.getAccountByHomeId(homeId),
          mainWindowRedirectUri: `${process.env.VUE_APP_LUNE_BASE_DOMAIN}/auth/login/`,
        };

        //clears the cache in browser storage and opens a pop-up window to the Azure Active Directory (Azure AD) sign-out page
        this.$msalInstance.logoutRedirect(logoutRequest);
        this.$store.dispatch("logout");
        localStorage.removeItem("azureUserAccount");
        this.$store.commit("updateAzureUserAccount", {});
      } catch (error) {
        console.error("Failed to logout with Azure AD: ", error);
        this.notify("Failed to Logout: Network Error", { success: false });
      }
      // finally {
      //   this.load(false);
      // }
    },
  },
};
</script>

<style>
.route-label-2-enter-active,
.route-label-leave-active {
  transition: opacity 0.15s;
}

.route-label-2-enter,
.route-label-2-leave-to {
  opacity: 0;
}
</style>
