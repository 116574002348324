<template>
  <div id="home-view" class="d-flex h-100">
    <Sidebar />
    <router-view
      class="main-router-view mx-auto"
      :class="{ 'with-sidebar': showSideBar }"
    />
  </div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";

export default {
  components: { Sidebar },
  computed: {
    showSideBar() {
      return this.$store.state.showSideBar;
    },
  },
};
</script>

<style>
#home-view {
  height: 100vh !important;
  background-color: var(--main-bg);
}
.main-router-view {
  width: 88%;
  transition: var(--d-ease);
}
.main-router-view.with-sidebar {
  width: 81%;
}
.flex-2 {
  flex-grow: 2;
}

/* .route-icon {
  width: 42px !important;
  height: 42px !important;
} */

a {
  text-decoration: none !important;
}

#logo {
  max-height: 4vh;
}
/* .full-logo {
  max-height: 4.5vh !important;
} */

.mw-0 {
  min-width: 0 !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-45 {
  width: 45% !important;
}

.h-45 {
  height: 45% !important;
}

.w-45 {
  width: 45% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.dropdown-item,
.dropdown-item a {
  color: var(--eggplant);
}

.dropdown-item {
  padding: unset !important;
  white-space: normal !important;
  border-radius: 7.5px !important;
  width: unset !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--sea-two-25) !important;
  /* color: #fff !important; */
}

.dropdown-item:hover,
.dropdown-item:hover a {
  color: var(--sea-two) !important;
}

.dropdown-list {
  /* padding: 25px 25px 0 !important; */
  max-height: 280px;
  overflow-y: auto;
  /* background-color: blue !important; */
}
.loader {
  border-radius: 10px !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  position: absolute !important;
  z-index: 2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.spinner-border {
  color: var(--sea) !important;
}
.settings-icon {
  opacity: 0.5 !important;
}
.sub-route {
  /* font-size: 28px; */
  color: #151515;
  background-color: rgb(151, 170, 189, 0.14);
}
.btn-primary {
  color: #fff !important;
  background-color: var(--green) !important;
  border-color: var(--green) !important;
}

.bg-primary {
  color: #fff;
  background-color: var(--sea) !important;
  /* border-color: var(--sea) !important; */
}

.rounded-20 {
  border-radius: 20px;
}

.rounded-start-20 {
  border-radius: 20px 0 0 20px;
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #326e69;
  border-color: #295551;
}

.btn-primary:hover {
  color: #fff;
  background-color: var(--sea);
  border-color: var(--sea);
}

.route-icon {
  /* height: 2.96vh !important; */
  width: 40px !important;
  height: 25px !important;
  filter: invert(75%) sepia(6%) saturate(905%) hue-rotate(169deg)
    brightness(89%) contrast(89%);
}

.sub-route-chevron {
  color: var(--bluey-grey) !important;
}
.card {
  border-radius: 10px !important;
  border: none !important;
  min-width: unset !important;
  background-color: #fff !important;
  /* max-height: 34% !important; */
}

.card-title {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--black-three);
}
h4.card-title {
  font-size: 1.45rem !important;
}

.card:hover {
  box-shadow: 0 12px 50px 0 rgba(151, 170, 189, 0.15) !important;
}
/*-------   sm size   -------*/
/* @media (min-width: 576px) {
  h4 {
    font-size: 1.65rem !important;
  }
  .card-link {
    font-size: 0.865rem !important;
  }
  button {
    font-size: 0.9rem !important;
  }
  p {
    font-size: 0.86rem !important;
  }
  .card-foot {
    font-size: 0.92rem !important;
  }
  .percentage {
    font-size: 0.78rem !important ;
  }
  .card-body h5 {
    font-size: 1.05rem !important ;
  }
  .card-body h2 {
    font-size: 2.92rem !important ;
  }
  .card-body h6 {
    font-size: 0.8rem !important ;
  }
  .card-body .conversion-label {
    font-size: 1.1rem !important;
  }
  .card-body .conversion-amount {
    font-size: 3rem !important ;
  }

  .main-router-view {
    width: 85% !important;
  }
} */

/*-------   md size   -------*/
/* @media (min-width: 768px) {
  .main-router-view {
    width: 75% !important;
  }
  h4 {
    font-size: 1.45rem !important;
  }
  .card-link {
    font-size: 0.81rem !important;
  }
  button {
    font-size: 0.777rem !important;
  }
  p {
    font-size: 0.755rem !important;
  }
  .card-foot {
    font-size: 0.82rem !important;
  }

  .percentage {
    font-size: 0.73rem !important ;
  }
  .card-body h5 {
    font-size: 0.94rem !important ;
  }

  .card-body h2 {
    font-size: 2.6rem !important ;
  }
  .card-body h6 {
    font-size: 0.73rem !important ;
  }
  .card-body .conversion-label {
    font-size: 0.95rem !important;
  }
  .card-body .conversion-amount {
    font-size: 2.2rem !important ;
  }

  .greyed-out {
    /* color: red !important; */
/* color: var(--bluey-grey) !important;
  }
} */

/*-------   lg size   -------*/
/* @media (min-width: 992px) {
  .main-router-view {
    width: 75% !important;
  }
  h4 {
    font-size: 1.365rem !important;
  }
  .card-link {
    font-size: 0.85rem !important;
  }
  button {
    font-size: 0.85rem !important;
  }
  p {
    font-size: 0.82rem !important;
  }
  .card-foot {
    font-size: 0.85rem !important;
  }
  .percentage {
    font-size: 0.77rem !important ;
  }
  .card-body h5 {
    font-size: 1.15rem !important ;
  }

  .card-body h2 {
    font-size: 3.25rem !important ;
  }
  .card-body h6 {
    font-size: 0.783rem !important ;
  }
  .card-body .conversion-label {
    font-size: 1.04rem !important;
  }
  .card-body .conversion-amount {
    font-size: 2.7rem !important ;
  }
} */

/*-------   xl size   -------*/
/* @media (min-width: 1200px) {
  .main-router-view {
    width: 80% !important;
  }
  h4 {
    font-size: 1.49rem !important;
  }
  .card-link {
    font-size: 0.82rem !important;
  }
  button {
    font-size: 0.8rem !important;
  }
  p {
    font-size: 0.67rem !important;
  }
  .card-foot {
    font-size: 0.8rem !important;
  }
  .percentage {
    font-size: 0.715rem !important ;
  }
  .card-body h5 {
    font-size: 0.955rem !important ;
  }

  .card-body h2 {
    font-size: 2.5rem !important ;
  }
  .card-body h6 {
    font-size: 0.66rem !important ;
  }
  .card-body .conversion-label {
    font-size: 0.95rem !important;
  }
  .card-body .conversion-amount {
    font-size: 2.4rem !important ;
  }
} */

/*-------   xxl size   -------*/
/* @media (min-width: 1400px) {
  .main-router-view {
    width: 88% !important;
  }
  h4 {
    font-size: 1.6rem !important;
  }
  .card-link {
    font-size: 0.88rem !important;
  }
  button {
    font-size: 0.877rem !important;
  }
  p {
    font-size: 0.89rem !important;
  }
  .card-foot {
    font-size: 0.9rem !important;
  }
  .percentage {
    font-size: 0.72rem !important ;
  }
  .card-body h5 {
    font-size: 1.2rem !important ;
  }

  .card-body h2 {
    font-size: 3rem !important ;
  }
  .card-body h6 {
    font-size: 0.715rem !important ;
  }
  .card-body .conversion-label {
    font-size: 1rem !important;
  }
  .card-body .conversion-amount {
    font-size: 2.6rem !important ;
  }
} */

/*-------   xxxl size   -------*/
/* @media (min-width: 1920px) {
  .main-router-view {
    width: 85% !important;
  }
  h4 {
    font-size: 2.3rem !important;
  }
  .card-link {
    font-size: 1.09rem !important;
  }
  button {
    font-size: 1.05rem !important;
  }
  p {
    font-size: 1.2rem !important;
  }
  .card-foot {
    font-size: 1.09rem !important;
  }
  .percentage {
    font-size: 0.88rem !important ;
  }
  .card-body h5 {
    font-size: 1.25rem !important ;
  }

  .card-body h2 {
    font-size: 3.8rem !important ;
  }
  .card-body h6 {
    font-size: 0.95rem !important ;
  }
  .card-body .conversion-label {
    font-size: 1.25rem !important;
  }
  .card-body .conversion-amount {
    font-size: 3.4rem !important ;
  }
} */
</style>
