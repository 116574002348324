<template>
  <div>
    <form id="forget-pass-form" @submit.prevent="submit()">
      <div class="row justify-content-center align-items-center">
        <div class="mb-3 col-12">
          <AuthInput
            id="new_password_1"
            v-model="newPassword"
            type="password"
            aria-label="password"
            label="New Password"
            name="new_password_1"
          />
        </div>

        <div class="mt-3 mb-4 col-12">
          <AuthInput
            id="new_password_2"
            v-model="newPasswordConfirm"
            type="password"
            aria-label="password"
            label="Re-enter new password"
            name="new_password_2"
          />
        </div>

        <div class="col-12 mt-4">
          <FormButton
            class="py-3 col-12"
            label="Change password"
            form="forget-pass-form"
            :loading="loading"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import RestService from "@/services/RestService.js";
import AuthInput from "../helpers/AuthInput.vue";
import FormButton from "../helpers/FormButton.vue";
import { inject, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { object, string, ref as yupRef } from "yup";
import { useForm } from "vee-validate";
import { event } from "vue-gtag";

export default {
  components: { AuthInput, FormButton },
  setup() {
    const notify = inject("notify");
    const router = useRouter();
    const route = useRoute();

    const form_key = ref(route.query?.form_key);
    const token = ref(route.query?.token);
    const newPassword = ref("");
    const newPasswordConfirm = ref("");
    const loading = ref(false);
    const payload = reactive({});

    onMounted(() => {
      payload[form_key.value] = token;
    });

    function trackSetForgotonPass() {
      event("MAY23_success_set_forgetted_pass", {
        event_category: "Authentication",
        event_label: "MAY23_success_set_forgetted_pass",
        value: 1,
      });
    }

    async function changePassword() {
      const _payload = {
        reset_password_token: payload[form_key.value],
        password: newPassword.value,
        password_confirmation: newPasswordConfirm.value,
      };

      loading.value = true;
      const res = await RestService.setPassword(_payload, false);
      loading.value = false;

      if (res?.success ?? false) {
        trackSetForgotonPass();
        notify("Your password has been updated", {
          success: true,
        });

        router.replace({
          name: "LoginForm",
        });
      } else {
        notify("Failed to update password", {
          success: false,
        });
      }
    }

    const validationSchema = object({
      new_password_1: string()
        .min(8, "Password should be 8 characters minimum")
        .required("New password is required")
        .test(
          "isValidPass",
          "Make sure to include ( Upper case, Lower case, & Numbers )",
          (value, context) => {
            const hasUpperCase = /[A-Z]/.test(value);
            const hasLowerCase = /[a-z]/.test(value);
            const hasNumber = /[0-9]/.test(value);
            const hasSymbole = /[!@#%&]/.test(value);
            let validConditions = 0;
            const numberOfMustBeValidConditions = 3;
            const conditions = [
              hasLowerCase,
              hasUpperCase,
              hasNumber,
              hasSymbole,
            ];
            conditions.forEach((condition) =>
              condition ? validConditions++ : null
            );
            if (validConditions >= numberOfMustBeValidConditions) {
              return true;
            }
            return false;
          }
        ),
      new_password_2: string()
        .oneOf(
          [yupRef("new_password_1")],
          "Please match the new password above"
        )
        .min(8, "Password should be 8 characters minimum")
        .required("Confirm password is required"),
    });

    const { handleSubmit, errors, resetForm } = useForm({
      validationSchema,
    });

    const submit = handleSubmit((values) => {
      changePassword();
    });

    return { submit, newPassword, newPasswordConfirm, loading };
  },
};
</script>
<style scoped></style>
