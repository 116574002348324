<template>
  <div class="paginator text-center">
    <Paginator
      class="paginator-ctx"
      :rows="rows"
      :total-records="totalRecords"
      @page="onPage($event)"
    ></Paginator>
  </div>
</template>

<script>
import Paginator from "primevue/paginator";

export default {
  components: {
    Paginator,
  },
  props: {
    rows: {
      type: Number,
      default: 20,
    },
    totalRecords: {
      type: Number,
      default: 20,
    },
    boxPadding: {
      type: String,
      default: "1rem",
    },
  },
  methods: {
    onPage(e) {
      this.$emit("page", e);
    },
  },
};
</script>

<style>
.p-paginator-page,
.p-paginator-next,
.p-paginator-last,
.p-paginator-prev,
.p-paginator-first {
  padding: 13px;
  margin: 0px 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: none;
  color: var(--green);
  background-color: transparent;
}

.p-paginator-page:hover {
  color: var(--dark-green-70, #4d6d6a);
  font-weight: 400 !important;
  background-color: transparent !important;
}
.p-paginator {
  background: transparent !important;
  padding: v-bind(boxPadding) !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  width: fit-content !important;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  border: none;
  color: var(--sea, #469b93) !important;
  font-weight: 700 !important;
  background: none !important;
  font-family: "Poppins", sans-serif;
  width: fit-content !important;
}
.p-link {
  font-family: "Poppins", sans-serif !important;
}
.p-link:disabled {
  border-radius: 50% !important;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  color: #c3c6c6 !important;
}
</style>
