<template>
  <div
    class="
      cp
      d-flex
      justify-content-start
      align-items-center
      base-custom-sort-header
    "
  >
    <div>{{ header }}</div>
    <img :src="sortIcon" alt="sort default icon" class="base-sort-icon" />
  </div>
</template>
<script>
export default {
  props: {
    header: {
      type: String,
      required: true,
      default: "Header",
    },
    order: {
      type: Number,
      default: null,
    },
  },
  computed: {
    sortIcon() {
      switch (this.order) {
        case null:
          return require("../../assets/images/svg/components/sort_normal.svg");
        case 1:
          return require("../../assets/images/svg/components/sort_up.svg");
        case -1:
          return require("../../assets/images/svg/components/sort_down.svg");
        default:
          return require("../../assets/images/svg/components/sort_normal.svg");
      }
    },
  },
};
</script>
<style>
.p-datatable .p-sortable-column {
  transition: var(--d-ease) !important;
}
.p-datatable .p-sortable-column:hover {
  background: #e9ecef;
  color: var(--green) !important;
  /* text-decoration: underline; */
}
.p-datatable .p-sortable-column:focus {
  outline: none !important;
  box-shadow: none !important;
}
.p-datatable .p-sortable-column.p-highlight {
  color: var(--green) !important;
  /* text-decoration: underline; */
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon,
.p-datatable .p-sortable-column .p-sortable-column-icon {
  display: none !important;
}

.base-sort-icon {
  width: 7px;
  margin-left: 10px;
  transition: var(--d-ease);
}
</style>
