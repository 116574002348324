<template>
  <div class="box">
    <div class="vertical-center d-flex align-items-center w-100">
      <div class="form-container container-lg">
        <form
          id="login-user-form"
          @submit.prevent="
            submit();
            trackLogin();
          "
        >
          <div class="row justify-content-center align-items-center">
            <div class="col-12 mb-2">
              <AuthInput
                id="login_email"
                v-model="email"
                type="email"
                aria-label="Email"
                label="E-mail"
                placeholder=""
                name="login_email"
              />
            </div>

            <div class="mt-3 mb-4 col-12">
              <AuthInput
                id="login_password"
                v-model="password"
                type="password"
                aria-label="password"
                label="Password"
                placeholder=""
                name="login_password"
              />
            </div>

            <div class="mt-1 mb-4 col-12 text-start login-foot">
              <Recaptcha @valid="setCaptchToken" />
            </div>

            <div class="mt-1 mb-4 col-12 text-end login-foot">
              <router-link :to="{ name: 'ForgetPasswordForm' }"
                ><span class="login-route-link"
                  >Forget your password</span
                ></router-link
              >
            </div>

            <div class="col-12 my-3">
              <FormButton
                id="form-button"
                class="py-3 col-12"
                label="Login"
                type="submit"
                :loading="loading"
              />
            </div>

            <div class="col-12 my-2 text-center login-foot">
              <span>Or</span>
            </div>

            <div class="my-2 d-flex justify-content-center flex-nowrap">
              <AzureADLoginButton />
            </div>

            <!-- <div v-if="false" class="col-12 mt-2 text-center login-foot">
              <span
                >Not a user?
                <router-link :to="{ name: 'SignUpForm' }"
                  ><span class="login-route-link ms-1">
                    Sign up now</span
                  ></router-link
                ></span
              >
            </div> -->
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import RestService from "@/services/RestService.js";
import Recaptcha from "../Recaptcha.vue";
import AzureADLoginButton from "../AzureADLoginButton.vue";
import AuthInput from "../helpers/AuthInput.vue";
import FormButton from "../helpers/FormButton.vue";
import { object, string } from "yup";
import { inject, ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { useForm } from "vee-validate";
import { event } from "vue-gtag";
import store from "../../store";

export default {
  components: { Recaptcha, AuthInput, FormButton, AzureADLoginButton },
  setup() {
    const router = useRouter();
    const notify = inject("notify");

    const email = ref("");
    const password = ref("");
    const captchToken = ref("");
    const loading = ref(false);

    const azureUserAccount = computed(() => {
      return store.state.msAzureAuth.azureUserAccount;
    });

    watch(azureUserAccount, (azureObject, oldAzureObject) => {
      if (
        Object.keys(azureObject)?.length !== 0 &&
        azureObject?.accessToken !== oldAzureObject?.accessToken
      ) {
        azureLogin(azureObject);
      }
    });

    function trackLogin() {
      event("MAY23_click_login_button", {
        event_category: "Engagement",
        event_label: "MAY23_click_login_button",
        value: 1,
      });
    }

    function trackSuccessLogin() {
      event("MAY23_success_login", {
        event_category: "Engagement",
        event_label: "MAY23_success_login",
        value: 1,
      });
    }

    function setCaptchToken(validationToken) {
      captchToken.value = validationToken;
    }

    async function login() {
      const credentials = {
        email: email.value.toLowerCase(),
        password: password.value,
      };

      loading.value = true;
      const res = await RestService.login(credentials);
      loading.value = false;

      //Validate the auth response
      handleAuthResponse(res);
    }
    function handleAuthResponse(res) {
      if (res.success) {
        trackSuccessLogin();
        router.replace({
          name: "Overview",
          // res.verified
          //   ? res.user.is_info_form_seen
          //     ? "Overview"
          //     : "CompanyDetailsForm"
          //   : "VerifySignUp",
        });
      } else {
        console.error(res.err);
        router.push({ name: "LoginForm" });
        notify("Failed to login using provided credentials", {
          success: false,
        });
      }
    }

    const validationSchema = object({
      login_email: string()
        .email("E-mail must be in a valid format")
        .required("E-mail is required"),
      login_password: string()
        //.min(8, "Password should be 8 characters minimum")
        .required("Password is required"),
    });

    async function azureLogin(azureUser) {
      loading.value = true;
      let res = await RestService.azureSignIn(azureUser); //exchange tokens
      loading.value = false;
      handleAuthResponse(res);
    }

    const { handleSubmit, errors } = useForm({
      validationSchema,
    });

    const submit = handleSubmit(() => {
      if (captchToken.value) login();
      else notify("Please check the reCAPTCHA", { success: false });
    });

    return {
      submit,
      setCaptchToken,
      trackLogin,
      azureLogin,
      email,
      password,
      captchToken,
      loading,
      errors,
    };
  },
  created() {
    localStorage.clear();
    this.$store.dispatch("logout");
  },
};
</script>

<style scoped></style>
