<template>
  <div class="cp user-acc-card d-flex align-items-center gap-3 p-3 px-4">
    <div
      class="user-acc-flag"
      :style="`background-image: url(${flagIconURL})`"
    ></div>
    <div>
      <h6>{{ account?.account_name ?? "--" }}</h6>
      <h4>
        {{ compactNumber(Math.abs(account?.account_total_spend ?? 0), 2) }}
      </h4>
    </div>

    <div class="d-flex align-items-center">
      <div class="ms-4 card-amount-rr">
        <h5 class="text-nowrap">No of Txns</h5>
        <div class="d-flex justify-content-start gap-2">
          <h4>{{ compactNumber(account?.account_transactions_count ?? 0) }}</h4>
          <img
            v-if="!txCountDiff?.diffSign"
            class="trending-icon"
            src="@/assets/images/svg/components/Trending-Down.svg"
            alt="trneding icon"
          />
          <img
            v-else
            class="trending-icon"
            src="@/assets/images/svg/components/Trending-UP.svg"
            alt="trneding icon"
          />
        </div>
      </div>
      <img
        class="mx-4"
        src="@/assets/images/svg/components/v-line-md.svg"
        alt="vertical line"
        height="44"
      />
      <div class="card-amount-rr">
        <h5 class="text-nowrap">Avg Spent / Day</h5>
        <div class="d-flex justify-content-start gap-2">
          <h4>
            {{
              compactNumber(
                Math.abs(account?.account_avg_spend_per_day ?? 0),
                2
              )
            }}
          </h4>

          <img
            v-if="!avgSpendDiff?.diffSign"
            class="trending-icon"
            src="@/assets/images/svg/components/Trending-Down.svg"
            alt="trneding icon"
          />
          <img
            v-else
            class="trending-icon"
            src="@/assets/images/svg/components/Trending-UP.svg"
            alt="trneding icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useDiffCalculator } from "@/composables/Helpers";
import { countries } from "@/data/countries";
import { computed, inject } from "vue";

export default {
  props: {
    account: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const compactNumber = inject("compactNumber");

    const flagIconURL = computed(() => {
      const acCountry = countries.filter(
        (country) => props.account?.account_currency === country.currencyCode
      );

      if (acCountry.length !== 0)
        return `https://purecatamphetamine.github.io/country-flag-icons/3x2/${acCountry[0]?.countryCode}.svg`;
      //`https://www.countryflagicons.com/FLAT/64/${acCountry[0]?.countryCode}.png`;
      else
        return "https://purecatamphetamine.github.io/country-flag-icons/3x2/AE.svg"; //"https://www.countryflagicons.com/FLAT/64/AE.png";
    });

    const txCountDiff = computed(() => {
      return useDiffCalculator(
        props.account?.account_transactions_count,
        props.account?.comparison_period_account_transactions_count
      );
    });
    const avgSpendDiff = computed(() => {
      return useDiffCalculator(
        props.account?.account_avg_spend_per_day,
        props.account?.comparison_period_account_avg_spend_per_day
      );
    });

    return { flagIconURL, compactNumber, txCountDiff, avgSpendDiff };
  },
};
</script>

<style lang="scss" scoped>
.user-acc-card {
  border: 1px solid white;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 4px 40px 0px rgba(204, 213, 212, 0.3);
  transition: var(--d-ease);

  &:hover {
    border: 1px solid var(--dark-green-100, #002e2a);
  }
  .user-acc-flag {
    width: 32px;
    height: 32px;
    background-size: 50px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border-radius: 50%;
  }

  h6 {
    font-size: 0.73rem !important;
    padding-bottom: 4px;
  }
  h4 {
    font-size: 1.8rem !important;
  }
  .card-amount-rr h5 {
    font-size: 0.77rem !important;
    color: var(--dark-green-50, #809795);
    padding-bottom: 6px;
    font-weight: 400 !important;
  }
  .card-amount-rr h4 {
    font-size: 1.32rem !important;
    font-weight: 400 !important;
    color: var(--sea);
  }
  .trending-icon {
    width: 22px;
  }
}
</style>
