<template>
  <div>
    <div
      v-if="showLabel || (showChangePwdLabel && type == 'password')"
      class="d-flex align-items-center justify-content-between"
    >
      <div>
        <label :for="label" class="form-label">{{ label }}</label>
      </div>
      <div v-if="showChangePwdLabel && type == 'password'">
        <a @click="$emit('changePassword')">
          <label class="form-label cp">Change password</label>
        </a>
      </div>
    </div>

    <div
      class="input-group"
      :class="{ disabled: isDisabled, 'input-text-area': type == 'textarea' }"
    >
      <span
        v-if="startIcon"
        class="input-group-text"
        :class="{ disabled: isDisabled }"
      >
        <img :src="startIcon" width="16" :class="{ disabled: isDisabled }" />
      </span>
      <input
        v-if="type != 'textarea' && listener == null && label != 'Benefits'"
        v-model="inputFieldValue"
        :id="label"
        :placeholder="placeholder"
        :type="type"
        class="form-control border-0 ps-0"
        :aria-label="type"
        :disabled="isDisabled"
        :pattern="
          type == 'email'
            ? '[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$'
            : null
        "
        :value="inputFieldValue"
        :maxlength="maxLength"
        required
        @keyup="
          triggerInput();
          inputFieldValue =
            typeof variable === 'string'
              ? inputFieldValue.toLowerCase()
              : inputFieldValue;
        "
      />

      <input
        v-else-if="listener != null"
        :id="label"
        v-model="inputFieldValue"
        :type="type"
        class="form-control border-0 ps-0"
        :aria-label="type"
        :disabled="isDisabled"
        :maxlength="maxLength"
        required
        @blur="$emit('updateInputValue', inputFieldValue)"
        @keyup="onKeyUp"
        v-on="listener"
      />

      <input
        v-else-if="label === 'Benefits'"
        id="benefits-input"
        v-model="inputFieldValue"
        :type="type"
        class="border-0 ps-0 cp benefits-input w-100"
        :aria-label="type"
        :disabled="isDisabled"
        :maxlength="maxLength"
        name="benefit-filled"
        placeholder="Add benefit"
        required
        @blur="addBenefit(inputFieldValue)"
        @keyup.enter="addBenefit(inputFieldValue)"
      />

      <textarea
        v-else
        :id="id"
        v-model="inputFieldValue"
        name=""
        cols="100"
        :disabled="isDisabled"
        :class="{ disabled: isDisabled }"
        :maxlength="maxLength"
        :rows="rows"
        @blur="$emit('updateInputValue', inputFieldValue)"
        @keyup="onKeyUp"
      ></textarea>

      <span v-if="endIcon" class="input-group-text">
        <img
          v-if="type != 'email'"
          :key="reloadIcon"
          :src="endIconChange"
          width="16"
          @click="showPassword"
        />
        <img v-else :src="endIcon" width="16" />
        <span v-if="type == 'email'" class="ps-1 email-verified"
          >Email verified</span
        >
      </span>
      <span v-else-if="unit != null" class="input-group-text disabled ps-1">
        {{ unit }}
      </span>
      <div v-if="type == 'textarea' && showCounter" class="w-100">
        <small id="field-counter" class="float-end pe-2 pb-1"
          >{{ fieldCount ?? 0 }}/{{ maxLength }}</small
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
    rows: {
      type: String,
      default: "3",
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
    },
    unit: {
      type: String,
    },
    listener: {
      type: Object,
    },
    maxLength: {
      type: String,
      default: "180",
    },
    inputValue: {},
    isDisabled: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    showChangePwdLabel: {
      type: Boolean,
      default: true,
    },
    reactive: {
      type: Boolean,
      default: false,
    },
    showCounter: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
    },
    startIcon: {
      type: String,
      default: "",
    },
    endIcon: {
      type: String,
      default: "",
    },
  },
  emits: ["changePassword", "updateInputValue", "fieldKeyUp"],
  data() {
    return {
      fieldCount: 0,
      reloadIcon: 0,
      inputFieldValue: this.inputValue,
    };
  },
  // data() {
  //   return {
  //     // inputFieldValue: this.inputFieldValue,
  //   };
  // },
  computed: {
    endIconChange() {
      if (
        (document.getElementById(this.label)?.type ?? "password") === "password"
      ) {
        this.reloadIcon++;
        // console.log("password");
        return require("../assets/images/svg/eye.svg");
      } else {
        this.reloadIcon++;
        console.log("text");
        return require("../assets/images/svg/eye-slash.svg");
      }
    },
  },
  mounted() {
    this.setValue();
  },

  methods: {
    triggerInput() {
      this.$emit("updateInputValue", this.inputFieldValue);
      this.onKeyUp();
    },
    addBenefit(inputFieldValue) {
      if (inputFieldValue) {
        //reset input
        document.querySelector(
          "#benefits-input input[name='benefit-filled']"
        ).value = "";
        this.inputFieldValue = "";

        this.$emit("updateInputValue", inputFieldValue);
      } else {
        console.log("Input field is empty");
      }
    },
    setValue() {
      let el = document.getElementById(this.label);

      if (el) el.value = this.inputFieldValue;
      this.countChars();
      //console.log("Ref : ",document.getElementById(this.label).value)
      //document.getElementById(this.label).value = this.placeHolder
    },
    showPassword() {
      var x = document.getElementById(this.label);

      if (x.type === "password") {
        x.type = "text";
      } else if (x.type === "text") {
        x.type = "password";
      }
      this.reloadIcon++;
    },
    countChars() {
      this.fieldCount = this.inputFieldValue?.length ?? 0;
    },
    onKeyUp() {
      if (this.type == "textarea") this.countChars();

      // console.log(this.reactive, this.inputFieldValue);
      if (this.reactive) {
        this.$emit("fieldKeyUp", this.inputFieldValue);
      }
    },
  },
};
</script>

<style scoped>
.form-label {
  font-size: 0.935rem;
  color: var(--bluey-grey);
  font-weight: 500;
}

a .form-label {
  font-size: 0.8rem;
  color: var(--bluey-grey);
  font-weight: 500;
}
a .form-label:hover {
  text-decoration: underline;
}

.email-verified {
  font-size: 0.84rem;
  margin-left: 5px;
  color: var(--sea) !important;
}

.input-group {
  border: 1px solid var(--input-border);
  border-radius: 8px;
}

.input-group-text {
  background-color: transparent !important;
  border: none !important;
  filter: invert(78%) sepia(18%) saturate(339%) hue-rotate(169deg)
    brightness(83%) contrast(87%);
}

textarea,
input[type="email"],
input[type="password"],
input[type="number"],
input[type="text"] {
  border: none !important;
  background-color: transparent !important;
}

textarea,
input[type="email"],
input[type="password"],
input[type="text"] {
  color: var(--eggplant);
  padding: 7px !important;
  padding-left: 8px !important;
  resize: none !important;
}
input[type="number"] {
  font-size: 0.82rem;
  color: var(--eggplant);
  padding: 7px !important;
  padding-left: 8px !important;
  resize: none !important;
}

textarea:focus,
input[type="password"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="text"]:focus {
  border-color: var(--sea);
  box-shadow: none;
  outline: 0 none;
}

textarea:disabled {
  color: var(--eggplant) !important;
}

input[type="email"]:disabled,
input[type="number"]:disabled,
input[type="password"]:disabled,
input[type="text"]:disabled,
.form-control:disabled {
  color: var(--bluey-grey) !important;
}

input[type="text"].benefits-input {
  color: var(--eggplant) !important;
  background-color: var(--bluey-grey-25) !important;
  border-radius: 8px !important;
}

input[type="text"].benefits-input:focus {
  background-color: transparent !important;
}

.input-group.disabled {
  border: 1px solid var(--input-border);
  border-radius: 8px;
}

.input-text-area.disabled {
  border: 1px solid var(--input-border);
  border-radius: 8px;
}

input[type="text"]:disabled {
  color: var(--eggplant) !important;
  border-radius: 8px !important;
}

img.disabled {
  filter: invert(78%) sepia(18%) saturate(339%) hue-rotate(169deg)
    brightness(83%) contrast(87%);
}

/* Change the white to any color */
textarea:-webkit-autofill,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
