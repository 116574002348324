<template>
  <div>
    <form id="send-forget-req" @submit.prevent="submit()">
      <div class="row justify-content-center align-items-center">
        <div class="col-12">
          <AuthInput
            id="forget_pass_email"
            v-model="email"
            type="email"
            aria-label="Email"
            label="E-mail"
            placeholder=""
            name="forget_pass_email"
          />
        </div>

        <div class="col-12 my-4 mt-5">
          <FormButton
            class="py-3 col-12"
            label="Reset password"
            from="send-forget-req"
            :loading="loading"
          />
        </div>

        <div class="col-12 mt-2 text-center login-foot">
          <span
            >Back to
            <router-link :to="{ name: 'LoginForm' }"
              ><span class="login-route-link">Sign in</span></router-link
            ></span
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import RestService from "@/services/RestService.js";
import AuthInput from "../helpers/AuthInput.vue";
import FormButton from "../helpers/FormButton.vue";
import { inject, ref } from "vue";
import { useRouter } from "vue-router";
import { object, string } from "yup";
import { useForm } from "vee-validate";
import { event } from "vue-gtag";

export default {
  components: { AuthInput, FormButton },
  setup() {
    const notify = inject("notify");

    const router = useRouter();
    const email = ref("");
    const loading = ref(false);

    function trackSuccessReset() {
      event("MAY23_success_password_reset_form_submit", {
        event_category: "Authentication",
        event_label: "MAY23_success_password_reset_form_submit",
        value: 1,
      });
    }

    function trackFailedReset() {
      event("MAY23_failed_password_reset_form_submit", {
        event_category: "Authentication",
        event_label: "MAY23_failed_password_reset_form_submit",
        value: 1,
      });
    }

    async function resetPassword() {
      const payload = {
        email: email.value,
      };

      loading.value = true;
      let res = await RestService.requestPasswordReset(payload);
      loading.value = false;

      if (res.success) {
        trackSuccessReset();
        router.replace({
          name: "ForgetPasswordConfirm",
          params: { email: email.value },
        });
        notify("A password reset link has been sent to your email", {
          success: true,
        });
      } else {
        trackFailedReset();
        notify("Failed to reset password", {
          success: false,
        });
      }
    }

    const validationSchema = object({
      forget_pass_email: string()
        .required("E-mail is required")
        .email("E-mail must be in a valid format"),
    });

    const { handleSubmit, errors, resetForm } = useForm({
      validationSchema,
    });

    const submit = handleSubmit((values) => {
      resetPassword();
    });

    return { email, submit, loading };
  },
};
</script>

<style scoped>
.login-foot > span {
  color: var(--bluey-grey);
}
</style>
