<template>
  <div class="auth-input">
    <label v-if="label" :for="id" class="form-label">{{ label }}</label>
    <div class="input-group">
      <input
        v-model="inputValue"
        class="form-control border-0 rounded-0 ps-0"
        :class="{ 'error-auth-state': !!inputError }"
        v-bind="{ ...$attrs }"
      />
    </div>
    <span v-if="inputError" class="error-message">{{ inputError ?? "" }}</span>
  </div>
</template>

<script>
import { toRef } from "vue";
import { useField } from "vee-validate";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props) {
    const name = toRef(props, "name");

    const { value: inputValue, errorMessage: inputError } = useField(
      name,
      undefined,
      {
        initialValue: props.value,
      }
    );

    return { inputValue, inputError, propName: name };
  },
  watch: {
    value(newVal) {
      this.inputValue = newVal ?? "";
    },
    inputValue(newVal) {
      this.$emit("value-change", newVal);
    },
  },
};
</script>

<style scoped>
.auth-input .form-label {
  color: var(--bluey-grey);
  font-size: 0.92rem;
}
.auth-input .input-group {
  border-radius: 0px;
  box-sizing: border-box;
}
.auth-input .form-control::placeholder {
  color: white;
  opacity: 0.4;
}

.auth-input input[type="email"],
.auth-input input[type="password"],
.auth-input input[type="text"] {
  font-size: 1.1rem !important;
  border-radius: 0px;
  color: #151515;
  background-color: transparent;
  padding: 10px 0;
  border-bottom: 1px solid var(--bluey-grey) !important;
  border-radius: 0px;
}

.auth-input input[type="password"]:focus,
.auth-input input[type="email"]:focus,
.auth-input input[type="text"]:focus {
  border-color: var(--sea);
  box-shadow: none;
  outline: 0 none;
  border-bottom: 2px solid var(--sea) !important;
}

.auth-input input.error-auth-state {
  border-bottom: 1px solid var(--error) !important;
}

/* Change the white to any color */
.auth-input input:-webkit-autofill,
.auth-input input:-webkit-autofill:hover,
.auth-input input:-webkit-autofill:focus,
.auth-input input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
</style>
