<template>
  <div class="card-body pb-1">
    <div
      class="
        d-flex
        flex-row
        chart-header
        justify-content-between
        align-content-center
      "
    >
      <div>
        <h4 class="card-title no-select me-auto">
          {{ metric == "average" ? "Average Spend" : "Total Spend" }}
        </h4>

        <div
          :key="selectedItem?.name"
          class="d-flex justify-content-start align-items-center gap-2"
        >
          <LogoIcon
            :src="
              forCategory
                ? selectedItem?.logo
                : `https://brandlogos.azureedge.net/media/${selectedItem?.logo}`
            "
            :is-category-icon="title === 'Categories'"
            :is-brand-icon="title === 'Brands'"
          />
          <small class="ms-1">{{ selectedItem?.name }}</small>
        </div>

        <div class="card-divider m-0 p-0 py-2 mt-1">
          <span>
            <small>{{ timePeriod }}</small></span
          >
        </div>
      </div>

      <div class="d-flex justify-content-between align-item-end gap-3">
        <div
          v-if="
            ![null, undefined, NaN].includes(numValues.current) &&
            (data ?? []).length !== 0
          "
          class="d-flex flex-column align-items-end me-3"
        >
          <small class="card-title no-select active-text">{{ unit }}</small>
          <h5 class="card-title no-select active-text">
            <small>
              <small>{{ currency + " " }}</small
              ><b>{{ numberWithCommas(numValues.current) }}</b>
            </small>
          </h5>
        </div>

        <div
          v-if="
            ![null, undefined, NaN].includes(numValues.past) &&
            (data ?? []).length !== 0
          "
          class="d-flex flex-column align-items-end me-3"
        >
          <small class="card-title no-select alt-text">{{ unit }}</small>
          <h5 class="card-title no-select alt-text">
            <small>
              <small>{{ currency + " " }}</small
              ><b>{{ numberWithCommas(numValues.past) }}</b>
            </small>
          </h5>
        </div>

        <div v-if="(data ?? []).length !== 0">
          <span
            class="d-inline-block percentage rounded fw-bold w-100 mt-3"
            :class="{
              negative: valueDifference < 0,
            }"
          >
            {{ valueDifference.toFixed(0) }}%
          </span>
        </div>

        <InfoPopover
          data-html2canvas-ignore
          class="pb-2"
          :title="'Spend on ' + title"
          :content="
            title == 'Brands'
              ? `Based on the time period and brand selected, the ${
                  metric === 'average' ? 'average' : 'total'
                } spend for this brand is displayed compared to the preceding time interval`
              : `Based on the time period and category selected, the ${
                  metric === 'average' ? 'average' : 'total'
                } spend for this category is displayed compared to the preceding time interval`
          "
        />
      </div>
    </div>

    <div v-if="(data ?? []).length !== 0" class="pt-3">
      <LineChart class="h30v" :chart-data="chartData" :options="options" />
    </div>

    <cards-empty-state v-else min-height="33" />
  </div>

  <div v-if="loading" class="loader px-auto py-auto">
    <span class="position-absolute top-50 start-50 translate-middle">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </span>
  </div>
</template>

<script>
import { computed, reactive, ref, watchEffect } from "vue";
import { LineChart } from "vue-chart-3";
import "chartjs-adapter-moment";
import { useStore } from "vuex";
import { inject } from "vue";
import moment from "moment";
import "chartjs-adapter-moment";
import InfoPopover from "./InfoPopover.vue";
import CardsEmptyState from "./CardsEmptyState.vue";
import LogoIcon from "./LogoIcon.vue";

export default {
  components: { LineChart, InfoPopover, CardsEmptyState, LogoIcon },
  props: {
    metric: {
      type: String,
      default: "average",
    },
    timePeriod: {
      type: String,
      default: "Last 30 days",
    },
    title: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: () => {},
    },
    summary: {
      type: Object,
      default: () => {},
    },
    selectedItem: {
      type: Object,
      default: () => {},
    },
    period: {
      default: 30,
      type: Number,
    },
    forCategory: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },
  setup(props) {
    // eslint-disable-next-line no-unused-vars
    const isNumber = inject("isNumber");
    const compactNumber = inject("compactNumber");
    const render = ref(0);

    const numValues = reactive({
      past: 0,
      current: 0,
    });

    const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const toFloat = (x) => {
      return (Math.round(x * 100) / 100).toFixed(0);
    };

    const toAbsFloat = (x) => {
      return Math.abs(toFloat(x));
    };

    let unit = ref(props.metric == "average" ? "Avg/day" : "Total spend");

    let valueKey, relKey;

    const rawChartData = computed(() => {
      let _hasSummary = props.summary != null;

      let _avg = props.metric == "average";

      valueKey =
        props.metric == "average"
          ? "tenant_customers_avg_spend"
          : "tenant_customers_total_spend";

      relKey =
        props.metric == "average"
          ? "comparison_period_tenant_customers_avg_spend"
          : "comparison_period_tenant_customers_total_spend";

      let _avg_sum = 0;
      let _avg_count = 0;

      let _rel_sum = 0;
      let _rel_count = 0;

      let dataMap = {
        avg: [],
        rel_avg: [],
      };
      // console.log(props.data);
      // console.log(`💾 Data: ${props.data}`);

      if (props.data) {
        for (const index in props.data) {
          let avg_y = toAbsFloat(props.data[index][valueKey]);

          _avg_sum += avg_y;
          _avg_count++;

          dataMap.avg.push({
            x: props.data[index]?.tenant_customers_transaction_count_date,
            y: avg_y,
          });

          if (props.data[index] != null) {
            let rel_y = toAbsFloat(props.data[index][relKey]);

            _rel_sum += rel_y;
            _rel_count++;

            dataMap.rel_avg.push({
              x: props.data[index]?.tenant_customers_transaction_count_date,
              y: rel_y,
            });
          }
        }
      }

      if (_hasSummary) {
        unit.value = props.summary.unit;

        numValues.current = toAbsFloat(props.summary.avg_exp);
        numValues.past = toAbsFloat(props.summary.rel_avg_exp);
      } else {
        //The divisor is the number of points
        let rel_divisor = _avg ? _rel_count : 1;
        let avg_divisor = _avg ? _avg_count : 1;

        let _past = Math.round(_rel_sum / rel_divisor);
        let _current = Math.round(_avg_sum / avg_divisor);

        numValues.past = _past;
        numValues.current = _current;

        // console.log(`⏱ _rel_sum: ${_rel_sum}`);
        // console.log(`⏰ _current: ${_avg_sum}`);
        // console.log(`⏰ avg_divisor: ${avg_divisor}`);
        // console.log(`⏰ rel_divisor: ${rel_divisor}`);

        // if (props.period <= 31) {
        if (props.period <= 31) {
          //|| props.timePeriod == "Current Month"){
          unit.value = props.metric == "average" ? "Avg/day" : "Total spend";
        } else if (props.period > 31 && props.period < 365) {
          unit.value = props.metric == "average" ? "Avg/week" : "Total spend";
        } else {
          unit.value = props.metric == "average" ? "Avg/month" : "Total spend";
        }
      }

      return dataMap;
    });

    const chartData = computed(() => ({
      datasets: [
        {
          label: "Previous",
          data: rawChartData.value.rel_avg,
          borderColor: "#6f6f6fc2",
          hoverBackgroundColor: "#6f6f6fc2",
          backgroundColor: "#6f6f6fc2",
          borderWidth: 2,
          tension: 0.05,
          pointRadius: 0,
          pointHoverRadius: 5,
          pointStyle: "circle",
        },
        {
          label: "Current",
          data: rawChartData.value.avg,
          borderColor: "#3f7b76",
          hoverBackgroundColor: "#3f7b76",
          backgroundColor: "#3f7b76",
          borderWidth: 2,
          tension: 0.05,
          pointRadius: 0,
          pointHoverRadius: 5,
          pointStyle: "circle",
        },
      ],
    }));

    const getOrCreateTooltip = (chart) => {
      let tooltipEl = chart.canvas.parentNode.querySelector("div");

      if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.style.background = "white";
        tooltipEl.style.borderRadius = "3px";
        tooltipEl.style.color = "black";
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = "none";
        tooltipEl.style.position = "absolute";
        tooltipEl.style.transform = "translate(-50%, 0)";
        tooltipEl.style.transition = "all .1s ease";

        const table = document.createElement("table");
        table.style.margin = "0px";

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }

      return tooltipEl;
    };

    // const externalTooltipHandler = (context) => {
    //   // Tooltip Element
    //   const { chart, tooltip } = context;
    //   const tooltipEl = getOrCreateTooltip(chart);
    //   tooltipEl.style.background = "white";
    //   tooltipEl.style.color = "black";
    //   tooltipEl.style.borderRadius = "3px";

    //   // Hide if no tooltip
    //   if (tooltip.opacity === 0) {
    //     tooltipEl.style.opacity = 0;
    //     return;
    //   }

    //   // Set Text
    //   if (tooltip.body) {
    //     const titleLines = tooltip.title || [];
    //     const bodyLines = tooltip.body.map((b) => b.lines);

    //     const tableHead = document.createElement("thead");

    //     titleLines.forEach((title) => {
    //       const tr = document.createElement("tr");
    //       tr.style.borderWidth = 0;

    //       const th = document.createElement("th");
    //       th.style.borderWidth = 0;
    //       const text = document.createTextNode(title);

    //       th.appendChild(text);
    //       tr.appendChild(th);
    //       tableHead.appendChild(tr);
    //     });

    //     const tableBody = document.createElement("tbody");
    //     bodyLines.forEach((body, i) => {
    //       const colors = tooltip.labelColors[i];

    //       const span = document.createElement("span");
    //       span.style.background = colors.backgroundColor;
    //       span.style.borderColor = colors.borderColor;
    //       span.style.borderWidth = "2px";
    //       span.style.marginRight = "10px";
    //       span.style.height = "10px";
    //       span.style.width = "10px";
    //       span.style.display = "inline-block";

    //       const tr = document.createElement("tr");
    //       tr.style.backgroundColor = "inherit";
    //       tr.style.borderWidth = 0;

    //       const td = document.createElement("td");
    //       td.style.borderWidth = 0;

    //       const text = document.createTextNode(body);

    //       td.appendChild(span);
    //       td.appendChild(text);
    //       tr.appendChild(td);
    //       tableBody.appendChild(tr);
    //     });

    //     const tableRoot = tooltipEl.querySelector("table");

    //     // Remove old children
    //     while (tableRoot.firstChild) {
    //       tableRoot.firstChild.remove();
    //     }

    //     // Add new children
    //     tableRoot.appendChild(tableHead);
    //     tableRoot.appendChild(tableBody);
    //   }

    //   const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    //   // Display, position, and set styles for font
    //   tooltipEl.style.opacity = 1;
    //   tooltipEl.style.left = positionX + tooltip.caretX + "px";
    //   tooltipEl.style.top = positionY + tooltip.caretY + "px";
    //   tooltipEl.style.font = tooltip.options.bodyFont.string;
    //   tooltipEl.style.padding =
    //     tooltip.options.padding + "px " + tooltip.options.padding + "px";
    // };

    const options = ref({
      interaction: {
        mode: "index",
        intersect: false,
      },
      plugins: {
        legend: {
          display: false,
          position: "top",
          align: "start",
          reverse: true,
          labels: {
            usePointStyle: true,
            boxWidth: 10,
            boxHeight: 10,
          },
        },
        tooltip: {
          enabled: true,
          //external: externalTooltipHandler,
          position: "nearest",
          usePointStyle: true,
          backgroundColor: "#f8f9fb",
          titleColor: "black",
          bodyColor: "black",
          padding: 15,
          bodyFont: {
            size: 14,
            lineHeight: 1.9,
          },
          displayColors: true,
          callbacks: {
            title: (ctx) => {
              return;
            },
            label: (context) => {
              let date =
                context.dataset.label == "Current"
                  ? props.data[context.dataIndex]
                      .tenant_customers_transaction_count_date
                  : `${
                      props.data[context.dataIndex]
                        .comparison_period_tenant_customers_transaction_date
                    }`;

              let formatedDate =
                props.period >= 365
                  ? moment(date).format("MMM YYYY")
                  : moment(date).format("D MMM YYYY");

              return `     ${formatedDate}     ${
                currency.value
              } ${compactNumber(context.raw.y, 2)}`;
            },
            labelPointStyle: function (context) {
              return {
                pointStyle: "circle",
                rotation: 0,
              };
            },
          },
        },
      },
      scales: {
        y: {
          min: 0,
          ticks: {
            padding: 8,
            font: {
              size: 12,
            },
            color: "#200e32",
            callback: function (value, index, values) {
              //return value + currency.value;
              return compactNumber(value);
            },
            // callback: function (value, index, values) {
            //   return currency.value + value;
            // },
          },
        },
        x: {
          grid: {
            display: false,
            drawBorder: false,
            // drawTicks: false,
          },

          ticks: {
            padding: 15,
            font: {
              size: 12,
            },
            color: "#200e32",
            callback: function (value, index, values) {
              if (index === 0) return value;
              else if (index + 1 == values.length) return value;
            },
          },
          type: "time",
          time: {
            unit: "day",
          },
        },
      },
      // elements: {
      //   point: {
      //     display: false,
      //     radius: 0,
      //   },
      // },
    });

    // console.log(chartData);
    const store = useStore();
    const currency = computed(() => {
      return store.state.currency;
    });

    const valueDifference = computed(() => {
      let diff = ((numValues.current - numValues.past) / numValues.past) * 100;
      return isNumber(diff) ? diff : 0;
    });

    watchEffect(() => {
      if (props.period >= 365) {
        options.value.scales.x.time.unit = "month";
      } else {
        options.value.scales.x.time.unit = "day";
      }
      //console.log(options.value.scales.x.time.unit)
    });

    return {
      chartData,
      options,
      render,
      unit,
      numValues,
      currency,
      valueDifference,
      numberWithCommas,
    };
  },
};
</script>

<style scoped>
.chart-header {
  border-bottom: 1px solid #dee2e6;
}

.card-divider span small {
  color: var(--eggplant);
  font-weight: 500;
}
.active-text {
  color: #469b93 !important;
}

.alt-text {
  color: #97aabd !important;
}
.h330 {
  height: 330px;
}
.h30v {
  height: 35vh;
}
.percentage {
  background-color: var(--sea-10);
  color: var(--sea);
  font-size: 0.8rem !important;
  padding: 3px 7px;
  border-radius: 7px;
}
.negative {
  background-color: rgba(223, 41, 53, 0.1);
  color: #df2935ec;
}
</style>
