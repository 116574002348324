<template>
  <div>
    <BarChart
      :chart-data="chartData"
      :options="chartOptions"
      :plugins="plugins"
    />
  </div>
</template>

<script>
import { computed, inject, ref } from "vue";
import { BarChart } from "vue-chart-3";
import { useRouter } from "vue-router";
import moment from "moment";
import { useStore } from "vuex";
import { useDiffCalculator } from "@/composables/Helpers";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  components: { BarChart },
  props: {
    primaryData: {
      type: Array,
      required: true,
      default: () => [],
    },
    secondaryData: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: null,
    },
    tooltip: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    const plugins = [ChartDataLabels];
    const compactNumber = inject("compactNumber");
    const router = useRouter();
    const store = useStore();

    const currency = computed(() => {
      return store.state.currency;
    });
    const metric = computed(() => store.state.overViewStore.metricFilters);
    const timeFilters = computed(() => store.state.overViewStore.timeFilters);

    const datesMin = computed(() => {
      const entries = [...props.primaryData];

      if (entries.length === 0) return { min: null, max: null };

      entries.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.x) - new Date(b.x);
      });

      let min = new Date(entries.at(0)?.x);
      let max = new Date(entries.at(-1)?.x);

      let daysOffset = 1;
      if (timeFilters.value?.periodFilter >= 365) {
        daysOffset = 20;
      }
      return {
        min: moment(min.setDate(min.getDate() - daysOffset)).format(
          "YYYY-MM-DD"
        ),
        max: moment(max.setDate(max.getDate() + daysOffset)).format(
          "YYYY-MM-DD"
        ),
      };
    });

    function getDateRange(txDate) {
      let from;
      let to;
      let label;

      const daysCount = timeFilters.value?.periodFilter;
      switch (true) {
        case daysCount <= 31: //Daily
          from = txDate;
          to = txDate;
          label = moment(txDate).format("D MMM, YYYY");
          break;

        case daysCount > 31 && daysCount < 365: //Weekly
          // eslint-disable-next-line no-case-declarations
          const endingDate = new Date(txDate);
          // eslint-disable-next-line no-case-declarations
          const startingDate = new Date(
            endingDate.getFullYear(),
            endingDate.getMonth(),
            endingDate.getDate() - 6
          );

          from = startingDate;
          to = endingDate;
          label =
            moment(from).format("DD MMM, YYYY") +
            " - " +
            moment(to).format("DD MMM, YYYY");
          break;

        case daysCount >= 365: //Monthly
          // eslint-disable-next-line no-case-declarations
          let date = new Date(txDate);
          // eslint-disable-next-line no-case-declarations
          let year = date.getFullYear();
          // eslint-disable-next-line no-case-declarations
          let month = date.getMonth() + 1;

          from = new Date(year, month - 1, 1);
          to = new Date(year, month, 0);
          label =
            moment(from).format("DD MMM, YYYY") +
            " - " +
            moment(to).format("DD MMM, YYYY");
          break;
      }

      return {
        from: moment(from).format("YYYY-MM-DD"),
        to: moment(to).format("YYYY-MM-DD"),
        label,
      };
    }

    function tooltipBtnClicked(dataPoint) {
      //[1] Inject the date filter
      const date = getDateRange(dataPoint?.tenant_transaction_date);
      //[2] Route to Transactions View
      router.push({
        name: "MainTransactions",
        params: {
          //replace with date filter
          timeFilter: JSON.stringify({
            key: "custom",
            rangePeriodFilter: {
              from: date?.from,
              to: date?.to,
            },
            dropDownLabel: date?.label,
            periodFilter: 1,
          }),
        },
      });
    }

    const getOrCreateTooltip = (chart) => {
      //Create tooltip root element
      let tooltipEl = chart.canvas.parentNode.querySelector("div");

      if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.style.background = "#ffffff";
        tooltipEl.style.borderRadius = "10px";
        tooltipEl.style.color = "#002E2A";
        tooltipEl.style.boxShadow =
          "0px 4px 50px 0px rgba(236, 236, 236, 0.85)";
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = "absolute";
        tooltipEl.style.zIndex = "10";
        tooltipEl.style.transform = "translate(-50%, 0)";
        tooltipEl.style.minWidth = "245px";
        tooltipEl.style.maxWidth = "245px";
        tooltipEl.style.transition = "all .2s ease";

        const table = document.createElement("table");
        table.style.width = "100%";

        const tDiv = document.createElement("div");
        tDiv.style.padding = "6px";
        tDiv.appendChild(table);
        tooltipEl.appendChild(tDiv);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }

      return tooltipEl;
    };

    const externalTooltipHandler = (context) => {
      // == Tooltip handler and configs==
      const { chart, tooltip } = context;
      const tooltipEl = getOrCreateTooltip(chart);

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }
      tooltipEl.style.pointerEvents = "auto";
      //== Magic Keep showing the tooltip on Hovering on it  ==
      tooltipEl.addEventListener("mouseout", () => {
        tooltipEl.style.opacity = 0;
        tooltipEl.style.pointerEvents = "none";
      });

      tooltipEl.addEventListener("mouseover", () => {
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = "auto";
      });

      // Set Text
      const pointKey = tooltip?.dataPoints[0]?.dataIndex ?? null;
      if (pointKey !== null) {
        const selectedDataPoint = props.data[pointKey];

        //[1] total spend for all customers
        let totalAmountSpend =
          selectedDataPoint?.tenant_customers_total_spend ?? 0;

        let relTotalAmountSpend =
          selectedDataPoint?.comparison_period_tenant_customers_total_spend ??
          0;

        const diffTotalSpend = useDiffCalculator(
          totalAmountSpend,
          relTotalAmountSpend
        );

        //[2] no of users
        let noUsers = selectedDataPoint?.tenant_customers_count ?? 0;

        //[3] avg spend per customer
        let avgSpend = selectedDataPoint?.tenant_customers_avg_spend ?? 0;
        let relAvgSpend =
          selectedDataPoint?.comparison_period_tenant_customers_avg_spend ?? 0;
        const diffAvgSpend = useDiffCalculator(avgSpend, relAvgSpend);

        const tableBody = document.createElement("tbody");
        tableBody.classList.add("tooltip-tbody");

        //Row 1
        const tRow_1 = document.createElement("tr");
        const tData_01 = document.createElement("td");
        tData_01.setAttribute("colspan", 2);

        //wrapper
        const tData_01_Div = document.createElement("div");
        //Set Date
        const tData_01_H5 = document.createElement("h5");
        tData_01_H5.innerHTML =
          timeFilters.value?.periodFilter >= 365
            ? moment(selectedDataPoint.tenant_transactions_date).format(
                "MMMM YYYY"
              )
            : moment(selectedDataPoint.tenant_transactions_date).format(
                "D MMMM YYYY"
              );

        //Set Image
        const tData_01_Img = document.createElement("img");
        if (metric.value.key === "total") {
          tData_01_Img.src = require(`@/assets/images/svg/components/${
            diffTotalSpend?.diffSign ? "Trending-UP.svg" : "Trending-Down.svg"
          }`);
        } else {
          tData_01_Img.src = require(`@/assets/images/svg/components/${
            diffAvgSpend?.diffSign ? "Trending-UP.svg" : "Trending-Down.svg"
          }`);
        }

        tData_01_Div.appendChild(tData_01_H5);
        tData_01_Div.appendChild(tData_01_Img);
        tData_01.appendChild(tData_01_Div);
        tRow_1.appendChild(tData_01);

        //Row 2
        const tRow_2 = document.createElement("tr");
        const tData_11 = document.createElement("td");
        const tData_12 = document.createElement("td");

        //Wrapper
        const tData_11_Temp = document.createElement("div");
        tData_11_Temp.innerHTML = `
            <div class='d-flex flex-column'>
              <h6>Total Spend</h6>
              <h5>${compactNumber(Math.abs(totalAmountSpend), 2)} ${
          currency.value
        }</h5>
            </div>`;

        const tData_12_Temp = document.createElement("div");
        tData_12_Temp.innerHTML = `
            <div class='d-flex flex-column ps-4'>
              <h6>Customers</h6>
              <h5>${compactNumber(noUsers)}</h5>
            </div>`;

        tData_11.appendChild(tData_11_Temp);
        tData_12.appendChild(tData_12_Temp);

        tRow_2.appendChild(tData_11);
        tRow_2.appendChild(tData_12);

        //Row 3
        const tRow_3 = document.createElement("tr");
        const tData_21 = document.createElement("td");
        tData_21.setAttribute("colspan", 2);

        //Wrapper
        const tData_21_Temp = document.createElement("div");
        tData_21_Temp.innerHTML = `
            <div class='d-flex flex-column'>
              <h6>Average/Customer</h6>
              <h5>${compactNumber(Math.abs(avgSpend), 2)} ${currency.value}</h5>
            </div>`;

        tData_21.appendChild(tData_21_Temp);

        tRow_3.appendChild(tData_21);

        //Row 4
        const tRow_4 = document.createElement("tr");
        const tData_31 = document.createElement("td");
        tData_31.setAttribute("colspan", 2);

        //Wrapper
        const tData_31_Temp = document.createElement("div");
        tData_31_Temp.innerHTML = `<div></div>`;

        const toolTipButton = document.createElement("button");
        toolTipButton.innerHTML = "View transactions";
        toolTipButton.addEventListener("click", () => {
          tooltipBtnClicked(selectedDataPoint);
        });

        tData_31_Temp.appendChild(toolTipButton);
        tData_31.appendChild(tData_31_Temp);

        tRow_4.appendChild(tData_31);
        // tRow_4.appendChild(tData_32);

        //Add all rows to tBody
        tableBody.appendChild(tRow_1);
        tableBody.appendChild(tRow_2);
        tableBody.appendChild(tRow_3);
        tableBody.appendChild(tRow_4);

        //Add tBody to root table
        const tableRoot = tooltipEl.querySelector("table");
        tableRoot.appendChild(tableBody);

        // Remove old children from previous hovering event
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove();
        }
        // Add new children
        tableRoot.appendChild(tableBody);
      }

      // == Postioning it  ==
      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

      let leftPosition = positionX + tooltip.caretX;
      //left start point
      if (leftPosition < 75) leftPosition += 50;
      else if (leftPosition > 800) leftPosition -= 80;

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = leftPosition + "px";
      tooltipEl.style.top = positionY + tooltip.caretY + 27 + "px"; //"91%";
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding =
        tooltip.options.padding + "px " + tooltip.options.padding + "px";
    };

    const chartData = computed(() => ({
      datasets: [
        {
          label: "Previous",
          data: [...props.secondaryData],
          borderColor: "#B3C0BF",
          hoverBackgroundColor: "#727b7af0",
          backgroundColor: "#B3C0BF",
          tension: 0.05,
          borderRadius: 5,
          order: 1,
        },
        {
          label: "Current",
          data: [...props.primaryData],
          borderColor: "#469B93",
          hoverBackgroundColor: "#002E2A",
          backgroundColor: "#469B93",
          tension: 0.05,
          borderRadius: 5,
          order: 0,
        },
      ],
    }));

    const defaultOptions = ref({
      categoryPercentage: 0.5,
      interaction: {
        mode: "index",
        intersect: false,
      },
      plugins: {
        legend: {
          display: false,
          position: "top",
          align: "start",
          reverse: true,
          labels: {
            usePointStyle: true,
            boxWidth: 10,
            boxHeight: 10,
          },
        },
        tooltip: {
          enabled: false,
          external: props.tooltip ?? externalTooltipHandler,
        },
        datalabels: {
          display: () => false,
        },
      },
      scales: {
        y: {
          grid: {
            display: true,
            drawBorder: false,
          },
          min: 0,
          ticks: {
            padding: 10,
            font: {
              size: 12,
            },
            color: "#66827F",
            // eslint-disable-next-line no-unused-vars
            callback: function (value, index, values) {
              //return value + currency.value;
              return compactNumber(value);
            },
          },
        },
        x: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            padding: 10,
            font: {
              size: 12,
            },
            color: "#66827F",
            callback: function (value, index, values) {
              let formatedDate = value.split(" ");
              if (formatedDate.length === 0) return value;

              formatedDate = formatedDate[1] + " " + formatedDate[0];

              let daysOffset = 1;
              if (timeFilters.value?.periodFilter >= 365) {
                daysOffset = 20;
              }
              if (index === daysOffset) return formatedDate;
              else if (index + daysOffset === values.length - 1)
                return formatedDate;
            },
          },
          min: datesMin.value?.min,
          max: datesMin.value?.max,
          type: "time",
          time: {
            unit: "day",
          },
          offset: true,
        },
      },
      // elements: {
      //   point: {
      //     display: false,
      //     radius: 0,
      //   },
      // },
    });

    const chartOptions = computed(() => {
      return { ...(props.options ?? defaultOptions.value) };
    });

    return { chartData, chartOptions, plugins };
  },
};
</script>

<style lang="scss">
.tooltip-tbody {
  // border: 1px solid red;
  font-family: "Poppins", sans-serif !important;
  font-size: 0.9rem;
  font-weight: 400;
  width: 100%;
  div h6,
  div h5 {
    padding: 0;
    margin: 0;
    font-weight: 400;
  }
  div h5 {
    font-size: 0.88rem;
  }
  div h6 {
    font-size: 0.85rem !important;
    color: var(--dark-green-50, #809795);
    padding-bottom: 0.35rem;
  }
  div img {
    width: 24px;
    margin-left: 8px;
  }
  div button {
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    color: var(--sea);
    text-decoration: underline;
    transition: var(--d-ease);

    &:hover {
      color: var(--green);
    }
  }
}
.tooltip-tbody tr td {
  // border: 1px solid blue;
  padding: 0.45rem 0;
  width: 50%;
}
.tooltip-tbody tr:first-child td {
  h5 {
    font-weight: 600;
  }
  div {
    background: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
  }
}
.tooltip-percent {
  color: var(--sea);
  font-weight: 500 !important;
}
</style>
