<template>
  <button
    class="d-flex align-items-center gap-2"
    :class="{
      active: sortIsActive || reverseSorting,
      'active-down': !sortIsActive,
      'active-up': sortIsActive,
    }"
  >
    <span v-if="label">{{ label }}</span>
    <img src="@/assets/images/svg/components/sort-arrow.svg" alt="sort arrow" />
  </button>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    sortIsActive: {
      type: Boolean,
      default: false,
    },
    reverseSorting: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  background-color: transparent;
  border: none;
  font-size: var(--fs-input);
  color: var(--green);

  &:hover {
    text-decoration: underline;
  }
  &.active {
    color: var(--sea);
    img {
      filter: brightness(0) saturate(100%) invert(56%) sepia(16%)
        saturate(1158%) hue-rotate(126deg) brightness(93%) contrast(86%);
    }
  }
  img {
    transition: 0.5s; /* Transition duration */
    filter: brightness(0) saturate(100%) invert(14%) sepia(40%) saturate(1218%)
      hue-rotate(131deg) brightness(94%) contrast(102%);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  &.active-up img {
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  &.active-down img {
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
</style>
