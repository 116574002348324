<template>
  <div class="card-body d-flex flex-column h-100 pb-1">
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="card-title">
        {{ title }}
      </h4>
      <!-- v-if="title === 'Categories'" -->

      <button
        v-if="fullscreen"
        id="close-modal-icon"
        class="btn btn-sm border-0"
        @click="toggleModal(false, title)"
      >
        <i class="bi bi-x-lg"></i>
      </button>

      <InfoPopover
        v-if="!fullscreen"
        class="pb-2"
        theme="sea"
        :title="title"
        :content="
          title == 'Inbound - Entities'
            ? 'Based on the time period chosen , the amount of inbound transfers by each entity is displayed.'
            : 'Based on the time period chosen , the amount of outbound transfers by each entity is displayed.'
        "
      />
    </div>

    <div class="d-flex flex-row mt-1">
      <span class="border-bottom py-2 border-dark btn-active">
        <small class="text-dark">{{
          metric === "average" ? "Avg. Amounts" : "Total Amounts"
        }}</small>
      </span>
      <span class="flex-grow-1 border-bottom py-2">
        <!-- <small class="text-dark"><strong>Brands</strong></small> -->
      </span>
    </div>

    <div
      v-if="values.length !== 0"
      class="flex-grow-1"
      :class="[
        fullscreen ? 'overflow-auto pe-1' : 'h30v',
        values.length < 1 ? 'pt-5 pb-3' : 'pt-3 mb-2',
      ]"
    >
      <table class="w-100">
        <tr
          v-for="(entry, index) in filteredList"
          :key="entry[0]"
          :class="[
            index == 0 ? '' : 'border-top border-light',
            // fullscreen ? 'my-3' : '',
          ]"
        >
          <td class="text-start w-100">
            <div
              class="d-flex justify-content-start align-items-center gap-2 pe-1"
            >
              <i class="rounded me-1 bi-brand">
                <!-- !hack -->
                <img
                  src="@/assets/images/svg/transfer-bank.svg"
                  alt="Icon"
                  width="14"
                  class="brand-icon"
                />
              </i>

              <small>{{ getLabel(entry) }}</small>
            </div>
          </td>

          <td class="text-start text-nowrap">
            <!-- {{entry[1]}} -->
            <div class="mx-2">
              <span class="d-inline-block py-2 my-1 me-1">
                <small>
                  <small
                    ><small> {{ currency }}</small></small
                  >
                </small>
              </span>

              <span class="d-inline-block py-2 my-1">
                <small>
                  {{
                    numberWithCommas(
                      Math.abs(Number(getCountString(entry[1])).toFixed(0))
                    )
                  }}
                </small>
              </span>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <CardsEmptyState v-else min-height="33" />

    <div
      v-if="!fullscreen && values.length > 5"
      class="py-0 my-0 pt-2 d-flex justify-content-between align-items-center"
    >
      <p class="m-0 text-end card-foot">
        <small>
          +{{ numberWithCommas(values.length - 5) }} other Entities{{
            values.length > 6 ? "s" : ""
          }}
        </small>
      </p>

      <div
        class="
          card-link
          cp
          d-flex
          justify-content-between
          align-items-center
          gap-2
        "
        @click="
          toggleModal(true, title);
          trackViewAll(title);
        "
      >
        <small class="view-more-btn">View All Entities</small>
        <img
          class="arrow-right"
          src="@/assets/images/svg/arrow-point-right.svg"
          alt="arrow"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InfoPopover from "./InfoPopover.vue";
import CardsEmptyState from "./CardsEmptyState.vue";

export default {
  components: { InfoPopover, CardsEmptyState },
  props: {
    title: {
      type: String,
      default: "Card Title",
    },
    metric: {
      type: String,
      default: "average",
    },
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["toggleModal"],
  data() {
    return {
      default_brand_logo: "@/assets/images/png/default_brand.png",
      invalid_urls: [],
    };
  },

  computed: {
    currency() {
      return this.$store.state.currency;
    },
    filteredList() {
      return (
        this.entries
          .sort(
            (a, b) =>
              Math.abs(this.getCount(b[1])) - Math.abs(this.getCount(a[1]))
          )

          // .map((v) => v[1])
          .slice(0, this.fullscreen ? undefined : 5)
      );
    },
    values() {
      return Object.values(this.data ?? {});
    },
    entries() {
      let _entries = Object.entries(this.data ?? {});
      return _entries;
    },
  },

  methods: {
    trackViewAll(title) {
      this.$gtag.event(`MAY23_click_viewall_entities_${title?.toLowerCase()}`, {
        event_category: "Engagement",
        event_label: `MAY23_click_viewall_entities_${title?.toLowerCase()}`,
        value: 1,
      });
    },
    toggleModal(state, title) {
      this.$emit("toggleModal", state, title);
    },
    markInvalidUrl(url) {
      this.invalid_urls.push(url);
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getDelta(value) {
      value = (Math.round(value * 100) / 100).toFixed(0);

      if (value == 0) {
        return "+" + 0;
      }
      return (value < 0 ? "" : "+") + (value ?? "NA");
    },
    getCount(entry) {
      let value; // = entry.value;

      if (this.metric == "average") {
        value =
          this.title === "Inbound - Entities"
            ? entry.tenant_customers_avg_deposit
            : entry.tenant_customers_avg_spend;
      } else {
        value =
          this.title === "Inbound - Entities"
            ? entry.tenant_customers_total_deposit
            : entry.tenant_customers_total_spend;
      }

      return value ?? 0;
    },
    getCountString(entry) {
      return this.getDelta(this.getCount(entry));
    },
    getIcon(entry) {
      return `${process.env.VUE_APP_IMG_BASE_URL}${entry[1].brand_logo}`;
    },
    getLabel(entry) {
      let label;
      if (this.metric == "average") {
        label = entry[1].tenant_customers_account_name;
      } else {
        label = entry[1].tenant_customers_account_name;
      }
      return label ?? "NA";
    },
  },
};
</script>

<style scoped>
td {
  color: var(--eggplant) !important;
}
.card-divider {
  border-bottom: 1px solid;
  padding: 5px 0;
}
.card-divider span small {
  color: var(--span-grey);
  font-weight: 500;
}

.content {
  height: 35vh !important;
}

p {
  color: var(--black-two);
  font-size: 0.8rem;
}

.list-group-item {
  border: none !important;
}
.bi-category {
  background-color: var(--bluey-grey-10);
  color: var(--sea);
  padding: 4px 7px;
}
.category-icon {
  filter: invert(54%) sepia(64%) saturate(310%) hue-rotate(126deg)
    brightness(88%) contrast(91%);
}
.brand-icon {
  width: 28px !important;
  border-radius: 4px;
}
.percentage {
  background-color: var(--sea-10);
  color: var(--sea);
  font-size: 0.8rem;
  padding: 3px 7px;
  border-radius: 7px;
}

.negative {
  background-color: rgba(223, 41, 53, 0.1);
  color: #df2935ec;
}

.card-foot {
  font-size: 1rem;
  color: var(--bluey-grey);
}

.scroll-y {
  overflow-y: scroll !important;
}
.view-more-btn {
  font-weight: 500 !important;
}
.card-link:hover .view-more-btn {
  color: var(--black-three);
}
.card-link:hover img {
  filter: invert(0%) sepia(4%) saturate(8%) hue-rotate(0deg) brightness(12%)
    contrast(30%);
}
</style>
