<template>
  <div
    class="loading-container d-flex justify-content-center align-items-center"
  >
    <i class="pi pi-spin pi-spinner" style="color: var(--green)"></i>
  </div>
</template>

<script>
export default {
  props: {
    minHeight: {
      type: String,
      default: "35vh",
    },
    iconSize: {
      type: String,
      default: "2.3rem",
    },
  },
};
</script>

<style lang="scss" scoped>
.pi-spin {
  opacity: 0.65;
  font-size: v-bind("iconSize") !important;
}
.loading-container {
  min-height: v-bind("minHeight");
}
</style>
