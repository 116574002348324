<template>
  <div
    class="
      d-flex
      flex-grow-1 flex-row flex-wrap
      justify-content-between
      overflow-auto
      h-100
      position-relative
    "
  >
    <div class="p-2 col-xl-8 col-lg-12 col-md-12 col-sm-12">
      <div class="card p-3 h-100 position-relative">
        <AgeDistributionChart
          :period="periodFilter"
          :data="ageDistribution"
          :metric="metric"
          :time-period="timePeriod"
        />

        <div v-if="loadingAgeDistribution" class="loader px-auto py-auto">
          <span class="position-absolute top-50 start-50 translate-middle">
            <div class="spinner-border" role="status">
              <!-- <span class="sr-only">Loading...</span> -->
            </div>
          </span>
        </div>
      </div>
    </div>

    <div class="p-2 col-xl-4 col-lg-6 col-md-6 col-sm-12">
      <div class="card user-card p-3 h-100 position-relative">
        <UserCard :metric="metric" :data="customersSummaryData" />

        <div v-if="loadingExpenses" class="loader px-auto py-auto">
          <span class="position-absolute top-50 start-50 translate-middle">
            <div class="spinner-border" role="status"></div>
          </span>
        </div>
      </div>
    </div>
    <div class="pb-xl-5 mb-xl-4 p-2 col-xl-4 col-lg-6 col-md-6 col-sm-12">
      <!-- border -->
      <div class="card p-3 h-100 position-relative">
        <GendersCard :data="gendersData" />

        <div v-if="loadingGenderDistribution" class="loader px-auto py-auto">
          <span class="position-absolute top-50 start-50 translate-middle">
            <div class="spinner-border" role="status"></div>
          </span>
        </div>
      </div>
    </div>

    <div class="pb-5 mb-4 p-2 col-xl-8 col-lg-12 col-md-12 col-sm-12">
      <div class="card p-3 h-100 position-relative">
        <CountryDistributionChart
          :period="periodFilter"
          :data="countryDistribution"
          :metric="metric"
          :time-period="timePeriod"
          @toggleCountryModal="toggleCountryModal"
        />

        <div v-if="loadingCountryDistribution" class="loader px-auto py-auto">
          <span class="position-absolute top-50 start-50 translate-middle">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="countryChartIsFullscreen"
      class="bg-transcluscent d-flex fixed-top h-100"
    >
      <div
        class="
          mx-auto
          top-50
          start-50
          w-75
          h-75
          rounded
          bg-white
          row
          justify-content-center
          align-self-center
          p-2
        "
      >
        <CountryDistributionChart
          :period="periodFilter"
          :data="countryDistribution"
          :metric="metric"
          :time-period="timePeriod"
          :fullscreen="true"
          @toggleCountryModal="toggleCountryModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AgeDistributionChart from "@/components/AgeDistributionChart.vue";
import CountryDistributionChart from "@/components/CountryDistributionChart.vue";
import GendersCard from "@/components/GendersCard.vue";
import UserCard from "@/components/UserCard.vue";
import DemographicsAPI from "@/services/api/DemographicsAPI";
import OverviewAPI from "@/services/api/OverviewAPI";

export default {
  name: "Demography",
  components: {
    GendersCard,
    AgeDistributionChart,
    CountryDistributionChart,
    UserCard,
  },
  inject: ["useDiffCalculator"],
  props: {
    fetchKey: {
      type: Number,
      default: 1,
    },
    timePeriod: {
      type: String,
      default: "Last 30 days",
    },
    metric: {
      type: String,
      default: "average",
    },
    filters: {
      type: Object,
      default: () => {},
    },
    periodFilter: {
      type: Number,
      default: 30,
    },
  },

  data() {
    return {
      customersSummaryData: {},
      gendersData: {
        male: {
          gender: "Male",
          count: 0,
          rel_perc_value: 0,
        },
        female: {
          gender: "Female",
          count: 0,
          rel_perc_value: 0,
        },
      },

      ageDistribution: [],
      countryDistribution: [],

      // filters: null,
      cashFlowIsDeposits: true,

      loadingAgeDistribution: false,
      loadingGenderDistribution: false,
      loadingExpenses: false,
      // ----------------------
      loadingCountryDistribution: false,

      countryChartIsFullscreen: false,
    };
  },

  watch: {
    fetchKey() {
      this.fetchData();
    },
  },

  mounted() {
    //fetch onMount
    if (
      this.filters?.tenant_transaction_from_date ||
      this.filters?.tenant_transaction_to_date
    ) {
      this.fetchData();
    }
  },
  unmounted() {
    this.$store.dispatch("CANCEL_PENDING_REQUESTS");
  },

  methods: {
    toggleCountryModal(state) {
      this.countryChartIsFullscreen = state ?? !this.countryChartIsFullscreen;
    },
    fetchData() {
      this.$store.dispatch("CANCEL_PENDING_REQUESTS");
      let filters = { ...this.filters };

      //Remove date filter from users endpoints
      const queryFilters = { ...filters };

      this.loadingExpenses = true;
      OverviewAPI.getUserSummary(filters)
        .then((response) => {
          this.customersSummaryData = response?.data;
          this.loadingExpenses = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            this.loadingExpenses = false;
            this.customersSummaryData = {};
            console.error("error: ", error);
          }
        });

      this.loadingAgeDistribution = true;
      OverviewAPI.getUsersAgesData(queryFilters)
        .then((response) => {
          this.ageDistribution = response.data?.demographics ?? [];
          this.loadingAgeDistribution = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            this.loadingAgeDistribution = false;
            this.ageDistribution = [];
            console.error("error: ", error);
          }
        });

      this.loadingGenderDistribution = true;
      DemographicsAPI.getUsersGendersData(queryFilters)
        .then((response) => {
          const demographics = response.data?.demographics ?? [];
          this.loadingGenderDistribution = false;
          if (demographics.length === 0) {
            this.gendersData = {};
            return;
          }
          //map the response
          demographics.forEach((item) => {
            const key = Object.keys(item)[0];

            this.gendersData[key.toLowerCase()] = {
              gender: item[key]?.tenant_customers_gender,
              count: item[key]?.tenant_customers_count,
              rel_perc_value: this.useDiffCalculator(
                item[key]?.tenant_customers_count ?? 0,
                item[key]?.comparison_period_tenant_customers_count ?? 0
              ).diffValue,
            };
          });
        })
        .catch((error) => {
          if (error !== "canceled") {
            this.loadingGenderDistribution = false;
            this.gendersData = {
              male: {
                gender: "Male",
                count: 0,
                rel_perc_value: 0,
              },
              female: {
                gender: "Female",
                count: 0,
                rel_perc_value: 0,
              },
            };
            console.error("error: ", error);
          }
        });

      this.loadingCountryDistribution = true;
      DemographicsAPI.getUsersCountriesData(queryFilters)
        .then((response) => {
          this.countryDistribution = response.data?.demographics ?? [];
          this.loadingCountryDistribution = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            this.loadingCountryDistribution = false;
            this.countryDistribution = [];
            console.error("error: ", error);
          }
        });
    },
  },
};
</script>
<style>
.bg-transcluscent {
  background: rgba(100, 100, 100, 0.2);
}
.sr-only {
  display: none !important;
}
</style>
