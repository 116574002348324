<template>
  <div>
    <label v-if="showLabel" :for="label" class="form-label">{{ label }}</label>
    <label v-else-if="value?.label == null" :for="label" class="form-label">{{
      label
    }}</label>

    <div
      :id="id"
      type="button"
      :data-bs-toggle="disabled ? '' : 'dropdown'"
      aria-expanded="false"
      :class="{ disable: disabled, 'border-less-dropdown': borderLess }"
      class="drop-down card cp no-select no-click"
    >
      <div v-if="!borderLess" class="d-flex flex-row align-items-center">
        <span v-if="value?.label != null" class="flex-grow-1 ps-2">{{
          value?.label
        }}</span>
        <span v-else class="flex-grow-1 ps-2 text-muted"
          ><span v-if="showPlaceholder">Choose {{ label }}</span></span
        >
        <i class="bi bi-chevron-down ms-1 pe-2"></i>
      </div>

      <div v-else class="m-0 p-0">
        <span v-if="value?.label != null">{{ value?.label }}</span>
      </div>
    </div>

    <ul
      class="dropdown-menu p-3 rounded-3 mt-1 no-select"
      :aria-labelledby="id"
    >
      <li
        v-for="option in Object.values(options)"
        :key="option.id"
        class="dropdown-item px-1"
        @click="onSelect(option)"
      >
        <label class="my-1 cp">
          <div class="px-2">
            <span>{{ option.label }}</span>
          </div>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      default: "",
    },
    id: {
      default: "dropdown",
    },
    disabled: {
      default: false,
    },
    borderLess: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      default: true,
    },
    showPlaceholder: {
      default: true,
    },
    forceClose: {
      default: false,
    },
    selection: {
      type: Object,
    },
    options: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      value: null,
    };
  },
  created() {
    this.onSelect(this.selection);
  },
  methods: {
    onSelect(option) {
      //console.log(option);
      if (option) {
        this.value = option;
        this.$emit("onSelect", option.id);

        if (this.forceClose) document.querySelector(`#${this.id}`)?.click();
      }
    },
  },
};
</script>

<style scoped>
.form-label {
  color: var(--bluey-grey);
  font-weight: 500;
}
.card {
  background-color: unset !important;
  border-radius: 8px !important;
}
.drop-down {
  border: 1px solid var(--input-border) !important;
  padding: 7.63px 0 !important;
}
.dropdown-menu {
  min-width: 20rem !important;
  max-height: 350px !important;
  overflow: auto !important;
}
.border-less-dropdown {
  color: var(--eggplant);
  background-color: transparent !important;
  margin: 0 !important;

  border: none !important;
  border-bottom: 1px solid var(--bluey-grey) !important;
  border-radius: 0px !important;
}
</style>
