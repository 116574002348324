<template>
  <div class="h-100 pb-4 px-4 hide-scrollbar">
    <div class="py-4">
      <BaseViewInfoText />
    </div>
    <div class="mt-2 d-flex align-items-end justify-content-between">
      <div class="w-50">
        <BaseSearchAction
          :id="`search-${$route.meta.label}-type`"
          v-model="searchQry"
          :label="$route.meta.label"
          :show-button="false"
        />
      </div>
      <div class="w-50 pb-2">
        <DetailedSpendTypesFilters v-model="sortOption" />
      </div>
    </div>

    <DetailedSpendTypesGrid
      v-if="!spendPerCurrencyLoading && !spendPerTxTypeLoading"
      class="my-4 pt-2"
      :data="sortedList"
    />
    <BaseLoadingCard v-else class="my-4 pt-2" />
  </div>
</template>

<script>
import BaseLoadingCard from "../components/helpers/BaseLoadingCard.vue";
import { useStore } from "vuex";
import { ref, reactive, computed, onUnmounted, watch, onMounted } from "vue";
import DetailedSpendTypesFilters from "@/components/DetailedSpendTypesFilters.vue";
import BaseSearchAction from "@/components/helpers/BaseSearchAction.vue";
import BaseViewInfoText from "@/components/helpers/BaseViewInfoText.vue";
import DetailedSpendTypesGrid from "@/components/DetailedSpendTypesGrid.vue";
import OverviewAPI from "@/services/api/OverviewAPI";
import { useRoute } from "vue-router";
export default {
  components: {
    BaseViewInfoText,
    BaseSearchAction,
    DetailedSpendTypesFilters,
    DetailedSpendTypesGrid,
    BaseLoadingCard,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const searchQry = ref("");
    const sortOption = ref(0);
    const sortOptions = reactive({
      spend: 0,
      customers: 1,
    });
    const spendPerCurrencyLoading = ref(false);
    const spendPerCurrencyData = ref([]);
    const spendPerTxTypeLoading = ref(false);
    const spendPerTxTypeData = ref([]);

    const metricFilters = computed(
      () => store.state.overViewStore.metricFilters
    );
    const filters = computed(() => {
      return store.getters.filters;
    });

    const setupTransfersSpendData = (dataList, type) => {
      if ((dataList ?? []).length === 0) return [];

      let data = [];
      dataList.forEach((item) => {
        data.push({
          ...item,
          tx_count: item?.tenant_customers_transactions_count,
          users_count: item?.tenant_customers_count,
          label:
            type === "currency"
              ? item?.tenant_customers_currency_code
              : item?.tenant_transaction_type,
          total_amount: item?.tenant_customers_total_spend,
          avg_amount: item?.tenant_customers_avg_spend,
          rel_total_amount:
            item?.comparison_period_tenant_customers_total_spend,
          rel_avg_amount: item?.comparison_period_tenant_customers_avg_spend,
          rel_users_count: item?.comparison_period_tenant_customers_count,
        });
      });

      return data;
    };

    const getSpendPerCurrencyData = () => {
      spendPerCurrencyLoading.value = true;
      const queryFilters = { ...filters.value };
      delete queryFilters.tenant_transaction_currency_codes;

      OverviewAPI.getSpendPerCurrencyData(queryFilters)
        .then((res) => {
          spendPerCurrencyData.value = setupTransfersSpendData(
            res.data?.tenant_customers_spends ?? [],
            "currency"
          );
          spendPerCurrencyLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            spendPerCurrencyData.value = [];
            spendPerCurrencyLoading.value = false;
            console.error("error: ", error);
          }
        });
    };
    const getSpendPerTxTypeData = () => {
      spendPerTxTypeLoading.value = true;
      OverviewAPI.getSpendPerTxTypeData(filters.value)
        .then((res) => {
          spendPerTxTypeData.value = setupTransfersSpendData(
            res.data?.tenant_customers_spends ?? [],
            "txType"
          );
          spendPerTxTypeLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            spendPerTxTypeData.value = [];
            spendPerTxTypeLoading.value = false;
            console.error("error: ", error);
          }
        });
    };
    const fetch = () => {
      const routeName = route.name ?? "AllCurrencies";
      if (routeName === "AllCurrencies") return getSpendPerCurrencyData();

      return getSpendPerTxTypeData();
    };

    const dataList = computed(() => {
      const routeName = route.name ?? "AllCurrencies";
      if (routeName === "AllCurrencies") return spendPerCurrencyData.value;

      return spendPerTxTypeData.value;
    });
    const sortedList = computed(() => {
      const list = dataList.value;
      let sortedList = [];
      // exit if empty
      if (list.length === 0) return [];

      //[1] sort the list
      sortedList = list.sort((a, b) => {
        let res = 0;

        switch (sortOption.value) {
          case sortOptions.spend:
            res = Math.abs(getSpend(b)) - Math.abs(getSpend(a));
            break;
          case `reverse-${sortOptions.spend}`:
            res = Math.abs(getSpend(a)) - Math.abs(getSpend(b));
            break;

          case sortOptions.customers:
            if (a.users_count || b.users_count)
              res =
                Math.abs(b?.users_count ?? 0) - Math.abs(a?.users_count ?? 0);
            break;

          case `reverse-${sortOptions.customers}`:
            if (a.users_count || b.users_count)
              res =
                Math.abs(a?.users_count ?? 0) - Math.abs(b?.users_count ?? 0);
            break;

          default:
            break;
        }

        return res;
      });

      //[2] apply search on the list
      if (searchQry.value) {
        sortedList = sortedList.filter((item) =>
          (item?.label ?? "")
            .toLowerCase()
            .includes(searchQry.value.toLowerCase())
        );
      }

      return sortedList;
    });

    function getSpend(option) {
      if (metricFilters.value.key === "average") {
        return option?.avg_amount ?? 0;
      }

      return option?.total_amount ?? 0;
    }

    //watchers
    watch(
      () => filters.value,
      (currFilters, prevFilters) => {
        if (prevFilters?.tenant_transaction_from_date) {
          //[1] Abort all previous pending requests
          store.dispatch("CANCEL_PENDING_REQUESTS");

          //[2] Fetch new requests
          fetch();
        }
      },
      {
        deep: true,
      }
    );

    //hooks
    onMounted(() => {
      fetch();
    });
    onUnmounted(() => {
      store.dispatch("CANCEL_PENDING_REQUESTS");
    });

    return {
      searchQry,
      sortOption,
      spendPerCurrencyLoading,
      spendPerTxTypeLoading,
      sortedList,
    };
  },
};
</script>

<style lang="scss" scoped></style>
