<template>
  <div class="bg-white lu-summary-cards-conatiner">
    <div
      v-if="!loading"
      class="
        d-flex
        flex-row flex-wrap
        justify-content-between
        align-items-center
        gap-md-3 gap-lg-0
      "
    >
      <BaseSummaryCard
        class="ov-summ-card"
        title="Total Customers"
        :amount="compactNumber(summaryData?.tenant_customers_count ?? 0, 2)"
        :is-trending-positively="totalCustomerCountDiff?.diffSign"
        :icon="require('@/assets/images/svg/components/Users.svg')"
        :is-clickable="true"
        @click="routeToUsers"
      />
      <BaseSummaryCard
        class="ov-summ-card"
        title="Avg Spent / Customer"
        :amount="compactNumber(currentAvgCustomerSpend ?? 0, 2)"
        :is-trending-positively="avgCustomerSpendDiff?.diffSign"
        :show-currency="true"
        :icon="require('@/assets/images/svg/components/Average.svg')"
      />
      <BaseSummaryCard
        class="ov-summ-card"
        title="Total Amount Spent"
        :amount="
          compactNumber(
            Math.abs(summaryData?.tenant_customers_total_spend ?? 0),
            2
          )
        "
        :is-trending-positively="totalAmountSpendDiff?.diffSign"
        :show-currency="true"
        :icon="require('@/assets/images/svg/components/Fixed-amount.svg')"
        :is-clickable="true"
        @click="routeToTxs"
      />
      <BaseSummaryCard
        class="ov-summ-card"
        title="Avg Spent / Day"
        :amount="
          compactNumber(
            Math.abs(summaryData?.tenant_customers_avg_daily_spend ?? 0),
            2
          )
        "
        :is-trending-positively="avgSpendDayDiff?.diffSign"
        :show-currency="true"
        :icon="require('@/assets/images/svg/components/Average.svg')"
      />
    </div>
    <BaseLoadingCard v-else min-height="60px" icon-size="1.8rem" />
  </div>
</template>
<script>
import BaseLoadingCard from "./helpers/BaseLoadingCard.vue";
import BaseSummaryCard from "../components/helpers/BaseSummaryCard.vue";
import { computed, inject } from "vue";
import { useDiffCalculator } from "../composables/Helpers";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  components: { BaseSummaryCard, BaseLoadingCard },
  props: {
    summaryData: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const compactNumber = inject("compactNumber");
    const router = useRouter();
    const store = useStore();
    const currency = computed(() => store.state.currency);

    const currentAvgCustomerSpend = computed(() => {
      let avg =
        parseInt(props.summaryData?.tenant_customers_total_spend) /
        parseInt(props.summaryData?.tenant_customers_count);

      avg = isFinite(avg) ? Math.abs(avg) : 0;

      return avg;
    });
    const oldAvgCustomerSpend = computed(() => {
      let oldAvg =
        parseInt(
          props.summaryData?.comparison_period_tenant_customers_total_spend
        ) / parseInt(props.summaryData?.tenant_customers_count);

      oldAvg = isFinite(oldAvg) ? Math.abs(oldAvg) : 0;

      return oldAvg;
    });

    const totalCustomerCountDiff = computed(() => {
      return useDiffCalculator(
        props.summaryData?.tenant_customers_count,
        props.summaryData?.comparison_period_tenant_customers_count
      );
    });

    const avgCustomerSpendDiff = computed(() => {
      return useDiffCalculator(
        currentAvgCustomerSpend.value,
        oldAvgCustomerSpend.value
      );
    });

    const totalAmountSpendDiff = computed(() => {
      return useDiffCalculator(
        props.summaryData?.tenant_customers_total_spend,
        props.summaryData?.comparison_period_tenant_customers_total_spend
      );
    });

    const avgSpendDayDiff = computed(() => {
      return useDiffCalculator(
        props.summaryData?.tenant_customers_avg_daily_spend,
        props.summaryData?.comparison_period_tenant_customers_avg_daily_spend
      );
    });

    const routeToUsers = () => {
      router.push({
        name: "UsersView",
        params: {
          filters: JSON.stringify({
            multi_currency: [
              { key: currency.value.trim(), value: currency.value.trim() },
            ],
          }),
        },
      });
    };

    const routeToTxs = () => {
      router.push({
        name: "MainTransactions",
        params: {
          filters: JSON.stringify({
            multi_currency: [
              { key: currency.value.trim(), value: currency.value.trim() },
            ],
          }),
        },
      });
    };

    return {
      compactNumber,
      currentAvgCustomerSpend,
      avgCustomerSpendDiff,
      totalAmountSpendDiff,
      avgSpendDayDiff,
      totalCustomerCountDiff,
      routeToUsers,
      routeToTxs,
    };
  },
};
</script>
<style scoped>
.lu-summary-cards-conatiner {
  border-radius: 20px;
  background: var(--white);
  box-shadow: 0px 4px 40px 0px rgba(204, 213, 212, 0.3);
  padding: 1rem;
}
.ov-summ-card {
  width: fit-content;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media screen and (max-width: 890px) {
  .ov-summ-card {
    width: 45%;
  }
}
@media screen and (max-width: 750px) {
  .ov-summ-card {
    width: 100%;
  }
}
</style>
