<template>
  <div class="card-body pb-0 mb-0 d-flex flex-column h-100">
    <div class="chart-header">
      <div>
        <div class="d-flex justify-content-between align-items-center w-100">
          <h4 class="card-title no-select me-auto">
            <!-- {{ metric == "average" ? "Avg. Spend per Customer" : "Total Spend" }} -->
            Users by Nationality
          </h4>

          <button
            v-if="fullscreen"
            id="close-modal-icon"
            class="btn btn-sm"
            @click="toggleCountryModal(false)"
          >
            <i class="bi bi-x-lg"></i>
          </button>

          <InfoPopover
            v-if="!fullscreen"
            class="pb-2"
            theme="sea"
            title="Users by Nationality"
            content="Based on the time period chosen, the number of users by each nationality is displayed."
          />
        </div>

        <div class="card-divider m-0 p-0 py-2 mt-1">
          <span>
            <small>{{ timePeriod }}</small></span
          >
        </div>
      </div>
    </div>

    <div v-if="data.length !== 0" class="d-flex gap-3 pt-3">
      <div>
        <FilterMenu
          label="Country"
          :sort-is-active="
            sortOption == sortOptions.tenant_customers_nationality
          "
          :reverse-sorting="sortOption == 'reverse-0'"
          @click="sortData(sortOptions.tenant_customers_nationality)"
        />
      </div>
      <div>
        <FilterMenu
          label="Number of Users"
          :sort-is-active="sortOption == sortOptions.tenant_customers_count"
          :reverse-sorting="sortOption == 'reverse-1'"
          @click="sortData(sortOptions.tenant_customers_count)"
        />
      </div>
    </div>

    <div
      v-if="data.length !== 0"
      class="pt-3"
      :class="[fullscreen ? 'full-chart-wrapper' : 'h30v']"
    >
      <BarChart
        :style="
          fullscreen
            ? `height: ${
                (chartData.labels ?? []).length * 35 + 350
              }px !important; `
            : ''
        "
        :class="[fullscreen ? '' : 'h30v']"
        :chart-data="chartData"
        :options="options"
      />
    </div>

    <div v-else>
      <CardsEmptyState min-height="40" />
    </div>

    <div
      v-if="!fullscreen && chartData.labels.length"
      class="card-link cp text-end"
    >
      <div
        v-if="data.length > 7"
        class="cp d-flex justify-content-end align-items-center gap-2 py-2"
        @click="
          toggleCountryModal(true);
          trackViewAll();
        "
      >
        <small class="view-more-btn py-2 cp">View All Countries</small>
        <img
          class="arrow-right"
          src="../assets/images/svg/arrow-point-right.svg"
          alt="arrow"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref } from "vue";
import { BarChart } from "vue-chart-3";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import FilterMenu from "./FilterMenu.vue";
import "chartjs-adapter-moment";
import "chartjs-plugin-style";
import { countries } from "../data/options";
import InfoPopover from "./InfoPopover.vue";
import CardsEmptyState from "./CardsEmptyState.vue";

export default {
  components: { BarChart, FilterMenu, InfoPopover, CardsEmptyState },
  props: {
    metric: {
      default: "average",
    },
    timePeriod: {
      type: String,
      default: "Last 30 days",
    },
    data: {
      type: [Object, Array],
      default: () => [],
    },
    period: {
      default: 30,
    },
    loading: {
      default: false,
    },
    fullscreen: {
      default: false,
    },
  },
  emits: ["toggleCountryModal"],
  setup(props, { emit }) {
    Chart.register(ChartDataLabels);
    Chart.defaults.set("plugins.datalabels", {
      color: "#FE777B00",
    });

    const sortOption = ref(1);

    const sortOptions = {
      tenant_customers_nationality: 0,
      tenant_customers_count: 1,
    };

    const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    const compactNumber = inject("compactNumber");

    const toggleCountryModal = (value) => emit("toggleCountryModal", value);

    const sortData = (option) => {
      sortOption.value =
        sortOption.value == option ? `reverse-${option}` : option;
    };

    const rawChartData = computed(() => {
      let keys = [];
      let values = [];

      //console.log(props.data);

      let entries = [];

      if (props.data.length) {
        let _data = [...props.data];

        if (
          sortOption.value == "reverse-0" ||
          sortOption.value == "reverse-1"
        ) {
          entries = _data.sort((a, b) => {
            if (sortOption.value == "reverse-0") {
              // sort alphabetically from A to Z
              return (
                countries[a.tenant_customers_nationality] ??
                a.tenant_customers_nationality
              ).toLowerCase() <
                (
                  countries[b.tenant_customers_nationality] ??
                  a.tenant_customers_nationality
                ).toLowerCase()
                ? -1
                : 1;
            } else {
              // sort from highest to smallest
              return Math.abs(b.tenant_customers_count) <
                Math.abs(a.tenant_customers_count)
                ? 1
                : -1;
            }
          });
        } else {
          entries = _data.sort((a, b) => {
            if (sortOption.value == 0) {
              // sort alphabetically from A to Z

              return (
                countries[a.tenant_customers_nationality] ??
                a.tenant_customers_nationality
              ).toLowerCase() <
                (
                  countries[b.tenant_customers_nationality] ??
                  b.tenant_customers_nationality
                ).toLowerCase()
                ? 1
                : -1;
            } else {
              // sort from highest to smallest
              return Math.abs(b.tenant_customers_count) <
                Math.abs(a.tenant_customers_count)
                ? -1
                : 1;
            }
          });
        }
      }

      entries.map((_datum) => {
        if (props.fullscreen || keys.length < 7) {
          keys.push(
            countries[_datum.tenant_customers_nationality] ??
              _datum.tenant_customers_nationality
          );
          values.push(_datum.tenant_customers_count);
        }
      });
      return {
        keys: keys,
        values: values,
      };
    });

    const chartData = computed(() => ({
      labels: rawChartData.value.keys,
      datasets: [
        {
          indexAxis: "y",
          // label: "Current",
          data: rawChartData.value.values,
          backgroundColor: rawChartData.value.values.map((_, index, arr) => {
            let len = arr.length;
            let percentage = parseInt(((len + 1 - index) / (len + 1)) * 100);
            // let percentage = 100 - (index * 10);

            if (percentage == 100) {
              return "#469a93";
            }

            return percentage > 9
              ? `#469a93${percentage}`
              : `#469a930${percentage}`;
          }),
          borderWidth: 1,
          borderRadius: 5,
          // barThickness: 34.5,
          // tension: 0.05,
          datalabels: {
            align: "end",
            anchor: "end",
            formatter: (value) => compactNumber(value),
            color: "#000",
          },
        },
      ],
    }));

    const options = ref({
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
          position: "top",
          align: "start",
          reverse: true,
          labels: {
            usePointStyle: true,
            boxWidth: 10,
            boxHeight: 10,
          },
        },
        tooltip: {
          enabled: true,
          usePointStyle: true,
          backgroundColor: "#f8f9fb",
          titleColor: "black",
          bodyColor: "black",
          // padding: 15,
          bodyFont: {
            size: 12,
            lineHeight: 1.9,
          },
          displayColors: true,
          callbacks: {
            title: (ctx) => {
              return;
            },
            label: (context) => {
              return `  ${context.label}    ${context.formattedValue} Users`;
            },
            labelPointStyle: function (context) {
              return {
                pointStyle: "circle",
                rotation: 0,
              };
            },
          },
        },
        ChartDataLabels,
      },
      scales: {
        y: {
          ticks: {
            color: "#200e32",
          },
          min: 0,
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        x: {
          ticks: {
            display: false,
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },

      indexAxis: "y",
    });

    return {
      chartData,
      options,
      numberWithCommas,
      sortData,
      sortOption,
      sortOptions,
      toggleCountryModal,
    };
  },
  methods: {
    trackViewAll() {
      this.$gtag.event("MAY23_click_viewall_nationalities", {
        event_category: "Engagement",
        event_label: "MAY23_click_viewall_nationalities",
        value: 1,
      });
    },
  },
};
</script>

<style scoped>
.chart-header {
  border-bottom: 1px solid #dee2e6;
}
.active-text {
  color: #469b93 !important;
}

.alt-text {
  color: #97aabd !important;
}
.h330 {
  height: 330px;
}
.h30v {
  height: 30vh;
}
.h100v {
  height: 100vh;
}

.overflow-scroll {
  overflow-y: auto !important;
}
.percentage {
  background-color: var(--sea-10);
  color: var(--sea);
  font-size: 0.8rem !important;
  padding: 3px 7px;
  border-radius: 7px;
}
.negative {
  background-color: rgba(223, 41, 53, 0.1);
  color: #df2935ec;
}

.full-chart-wrapper {
  overflow-y: scroll !important;
  /* background: red; */
}
</style>
