<template>
  <div
    class="
      filter-button-pu
      cp
      d-flex
      justify-content-center
      align-items-center
      gap-4
    "
  >
    <button class="p-0 m-0">{{ label }}</button>
    <div class="d-flex align-items-center gap-2">
      <div
        v-if="count > 0"
        class="bms-dp-counter d-flex justify-content-center align-items-center"
      >
        {{ count }}
      </div>
      <img
        src="@/assets/images/svg/components/filters_popup.svg"
        alt="filters pop-up"
        width="20"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    count: {
      type: Number,
      default: 3,
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-button-pu {
  background: white !important;
  border: 1px solid white !important;
  transition: var(--d-ease) !important;
  box-shadow: 0px 4px 40px 0px rgba(204, 213, 212, 0.55);
  border-radius: 10px !important;
  padding: 0.89rem 0.8rem;
  font-size: var(--fs-input) !important;
  font-weight: 400;
  min-height: 42px;

  button {
    background-color: transparent;
    border: none;
  }
}
.bms-dp-counter {
  background-color: var(--sea);
  color: white !important;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  font-size: 0.8rem;
}
</style>
