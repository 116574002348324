<template>
  <div v-if="totalRecords !== 0" class="pb-3">
    <BaseViewInfoText
      v-slot="{ loading: loadingDates, data }"
      :is-custom="true"
    >
      <h6 class="py-1">
        {{ getTableLabel(loadingDates, data) }}
      </h6></BaseViewInfoText
    >
  </div>

  <div class="base-table-outer" :class="{ 'clickable-row': true }">
    <DataTable
      v-model:first="first"
      :paginator="!loading && sortedData?.length !== 0"
      :value="[...sortedData]"
      breakpoint="20px"
      :rows="20"
      :lazy="true"
      :total-records="totalRecords"
      table-class="base-table"
      :users-list-table="true"
      @sort="onSort($event)"
      @page="onPage($event)"
      @row-click="rowClick($event)"
    >
      <template #empty>
        <div class="h-100 w-100">
          <LoadingBox v-if="loading" />
          <BaseEmptyState v-else /></div
      ></template>
      <Column
        field="name"
        header="Name"
        header-class="base-table-header"
        body-class="base-table-cell"
        style="width: 20%"
      >
        <!-- <template #header>
          <BaseTableCustomSortHeader
            header="Name"
            :order="sortObject?.name?.order ?? null"
            @click="triggerSort($event)"
          />
        </template> -->

        <template #body="{ data }">
          <span v-if="!customersDetails[data?.cloud_id]?.loading">{{
            customersDetails[data?.cloud_id]?.CustomerName
              ? customersDetails[data?.cloud_id]?.CustomerName
              : getCustomerName(data)
          }}</span>
          <span v-else class="opacity-50">loading...</span>
        </template>
      </Column>
      <Column
        field="nationality"
        header="Nationality"
        header-class="base-table-header"
        body-class="base-table-cell"
        style="width: 12%"
      >
        <template #body="{ data }">{{
          getCountry(data?.nationality)
        }}</template>
      </Column>

      <Column
        field="cif"
        header="CIF"
        header-class="base-table-header"
        body-class="base-table-cell"
        style="width: 9%"
      >
        <template #body="{ data }">
          <span v-if="!customersDetails[data?.cloud_id]?.loading">{{
            customersDetails[data?.cloud_id]?.CifNumber
              ? customersDetails[data?.cloud_id]?.CifNumber
              : getCustomerName(data)
          }}</span>
          <span v-else class="opacity-50">loading...</span>
        </template>
      </Column>

      <Column
        field="age"
        header="Age"
        header-class="base-table-header"
        body-class="base-table-cell"
        style="width: 8%"
      ></Column>
      <Column
        field="account_category"
        header="Account Category"
        header-class="base-table-header"
        body-class="base-table-cell"
        style="width: 15%"
      >
        <template #body="{ data }">{{
          data?.account_category || "--"
        }}</template></Column
      >
      <Column
        field="segment"
        header="Segment"
        header-class="base-table-header"
        body-class="base-table-cell"
        style="width: 15%"
      >
        <template #body="{ data }">{{ data?.segment || "--" }}</template>
      </Column>

      <Column
        field="account_total_spend"
        sortable
        header-class="base-table-header"
        body-class="base-table-cell"
        style="width: 16%"
      >
        <template #header>
          <BaseTableCustomSortHeader
            header="Total Spending"
            :order="sortObject?.account_total_spend?.order ?? null"
            @click="triggerSort($event)"
          />
        </template>
        <template #body="{ data }">{{
          compactNumber(Math.abs(data?.account_total_spend), 2) +
          " " +
          data?.account_currency
        }}</template>
      </Column>

      <Column
        field="account_transactions_count"
        sortable
        header-class="base-table-header"
        body-class="base-table-cell"
        style="width: 13%"
      >
        <template #header>
          <BaseTableCustomSortHeader
            header="Number of txns"
            :order="sortObject?.account_transactions_count?.order ?? null"
            @click="triggerSort($event)"
          />
        </template>
        <template #body="{ data }">{{
          compactNumber(data?.account_transactions_count)
        }}</template></Column
      >
    </DataTable>
  </div>
</template>
<script>
import BaseViewInfoText from "./helpers/BaseViewInfoText.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import LoadingBox from "./helpers/LoadingBox.vue";
import BaseEmptyState from "./helpers/BaseEmptyState.vue";
import BaseTableCustomSortHeader from "./helpers/BaseTableCustomSortHeader.vue";
import { ref, computed, inject, reactive, watch } from "vue";
import { countries } from "../data/options";
import { useRouter } from "vue-router";
import NBFService from "@/services/NBFService";
import { useStore } from "vuex";
import moment from "moment";

export default {
  components: {
    DataTable,
    Column,
    LoadingBox,
    BaseEmptyState,
    BaseTableCustomSortHeader,
    BaseViewInfoText,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    tableRows: {
      type: Array,
      default: () => [],
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
    totalUsers: {
      type: Number,
      default: 0,
    },
    firstRow: {
      type: Number,
      default: 0,
    },
  },
  emits: ["page-change", "update:firstRow", "sort-change"],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const compactNumber = inject("compactNumber");
    const first = ref(0);
    const currentSortState = ref({
      field: "name",
      order: 1,
      type: "String",
    });
    const sortObject = ref({
      // name: { order: null, type: "String" },
      account_total_spend: { order: null, type: "Number" },
      account_transactions_count: { order: null, type: "Number" },
    });
    const customersDetails = reactive({});
    const triggerSort = (event) => {
      //Function to trigger parent header click event, cuz library by default is not triggering the parent event when child div is clicked
      const header = event.target.closest(".p-sortable-column");
      header.click();
    };
    const sortedData = computed(() => {
      const dataRows = props.tableRows; //assign by array original refrence ("sort will also apply on original array (in-place) algorithm")
      // console.log(dataRows);
      return dataRows;

      // //[1] exit if no mode is provided
      // if (dataRows.length === 0) return [];
      // // console.log("Apply sort for: ", currentSortState.value);

      // // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // //[2] reverse sort "-1" data
      // if (currentSortState.value?.order === -1) return dataRows.reverse();

      // //[3] check mode and sort the data by it
      // return dataRows.sort((a, b) => {
      //   let res = 0;

      //   switch (currentSortState.value?.field) {
      //     case "name":
      //       res = a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
      //       break;
      //     case "account_total_spend":
      //       res =
      //         Math.abs(b?.account_total_spend) -
      //         Math.abs(a?.account_total_spend);
      //       break;
      //     case "account_transactions_count":
      //       res =
      //         Math.abs(b?.account_transactions_count) -
      //         Math.abs(a?.account_transactions_count);
      //       break;
      //     default:
      //       res = 0;
      //       break;
      //   }

      //   return res;
      // });
    });
    const timeFilters = computed(() => store.state.overViewStore.timeFilters);

    const onSort = (sortEvent) => {
      //[0] emit sort
      emit("sort-change", {
        field: sortEvent?.sortField,
        order: sortEvent?.sortOrder,
      });

      //[1] Reset all previous sorting states
      for (let sortOption in sortObject.value) {
        sortObject.value[sortOption]["order"] = null;
      }

      //[2] set new selected sort state
      sortObject.value[sortEvent?.sortField]["order"] = sortEvent?.sortOrder;

      //[3] sort action
      currentSortState.value = {
        field: sortEvent?.sortField,
        order: sortEvent?.sortOrder,
        type: sortObject.value[sortEvent?.sortField]["type"],
      };
    };
    const onPage = (page) => {
      emit("page-change", page);
    };
    const getCountry = (countryKey) => {
      return countries[countryKey] ?? countryKey;
    };
    const rowClick = (row) => {
      if (!customersDetails[row?.data?.cloud_id]?.loading) {
        router.push({
          name: "UserDetails",
          params: { userId: row?.data?.id, cloudId: row?.data?.cloud_id },
          // query: {
          //   user_name: customersDetails[row?.data?.cloud_id]?.CustomerName,
          // },
        });
      }
    };
    const getCustomerDetails = (cloudId) => {
      customersDetails[cloudId] = {
        loading: true,
      };
      NBFService.getCustomerDetails(cloudId)
        .then((res) => {
          // console.log("Success Request: ", res);
          customersDetails[cloudId] = {
            ...(res?.data ? res?.data : res),
            loading: false,
          };
        })
        .catch((error) => {
          if (error !== "canceled") {
            customersDetails[cloudId] = {
              CustomerName: `User Id: ${cloudId}`,
              CifNumber: "--",
              loading: false,
            };
            console.error("error: ", error);
          }
        });
    };
    const getCustomerName = (data) => {
      // console.log("Get Customer Details with: ", data?.cloud_id);
      //[1] Exit if empty
      if (!data?.cloud_id) return "--";

      // //[2] Fetch the Customer Name from Bank CustomerDetails Endpoint
      getCustomerDetails(data?.cloud_id);
    };

    const formatDate = (date) => {
      return moment(date).format("D MMM, YYYY");
    };

    const getTableLabel = (loading, data) => {
      if (loading) return "Loading...";

      if (
        !data?.min_tenant_transaction_date &&
        !data?.max_tenant_transaction_date
      )
        return "No data results for the selected time range";

      return `Showing ${compactNumber(
        props.totalRecords ?? 0,
        2
      )} Accounts for ${compactNumber(
        props.totalUsers ?? 0,
        2
      )} Users from ${formatDate(
        data?.min_tenant_transaction_date
      )} to ${formatDate(data?.max_tenant_transaction_date)}`;
    };

    watch(first, (newVal) => {
      emit("update:firstRow", newVal);
    });

    watch(
      () => props.firstRow,
      (newVal) => {
        first.value = newVal;
      }
    );

    return {
      getCountry,
      rowClick,
      compactNumber,
      sortObject,
      sortedData,
      currentSortState,
      onSort,
      onPage,
      triggerSort,
      getCustomerName,
      customersDetails,
      first,
      timeFilters,
      formatDate,
      getTableLabel,
    };
  },
};
</script>
<style lang="scss">
.base-table-outer {
  border-radius: 20px;
  background: white;
  box-shadow: 0px 4px 40px 0px rgba(204, 213, 212, 0.3);
  min-width: 950px;
  overflow: auto;
}
.base-table-outer .base-table {
  border: none;
  width: 100%;
  min-width: 950px;
  overflow: auto;
}
.p-datatable .p-datatable-thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  color: #809795 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  background: #f3f5f5 !important;
}
.p-datatable .p-datatable-thead > tr > th:first-child {
  border-radius: 20px 0 0 0;
}
.p-datatable .p-datatable-thead > tr > th:last-child {
  border-radius: 0 20px 0 0;
}

.p-datatable .p-datatable-tbody .base-table-cell {
  text-align: left;
  border: 1px solid #e6eaea;
  border-width: 0 0 1px 0;
  color: var(--green, #002e2a);
  font-family: "Poppins", sans-serif !important;
  font-size: 0.88rem;
  font-weight: 400;
  padding: 1.15rem 1rem;
}
.p-datatable-emptymessage {
  background-color: transparent !important;
}
.p-datatable-emptymessage > td {
  border: none !important;
}
.clickable-row .p-datatable .p-datatable-tbody > tr:hover {
  background-color: #aac9c630;
  cursor: pointer;
}
.p-datatable .p-datatable-tbody > tr:hover {
  background-color: #aac9c630;
}
</style>
