import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./style.css";
import "chart.js/auto";
import "primeicons/primeicons.css";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";

import Popper from "vue3-popper";
import DropZone from "dropzone-vue";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";

// optionally import default styles
import "dropzone-vue/dist/dropzone-vue.common.css";
import { SetupCalendar } from "v-calendar";
import VueTippy from "vue-tippy";
import VueGtag from "vue-gtag";
import authStore from "/src/store/authStore";

const app = createApp(App);

//golbal properties
app.config.globalProperties.$msalInstance = authStore.msalInstance;

app
  .use(store)
  .use(router)
  .use(PrimeVue)
  .use(SetupCalendar, {})
  .use(autoAnimatePlugin) // v-auto-animate
  .use(
    VueTippy,
    // optional
    {
      directive: "tippy", // => v-tippy
      component: "tippy", // => <tippy/>
      componentSingleton: "tippy-singleton", // => <tippy-singleton/>,
      defaultProps: {
        theme: "light",
        placement: "auto-end",
        allowHTML: true,
        arrow: false,
      }, // => Global default options * see all props
    }
  )
  .use(VueGtag, {
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS },
  })
  .use(Popper)
  .use(DropZone);

app.mount("#app");
