function useDiffCalculator(newValArg, oldValArg) {
  let diffValue = 0;
  let diffSign = true;

  let newVal = parseFloat(newValArg);
  let oldVal = parseFloat(oldValArg);

  if (!isNaN(newVal) && !isNaN(oldVal)) {
    //calculate the diff %
    diffValue = (Math.abs(newVal) - Math.abs(oldVal)) / Math.abs(oldVal);
    diffValue = isFinite(diffValue) ? diffValue * 100 : 0;
    diffValue = Math.round(diffValue);

    //check if the diff is postive or negative
    diffSign = Math.sign(diffValue) < 0 ? false : true;
  }

  return { diffValue, diffSign };
}
function useDivideCalculator(numerator = 0, denominator = 1) {
  let result = 0;

  if (!isNaN(numerator) && !isNaN(denominator)) {
    //calculate the save divide
    result = numerator / denominator;
    result = isFinite(result) ? Math.abs(result) : 0;
  }

  return result;
}
function useMockFetch(data, delay = 500) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(data), delay);
  });
}
function colorMapper(List, color = "#469A93") {
  return List.map((_, index, arr) => {
    const len = arr.length;
    const percentage = parseInt(((len - index) / len) * 100);

    if (percentage === 100) {
      return color;
    }
    return percentage > 9 ? `${color}${percentage}` : `${color}0${percentage}`;
  });
}
function ageGroupsMapper(ageLabel) {
  let range = {};

  //[1] specified range
  if (ageLabel.includes("-")) {
    const splitedRange = ageLabel.split("-");
    range.min = parseInt(splitedRange[0]);
    range.max = parseInt(splitedRange[1]);
  }

  //[2] only min
  if (ageLabel.includes("over")) {
    let min = ageLabel.replace("over", "");
    min = parseInt(min);
    range.min = min;
  }

  //[3] only max
  if (ageLabel.includes("below")) {
    let max = ageLabel.replace("below", "");
    max = parseInt(max);
    range.max = max;
  }

  return range;
}

export {
  useDiffCalculator,
  useMockFetch,
  colorMapper,
  ageGroupsMapper,
  useDivideCalculator,
};
