<template>
  <div class="px-4 mt-4">
    <ActionButton
      type="button"
      label="View Full Documentation"
      class="btn-primary filter-btn px-4 py-3"
      @click="openDocs"
    />
  </div>
</template>

<script>
import ActionButton from "../components/helpers/ActionButton.vue";
export default {
  components: { ActionButton },
  methods: {
    openDocs() {
      const route = this.$route;
      if (route.name === "Documentation") {
        window.open("https://www.lunedata.io/documentation/main", "_blank");
      }
    },
  },
};
</script>

<style scoped></style>
