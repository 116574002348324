<template>
  <div class="d-flex align-items-center justify-content-between px-1 py-2 mt-1">
    <div class="flex-grow-1">
      <h5 class="fw-500">{{ title }}</h5>
    </div>
    <div class="d-flex align-items-center justify-content-between">
      <BaseSmallAmountTitle
        :show-currency="true"
        :currency="
          componentName === 'currencies' ? title : $store.state.currency
        "
        :show-trend-sign="true"
        :amount="compactNumber(Math.round(Math.abs(amount)), 2)"
        :is-trending-positively="amountDiffSign"
        :is-clickable="true"
        @click="$emit('amount-clicked')"
      />
      <img
        src="@/assets/images/svg/components/v-line-md.svg"
        alt="vertical line"
        height="27"
        class="mx-3"
      />
      <BaseSmallAmountTitle
        :show-currency="false"
        :show-trend-sign="true"
        :amount="compactNumber(Math.round(Math.abs(users)))"
        custom-icon-size="15px"
        :is-trending-positively="usersDiffSign"
        :custom-icon="require('@/assets/images/svg/components/users-sm.svg')"
        @click="$emit('users-clicked')"
      />
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import BaseSmallAmountTitle from "./helpers/BaseSmallAmountTitle.vue";
export default {
  components: { BaseSmallAmountTitle },
  props: {
    title: {
      type: String,
      default: "",
    },
    amount: {
      type: Number,
      default: 0,
    },
    users: {
      type: Number,
      default: 0,
    },
    amountDiffSign: {
      type: Boolean,
      default: true,
    },
    usersDiffSign: {
      type: Boolean,
      default: true,
    },
    componentName: {
      type: String,
      default: "",
    },
  },
  emits: ["amount-clicked", "users-clicked"],
  setup() {
    const compactNumber = inject("compactNumber");

    return { compactNumber };
  },
};
</script>

<style lang="scss" scoped>
h5 {
  color: var(--green);
  font-size: 1.13rem;
}
</style>
