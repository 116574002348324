<template>
  <div>
    <div v-show="Object.keys(userObject).length !== 0">
      <div class="d-flex justify-content-center align-items-center">
        <div class="profile-img-container pe-2">
          <ProfileImageUpload
            size="small"
            :image-u-r-l="userObject?.profile_image ?? null"
            :name="userObject?.name"
          />
        </div>

        <p class="m-0 profile-label">
          {{ userObject?.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileImageUpload from "./ProfileImageUpload.vue";

export default {
  components: { ProfileImageUpload },
  computed: {
    userObject() {
      const userCached = JSON.parse(localStorage.getItem("user"));
      const userVuex = this.$store.state.user;

      return (Object.keys(userVuex).length !== 0 ? userVuex : userCached) ?? {};
    },
  },
};
</script>

<style scoped>
.profile-img-container img {
  width: 25px;
  height: 25px;
  background: rgb(19, 13, 13);
  border-radius: 50%;
}
.profile-label {
  font-size: 0.85rem;
  color: var(--green);
  font-weight: 400;
}
.pi.pi-angle-down {
  font-size: 1rem !important;
  opacity: 0.85;
}
.dropdown-menu {
  min-width: 110%;
}
</style>
