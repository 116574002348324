<template>
  <div v-if="Object.keys(data).length !== 0" class="pt-2">
    <BaseGroupedBarChart
      class="w-100 h-100"
      :primary-data="chartData?.current.slice(0, 10)"
      :secondary-data="chartData?.relative.slice(0, 10)"
      :options="chartOptions"
    />

    <!-- <div class="w-100 d-flex justify-content-end align-items-start pt-2">
      <BaseSmallButton
        label="All Categories"
        :end-icon="require('@/assets/images/svg/components/right-arrow.svg')"
        @click="null"
      />
    </div> -->
  </div>
  <BaseEmptyState v-else min-height="45vh" />
</template>
<script>
import { computed, inject, ref } from "vue";
import { useStore } from "vuex";
import BaseGroupedBarChart from "./charts/BaseGroupedBarChart.vue";
// import BaseSmallButton from "./helpers/BaseSmallButton";
import BaseEmptyState from "./helpers/BaseEmptyState";
import { userCategoriesSpendChartTooltip } from "../composables/ChartTooltips";
import { useRouter, useRoute } from "vue-router";
import { categories } from "@/data/options";

export default {
  components: { BaseGroupedBarChart, BaseEmptyState },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    const metric = computed(() => store.state.overViewStore.metricFilters);
    const compactNumber = inject("compactNumber");
    const router = useRouter();
    const route = useRoute();

    const selectedUserAcc = computed(
      () => store.state.usersViewStore?.selectedAccount
    );
    const chartData = computed(() => {
      let data = { current: [], relative: [] };

      //Check if empty data, => exit
      const pointsCount = Object.keys(props.data ?? []).length ?? 0;
      if (pointsCount === 0 || !props.data) return data;

      const currAmountKey =
        metric.value?.key === "average" ? "avg_spend_per_tx" : "total_spend";

      const relAmountKey =
        metric.value?.key === "average"
          ? "rel_avg_spend_per_tx"
          : "rel_total_spend";

      //[1] Sort the data by "currAmountKey" based on current_labeled data in Descending order
      const sortedData = Object.entries({ ...props.data });
      sortedData.sort((a, b) => {
        return b[1][currAmountKey] - a[1][currAmountKey];
      });

      // console.log("Sort: ", sortedData);

      //[2] Format the data
      for (let cat of sortedData) {
        // == [1] set current data points ==
        data.current.push({
          x: cat[1]?.name,
          y: Math.round(Math.abs(cat[1][currAmountKey])),
        });

        // == [2] set relative data points ==
        data.relative.push({
          x: cat[1]?.name,
          y: Math.round(Math.abs(cat[1][relAmountKey])),
        });
      }

      return data;
    });

    const chartOptions = ref({
      onClick: (event, elements, chart) => {
        if (elements[0]) {
          const i = elements[0]?.index;
          const selectedBar = chart.data.datasets[0].data[i];

          //[1] query params
          const userId = route.params?.userId;
          const cloudId = route.params?.cloudId;
          const accountId = selectedUserAcc.value?.account_id;

          //[2] route params
          let catId = "1";
          const catList = Object.values(categories);
          for (let cat of catList) {
            if (cat.label === selectedBar?.x) {
              catId = cat?.id;
            }
          }
          const userName = route.query?.user_name;

          //[3] redirect to cat page
          router.push({
            name: "UserCategoryDetails",
            params: {
              userId: userId,
              cloudId: cloudId,
              accountId: accountId,
              catId: catId,
            },
            query: {
              user_name: userName,
            },
          });
        }
      },
      categoryPercentage: 0.5,
      interaction: {
        mode: "index",
        intersect: false,
      },
      plugins: {
        legend: {
          display: false,
          position: "top",
          align: "start",
          reverse: true,
          labels: {
            usePointStyle: true,
            boxWidth: 10,
            boxHeight: 10,
          },
        },
        tooltip: {
          enabled: false,
          external: userCategoriesSpendChartTooltip(
            {
              categories: { ...props.data },
            },
            (dataPoint) => {
              console.log("View All is Clicked: ", dataPoint);
            }
          ),
        },
        datalabels: {
          color: "white",
          display: function (context) {
            const dataSetLabel = context?.dataset?.label;
            return dataSetLabel === "Current";
          },
          font: {
            size: 9,
            weight: 500,
          },
          formatter: (value, ctx) => {
            const dataSetLabel = ctx?.dataset?.label;

            const totalSum =
              dataSetLabel === "Current"
                ? dataSum.value?.current
                : dataSum.value?.relative;

            let percentage = 0;
            percentage = Math.round((value?.y / totalSum) * 100);
            percentage = isFinite(percentage) ? percentage : 0;

            return percentage + "%";
          },
        },
      },
      scales: {
        y: {
          grid: {
            display: false,
            drawBorder: false,
          },
          min: 0,
          ticks: {
            padding: 10,
            font: {
              size: 12,
            },
            color: "#66827F",
            // eslint-disable-next-line no-unused-vars
            callback: function (value, index, values) {
              //return value + currency.value;
              return compactNumber(value);
            },
          },
        },
        x: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            padding: 10,
            font: {
              size: 10,
            },
            color: "#66827F",
          },
        },
      },
    });

    const dataSum = computed(() => {
      const data = { ...chartData.value };

      const currDataSum = data?.current.reduce((prev, curr) => {
        return prev + curr?.y ?? 0;
      }, 0);
      const relDataSum = data?.relative.reduce((prev, curr) => {
        return prev + curr?.y ?? 0;
      }, 0);

      return { current: currDataSum, relative: relDataSum };
    });

    return {
      chartData,
      chartOptions,
      dataSum,
    };
  },
};
</script>
<style></style>
