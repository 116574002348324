<template>
  <div
    class="
      empty-card-pp
      h-100
      w-100
      d-flex
      gap-3
      flex-column
      justify-content-center
      align-items-center
    "
    :style="`min-height: ${minHeight}`"
  >
    <img src="@/assets/images/svg/new_icons/empty_icon.svg" alt="empty icons" />
    <h6>No Data</h6>
  </div>
</template>
<script>
export default {
  props: {
    minHeight: {
      type: String,
      default: "40vh",
    },
    iconSize: {
      type: String,
      default: "115px",
    },
  },
};
</script>
<style scoped>
.empty-card-pp img {
  width: v-bind(iconSize);
}
.empty-card-pp h6 {
  color: var(--green);
  font-size: 0.9rem !important;
}
</style>
