<template>
  <div class="d-flex gap-2 flex-row align-items-center">
    <div class="d-flex align-items-center">
      <h5
        class="p-0 m-0"
        :style="`color: ${color}`"
        :class="{ clickable: isClickable }"
      >
        {{ amount }}
        <span v-if="showCurrency && $store.state.currency">{{
          " " + (currency ?? $store.state.currency)
        }}</span>
      </h5>
      <img
        v-if="customIcon"
        class="custom-icon mx-1"
        :src="customIcon"
        alt="custom icon"
      />
    </div>
    <div v-if="showTrendSign">
      <img
        v-if="!isTrendingPositively"
        class="trending-icon"
        src="@/assets/images/svg/components/Trending-Down.svg"
        alt="trneding icon"
      />
      <img
        v-else
        class="trending-icon"
        src="@/assets/images/svg/components/Trending-UP.svg"
        alt="trneding icon"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    amount: {
      type: [Number, String],
      default: 0,
    },
    showCurrency: {
      type: Boolean,
      default: false,
    },
    showTrendSign: {
      type: Boolean,
      default: false,
    },
    isTrendingPositively: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "#469B93",
    },
    currency: {
      type: String,
      default: null,
    },
    customIcon: {
      type: String,
      default: null,
    },
    fontSize: {
      type: String,
      default: "1.13rem",
    },
    trendingIconSize: {
      type: String,
      default: "22px",
    },
    customIconSize: {
      type: String,
      default: "21px",
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
h5 {
  color: var(--sea);
  font-size: v-bind("fontSize");
  font-weight: 400 !important;
}
.trending-icon {
  width: v-bind("trendingIconSize");
}
.custom-icon {
  width: v-bind("customIconSize");
}
</style>
