<template>
  <div v-if="data.length !== 0" class="grid-container">
    <div v-for="(item, index) in data" :key="index">
      <DetailedSpendTypeCard :data="item" />
    </div>
  </div>
  <div v-else>
    <BaseEmptyState />
  </div>
</template>

<script>
import BaseEmptyState from "./helpers/BaseEmptyState.vue";
import DetailedSpendTypeCard from "./DetailedSpendTypeCard.vue";
export default {
  components: { DetailedSpendTypeCard, BaseEmptyState },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.25rem;
}

@media only screen and (max-width: 950px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 1.25rem;
  }
}
</style>
