<template>
  <div class="d-flex align-items-center justify-content-start flex-wrap gap-2">
    <div
      v-for="item in items"
      :key="item?.id"
      class="
        cp
        pu-filter-selected-item-box
        d-flex
        justify-content-between
        align-items-center
        gap-3
      "
      @click.stop="$emit('remove-item', item)"
    >
      <p class="p-0 m-0">{{ item?.name }}</p>
      <img
        src="@/assets/images/svg/components/cross-x.svg"
        alt="remove icon"
        width="8"
      />
    </div>

    <div
      v-if="(selectedObjects ?? 0).length > 5 && !showAll"
      class="
        cp
        pu-filter-selected-item-box
        d-flex
        justify-content-between
        align-items-center
        gap-3
      "
      @click.stop="showAllToggle"
    >
      <p class="p-0 m-0">+{{ (selectedObjects ?? 0).length - 5 }}</p>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";

export default {
  props: {
    selectedObjects: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["remove-item"],
  setup(props) {
    const showAll = ref(false);

    const showAllToggle = () => {
      showAll.value = !showAll.value;
    };

    const items = computed(() => {
      if (showAll.value) return props.selectedObjects;
      else return props.selectedObjects.slice(0, 5);
    });

    return { showAll, showAllToggle, items };
  },
};
</script>

<style lang="scss" scoped>
.pu-filter-selected-item-box {
  color: var(--sea);
  background-color: #edf5f4;
  border-radius: 6px;
  padding: 0.38rem 0.6rem;
  font-weight: 400;
  font-size: 0.9rem;
}
</style>
