<template>
  <button
    :disabled="loading || preparingPDF"
    class="btn btn-nav px-4 d-flex align-items-center gap-2"
    :class="{ 'nav-btn-loading': loading || preparingPDF }"
    @click="exportPDF"
  >
    <img
      v-if="loading || preparingPDF"
      src="../assets/images/svg/circle-rolling.svg"
      alt="circle-loading"
      width="16"
    />
    <i v-else class="pi pi-file-pdf"></i>
    Generate PDF
  </button>
</template>

<script>
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { countries, categories } from "../data/options.js";
import moment from "moment";

export default {
  inject: ["capitalizeFirstLetter", "numberWithCommas", "notify"],
  props: {
    metric: {
      type: String,
      default: "average",
    },
    timePeriod: {
      type: String,
      default: "Last 30 days",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      categories: categories,
      countries: countries,
      preparingPDF: false,
    };
  },
  computed: {
    currentCurrency() {
      return this.$store.state.currency || "AED";
    },
    currentDate() {
      return moment(new Date()).format("D MMM, YYYY  :  h:mm A");
    },
    filtersString() {
      let appliedFilters = { ...this.filters };

      delete appliedFilters?.tenant_transaction_from_date;
      delete appliedFilters?.tenant_transaction_to_date;

      let filtersString = "";
      for (const filter in appliedFilters) {
        if (filter === "tenant_customer_nationalities") {
          //map keys to values
          let nationalitiesList = appliedFilters[filter].map(
            (nationality) => ` ${countries[nationality]} `
          );
          filtersString += `- Nationalities: ${nationalitiesList} \n`;
          continue;
        }

        if (filter === "tenant_customers_incomes") {
          let minRange =
            "min" in appliedFilters[filter][0]
              ? `min: ${this.numberWithCommas(appliedFilters[filter][0]?.min)}`
              : "";
          let maxRange =
            "max" in appliedFilters[filter][0]
              ? `max: ${this.numberWithCommas(appliedFilters[filter][0]?.max)}`
              : "";
          let range =
            minRange && maxRange
              ? minRange + " to " + maxRange
              : minRange || maxRange;
          filtersString += `- Income: ${range} \n`;
          continue;
        }

        if (filter === "tenant_customers_ages") {
          let minRange =
            "min" in appliedFilters[filter][0]
              ? `min: ${this.numberWithCommas(appliedFilters[filter][0]?.min)}`
              : "";
          let maxRange =
            "max" in appliedFilters[filter][0]
              ? `max: ${this.numberWithCommas(appliedFilters[filter][0]?.max)}`
              : "";
          let range =
            minRange && maxRange
              ? minRange + " to " + maxRange
              : minRange || maxRange;
          filtersString += `- Age: ${range} \n`;
          continue;
        }

        if (filter === "tenant_transaction_currency_codes") {
          filtersString += `- Currency: ${appliedFilters[filter]}\n`;
          continue;
        }

        if (filter === "tenant_customer_genders") {
          filtersString += `- Gender: ${appliedFilters[filter]}\n`;
          continue;
        }

        if (filter === "tenant_customer_account_segment_ids") {
          const segments = this.$store.state.overViewStore.segments;
          let selectedItems = segments?.filter((item) =>
            appliedFilters[filter].includes(item?.key)
          );
          selectedItems = selectedItems?.map((item) => ` ${item?.value} `);
          filtersString += `- Segment: ${selectedItems}\n`;
          continue;
        }

        if (filter === "tenant_customer_account_category_ids") {
          const categories = this.$store.state.overViewStore.categories;
          let selectedItems = categories?.filter((item) =>
            appliedFilters[filter].includes(item?.key)
          );
          selectedItems = selectedItems?.map((item) => ` ${item?.value} `);
          filtersString += `- Account Category: ${selectedItems}\n`;
          continue;
        }

        let values = appliedFilters[filter]?.map((value) => ` ${value} `);
        filtersString += `- ${filter}: ${values} \n`;
      }

      return filtersString;
    },
  },
  methods: {
    trackExportPDF() {
      this.$gtag.event("MAY23_click_export_pdf", {
        event_category: "Engagement",
        event_label: "MAY23_click_export_pdf",
        value: 1,
      });
    },
    async exportPDF() {
      this.trackExportPDF();

      this.preparingPDF = true;
      let route = this.$route.name;

      switch (route) {
        case "Dashboard":
        case "Overview":
          try {
            await this.generateDashboardReport();
            this.notify("PDF report is generated");
          } catch (err) {
            this.notify("Faild to export data as PDF", { success: false });
            console.error("Faild to export data as PDF: ", err);
          }
          break;

        case "Categories":
          try {
            await this.generateCategoriesReport();
            this.notify("PDF report is generated");
          } catch (err) {
            this.notify("Faild to export data", { success: false });
            console.error("Faild to export data: ", err);
          }
          break;

        case "Brands":
          try {
            await this.generateBrandsReport();
            this.notify("PDF report is generated");
          } catch (err) {
            this.notify("Faild to export data", { success: false });
            console.error("Faild to export data: ", err);
          }
          break;
      }
      this.preparingPDF = false;
    },
    async generateBrandsReport() {
      let pdf = new jsPDF("p", "mm", "a4", true); //pdf page size
      let pageSize = pdf.internal.pageSize;
      let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

      //Title
      pdf.setFontSize(24);
      pdf.setFont(undefined, "bold").text("Lune Brand Report", 10, 15);

      pdf.setFontSize(7);
      pdf.text(
        "________________________________________________________________________________",
        10,
        20
      );

      //currentDate
      pdf.setFontSize(9);
      pdf.setTextColor(100);
      pdf
        .setFont(undefined, "bold")
        .text(`Export Date: ${this.currentDate}`, 146, 7);

      pdf.setFontSize(16);
      pdf.setTextColor(55);
      let textMain = pdf.splitTextToSize(
        `Brands Screen | Selected Brand: ${
          this.$store.state.pdfData?.selectedBrand?.name ?? "Null"
        }`,
        pageWidth - 20
      );
      pdf.text(textMain, 10, 35, {
        lineHeightFactor: 1.5,
      });

      pdf.setFontSize(10);
      pdf.setTextColor(100);
      pdf
        .setFont(undefined, "bold")
        .text(
          `Applied Metric: ${this.capitalizeFirstLetter(this.metric)} Spend`,
          10,
          44
        );

      pdf.setFontSize(10);
      pdf
        .setFont(undefined, "bold")
        .text(`Selected Time period: ${this.timePeriod}`, 10, 51);

      //Applied Filters
      pdf.setFontSize(10);
      pdf
        .setFont(undefined, "bold")
        .text(`Applied Filters: ${this.filtersString ? "" : "None"}`, 10, 58);

      let filtersText = pdf.splitTextToSize(this.filtersString, pageWidth - 20);

      pdf.setFontSize(9);
      pdf.setFont(undefined, "normal").text(filtersText, 10, 65, {
        lineHeightFactor: 2,
      });

      //--- Item Chart ---
      pdf.setFontSize(15);
      pdf.setTextColor(75);
      pdf.setFont(undefined, "bold");
      pdf.text(
        `Spend on ${
          this.$store.state.pdfData?.selectedBrand?.name ?? "Null"
        } Brand`,
        10,
        125
      );

      pdf.setFont(undefined, "normal");
      pdf.setFontSize(11);
      pdf.setTextColor(120);
      let text0 = pdf.splitTextToSize(
        "Based on the time period, filters and brand selected, the total or average spend for this brand is displayed compared to the preceding time interval",
        pageWidth - 20
      );
      pdf.text(text0, 10, 133, {
        lineHeightFactor: 1.5,
      });

      //Element (1)
      const element1 = await html2canvas(
        document.getElementById("Brands-user-spend-chart"),
        {
          scale: 2,
          windowWidth: 1400,
          windowHeight: 750,
          useCORS: true,
        } //higher is bettwer and larger the file.
      );
      let base64image1 = element1.toDataURL("image/png");
      pdf.addImage(base64image1, "PNG", 10, 150, 125, 83);

      pdf.addPage();

      //Users and Tx Info
      pdf.setFontSize(15);
      pdf.setTextColor(75);
      pdf.setFont(undefined, "bold");
      pdf.text("Users and Transactions Info", 10, 15);

      pdf.setFont(undefined, "normal");
      pdf.setFontSize(11);
      pdf.setTextColor(120);
      let text1 = pdf.splitTextToSize(
        "Users: Based on the time period & filters chosen, the number of users making transactions on this brand is displayed.",
        pageWidth - 20
      );
      pdf.text(text1, 10, 23, {
        lineHeightFactor: 1.5,
      });

      let text12 = pdf.splitTextToSize(
        "Transactions: Based on the time period & filters chosen, the total number of transactions on this brand is displayed.",
        pageWidth - 20
      );
      pdf.text(text12, 10, 37, {
        lineHeightFactor: 1.5,
      });

      //Element (2)
      const element2 = await html2canvas(
        document.getElementById("Brands-user-count-card"),
        {
          scale: 2,
          windowWidth: 1400,
          windowHeight: 750,
        }
      );
      let base64image2 = element2.toDataURL("image/png");
      pdf.addImage(base64image2, "PNG", 10, 57, 62.97, 30.6); //x, y, w, h

      //Element (3)
      const element3 = await html2canvas(
        document.getElementById("Brands-tx-count-card"),
        {
          scale: 2,
          windowWidth: 1400,
          windowHeight: 750,
        }
      );
      let base64image3 = element3.toDataURL("image/png");
      pdf.addImage(base64image3, "PNG", 100, 57, 62.97, 30.6); //x, y, w, h

      //Top Brands
      pdf.setFontSize(15);
      pdf.setTextColor(75);
      pdf.setFont(undefined, "bold");
      pdf.text(`Top Transactions`, 10, 130);

      pdf.setFont(undefined, "normal");
      pdf.setFontSize(11);
      pdf.setTextColor(120);
      let text2 = pdf.splitTextToSize(
        "Based on the time period chosen, top transactions in the selected brand are displayed.",
        pageWidth - 20
      );
      pdf.text(text2, 10, 138, {
        lineHeightFactor: 1.5,
      });

      //Element (4)
      const element4 = await html2canvas(
        document.getElementById("Brands-top-transactions"),
        {
          scale: 2,
          windowWidth: 1400,
          windowHeight: 750,
          useCORS: true,
        }
      );
      let base64image4 = element4.toDataURL("image/png");
      pdf.addImage(base64image4, "PNG", 10, 145, 132.29, 148.16); //x, y, w, h

      pdf.save("Lune Report(Brands).pdf");
    },
    async generateCategoriesReport() {
      let pdf = new jsPDF("p", "mm", "a4", true); //pdf page size
      let pageSize = pdf.internal.pageSize;
      let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

      //Title
      pdf.setFontSize(24);
      pdf.setFont(undefined, "bold").text("Lune Category Report", 10, 15);

      pdf.setFontSize(7);
      pdf.text(
        "________________________________________________________________________________",
        10,
        20
      );

      //currentDate
      pdf.setFontSize(9);
      pdf.setTextColor(100);
      pdf
        .setFont(undefined, "bold")
        .text(`Export Date: ${this.currentDate}`, 146, 7);

      pdf.setFontSize(16);
      pdf.setTextColor(55);
      let textMain = pdf.splitTextToSize(
        `Categories Screen | Selected Category: ${
          this.$store.state.pdfData?.selectedCategory?.name ?? "Null"
        }`,
        pageWidth - 20
      );
      pdf.text(textMain, 10, 35, {
        lineHeightFactor: 1.5,
      });

      pdf.setFontSize(10);
      pdf.setTextColor(100);
      pdf
        .setFont(undefined, "bold")
        .text(
          `Applied Metric: ${this.capitalizeFirstLetter(this.metric)} Spend`,
          10,
          44
        );

      pdf.setFontSize(10);
      pdf
        .setFont(undefined, "bold")
        .text(`Selected Time period: ${this.timePeriod}`, 10, 51);

      //Applied Filters
      pdf.setFontSize(10);
      pdf
        .setFont(undefined, "bold")
        .text(`Applied Filters: ${this.filtersString ? "" : "None"}`, 10, 58);

      let filtersText = pdf.splitTextToSize(this.filtersString, pageWidth - 20);

      pdf.setFontSize(9);
      pdf.setFont(undefined, "normal").text(filtersText, 10, 65, {
        lineHeightFactor: 2,
      });

      //--- Item Chart ---
      pdf.setFontSize(15);
      pdf.setTextColor(75);
      pdf.setFont(undefined, "bold");
      pdf.text(
        `Spend Per Customer on ${
          this.$store.state.pdfData?.selectedCategory?.name ?? "Null"
        } Category`,
        10,
        125
      );

      pdf.setFont(undefined, "normal");
      pdf.setFontSize(11);
      pdf.setTextColor(120);
      let text0 = pdf.splitTextToSize(
        "Based on the time period, filters and category selected, the total or average spend per customer for this category is displayed compared to the preceding time interval",
        pageWidth - 20
      );
      pdf.text(text0, 10, 133, {
        lineHeightFactor: 1.5,
      });

      //Element (1)
      const element1 = await html2canvas(
        document.getElementById("Categories-user-spend-chart"),
        {
          scale: 2,
          windowWidth: 1400,
          windowHeight: 750,
        } //higher is bettwer and larger the file.
      );
      let base64image1 = element1.toDataURL("image/png");
      pdf.addImage(base64image1, "PNG", 10, 150, 125, 83);

      pdf.addPage();

      //Users and Tx Info
      pdf.setFontSize(15);
      pdf.setTextColor(75);
      pdf.setFont(undefined, "bold");
      pdf.text("Users and Transactions Info", 10, 15);

      pdf.setFont(undefined, "normal");
      pdf.setFontSize(11);
      pdf.setTextColor(120);
      let text1 = pdf.splitTextToSize(
        "Users: Based on the time period & filters chosen, the number of users making transactions on this category is displayed.",
        pageWidth - 20
      );
      pdf.text(text1, 10, 23, {
        lineHeightFactor: 1.5,
      });

      let text12 = pdf.splitTextToSize(
        "Transactions: Based on the time period & filters chosen , the total number of transactions on this category is displayed.",
        pageWidth - 20
      );
      pdf.text(text12, 10, 37, {
        lineHeightFactor: 1.5,
      });

      //Element (2)
      const element2 = await html2canvas(
        document.getElementById("Categories-user-count-card"),
        {
          scale: 2,
          windowWidth: 1400,
          windowHeight: 750,
        }
      );
      let base64image2 = element2.toDataURL("image/png");
      pdf.addImage(base64image2, "PNG", 10, 57, 62.97, 30.6); //x, y, w, h

      //Element (3)
      const element3 = await html2canvas(
        document.getElementById("Categories-tx-count-card"),
        {
          scale: 2,
          windowWidth: 1400,
          windowHeight: 750,
        }
      );
      let base64image3 = element3.toDataURL("image/png");
      pdf.addImage(base64image3, "PNG", 100, 57, 62.97, 30.6); //x, y, w, h

      //Top Brands
      pdf.setFontSize(15);
      pdf.setTextColor(75);
      pdf.setFont(undefined, "bold");
      pdf.text(`Top brands with highest spending`, 10, 130);

      pdf.setFont(undefined, "normal");
      pdf.setFontSize(11);
      pdf.setTextColor(120);
      let text2 = pdf.splitTextToSize(
        "Based on the time period & filters chosen, top brands in the selected category are displayed.",
        pageWidth - 20
      );
      pdf.text(text2, 10, 138, {
        lineHeightFactor: 1.5,
      });

      //Element (4)
      const element4 = await html2canvas(
        document.getElementById("Categories-top-brands"),
        {
          scale: 2,
          windowWidth: 1400,
          windowHeight: 750,
          useCORS: true,
        }
      );
      let base64image4 = element4.toDataURL("image/png");
      pdf.addImage(base64image4, "PNG", 10, 145, 132.29, 148.16); //x, y, w, h

      pdf.addPage();

      //Top Transactions
      pdf.setFontSize(15);
      pdf.setTextColor(75);
      pdf.setFont(undefined, "bold");
      pdf.text(`Top Transactions`, 10, 20);

      pdf.setFont(undefined, "normal");
      pdf.setFontSize(11);
      pdf.setTextColor(120);
      let text3 = pdf.splitTextToSize(
        "Based on the time period & filters chosen, top transactions in the selected category are displayed.",
        pageWidth - 20
      );
      pdf.text(text3, 10, 28, {
        lineHeightFactor: 1.5,
      });

      //Element (5)
      const element5 = await html2canvas(
        document.getElementById("Categories-top-transactions"),
        {
          scale: 2,
          windowWidth: 1400,
          windowHeight: 750,
        }
      );
      let base64image5 = element5.toDataURL("image/png");
      pdf.addImage(base64image5, "PNG", 10, 40, 132.29, 148.16); //x, y, w, h

      pdf.save("Lune Report(Categories).pdf");
    },
    async generateDashboardReport() {
      let pdf = new jsPDF("p", "mm", "a4", true); //pdf page size
      let pageSize = pdf.internal.pageSize;
      let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

      //Title
      pdf.setFontSize(24);
      pdf.setFont(undefined, "bold").text("Lune Insights Report", 10, 15);

      pdf.setFontSize(7);
      pdf.text(
        "________________________________________________________________________________",
        10,
        20
      );

      //currentDate
      pdf.setFontSize(9);
      pdf.setTextColor(100);
      pdf
        .setFont(undefined, "bold")
        .text(`Export Date: ${this.currentDate}`, 146, 7);

      pdf.setFontSize(18);
      pdf.setTextColor(55);
      pdf.text("Overview Screen", 10, 35);

      pdf.setFontSize(10);
      pdf.setTextColor(100);
      pdf
        .setFont(undefined, "bold")
        .text(
          `Applied Metric: ${this.capitalizeFirstLetter(this.metric)} Spend`,
          10,
          44
        );

      pdf.setFontSize(10);
      pdf
        .setFont(undefined, "bold")
        .text(`Selected Time period: ${this.timePeriod}`, 10, 51);

      //Applied Filters
      pdf.setFontSize(10);
      pdf
        .setFont(undefined, "bold")
        .text(`Applied Filters: ${this.filtersString ? "" : "None"}`, 10, 58);

      let filtersText = pdf.splitTextToSize(this.filtersString, pageWidth - 20);

      pdf.setFontSize(9);
      pdf.setFont(undefined, "normal").text(filtersText, 10, 65, {
        lineHeightFactor: 2,
      });

      //--- OverviewSummaryCards ---
      pdf.setFontSize(15);
      pdf.setTextColor(75);
      pdf.setFont(undefined, "bold");
      pdf.text("Overview", 10, 125);

      const element0 = await html2canvas(
        document.getElementById("OverviewBaseViewInfoText"),
        {
          scale: 2,
          windowWidth: 1400,
          windowHeight: 775,
        }
      );
      let base64image0 = element0.toDataURL("image/png");
      pdf.addImage(base64image0, "PNG", 10, 130, 200, 10); //x, y, w, h

      const element1 = await html2canvas(
        document.getElementById("OverviewSummaryCards"),
        {
          scale: 2,
          windowWidth: 1400,
          windowHeight: 775,
          //backgroundColor: "#00000010",
        }
      );
      let base64image1 = element1.toDataURL("image/png");
      pdf.addImage(base64image1, "PNG", 10, 140, 192, 15); //x, y, w, h

      //Element (2)
      const element2 = await html2canvas(
        document.getElementById("CustomersSpendChart"),
        {
          scale: 2,
          windowWidth: 1400,
          windowHeight: 775,
          //backgroundColor: "#00000010",
        }
      );
      let base64image2 = element2.toDataURL("image/png");
      pdf.addImage(base64image2, "PNG", 10, 165, 192, 84); //x, y, w, h

      pdf.addPage();

      //Element (3)
      const element3 = await html2canvas(
        document.getElementById("TopBrandsTableCard"),
        {
          scale: 2,
          windowWidth: 1400,
          windowHeight: 775,
          //backgroundColor: "#00000010",
          useCORS: true,
        }
      );
      let base64image3 = element3.toDataURL("image/png");
      pdf.addImage(base64image3, "PNG", 10, 15, 86, 63); //x, y, w, h

      //Element (4)
      const element4 = await html2canvas(
        document.getElementById("TopCategoriesTableCard"),
        {
          scale: 2,
          windowWidth: 1400,
          windowHeight: 775,
          //backgroundColor: "#00000010",
        }
      );
      let base64image4 = element4.toDataURL("image/png");
      pdf.addImage(base64image4, "PNG", 116, 15, 86, 63); //x, y, w, h

      //Element (5)
      const element5 = await html2canvas(
        document.getElementById("AgeBarChart"),
        {
          scale: 2,
          windowWidth: 1400,
          windowHeight: 775,
          //backgroundColor: "#00000010",
        }
      );
      let base64image5 = element5.toDataURL("image/png");
      pdf.addImage(base64image5, "PNG", 10, 95, 85, 70); //x, y, w, h

      //Element (6)
      const element6 = await html2canvas(
        document.getElementById("OverviewCashFlow"),
        {
          scale: 2,
          windowWidth: 1400,
          windowHeight: 775,
          //backgroundColor: "#00000010",
        }
      );
      let base64image6 = element6.toDataURL("image/png");
      pdf.addImage(base64image6, "PNG", 116, 95, 85, 70); //x, y, w, h

      //Element (7)
      const element7 = await html2canvas(
        document.getElementById("SpendPerItemCardCurrencies"),
        {
          scale: 2,
          windowWidth: 1400,
          windowHeight: 775,
          //backgroundColor: "#00000010",
        }
      );
      let base64image7 = element7.toDataURL("image/png");
      pdf.addImage(base64image7, "PNG", 10, 190, 86, 80); //x, y, w, h

      //Element (8)
      const element8 = await html2canvas(
        document.getElementById("SpendPerItemCardTypes"),
        {
          scale: 2,
          windowWidth: 1400,
          windowHeight: 775,
          //backgroundColor: "#00000010",
        }
      );
      let base64image8 = element8.toDataURL("image/png");
      pdf.addImage(base64image8, "PNG", 116, 190, 86, 80); //x, y, w, h

      pdf.save("Lune PDF Report (Overview).pdf");
    },
  },
};
</script>

<style scoped>
.nav-btn-loading {
  background-color: var(--sea) !important;
  color: white !important;
}
.btn-primary {
  font-size: 1rem !important;
  font-weight: 400;
}
.btn-nav {
  box-shadow: 0px 4px 40px rgba(155, 157, 157, 0.2) !important;
}
</style>
