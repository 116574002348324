<template>
  <div class="text-input text-start h-100">
    <div
      v-if="String(label).length > 0 && label"
      class="w-100 d-flex justify-content-between align-items-center"
    >
      <span v-if="!loading" class="">
        <label v-if="label?.length" :for="'input_$' + id" class="form-label">{{
          label
        }}</label>
        <label v-if="isOptional" class="input-optional-flag">- Optional</label>
      </span>

      <span v-if="!loading && !isDisabled">
        <label class="form-label">
          <slot name="label"> </slot>
        </label>
      </span>

      <Skeleton v-if="loading" width="35%" height="0.85rem" />
    </div>

    <div class="position-relative">
      <img
        v-if="withReset"
        src="@/assets/images/svg/components/x.svg"
        class="input--inner-icon"
        alt="reset icon"
        @click="inputValue = ''"
      />
      <input
        v-if="!loading"
        :id="'input_$' + id"
        v-model="inputValue"
        :type="type"
        :disabled="isDisabled"
        :placeholder="!isDisabled ? placeholder : '-'"
        class="form-control"
        :class="{
          inputError: !!inputError,
          disableField: type === 'password' && mode === 'block',
          'large-input': size === 'large',
        }"
        @keypress="($event) => (isOnlyNumber ? numbersOnly($event) : null)"
      />

      <Skeleton v-else class="my-2" width="100%" height="1.8rem" />
    </div>
    <span v-if="!isDisabled" class="error-message">{{
      inputError ?? "  "
    }}</span>
    <!-- <span>name: {{ name }}</span> -->
  </div>
</template>

<script>
import { useField } from "vee-validate";
import { toRef } from "vue";
import Skeleton from "primevue/skeleton";

export default {
  components: { Skeleton },
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [Number, String],
      default: "",
    },
    modelValue: {
      type: [Number, String],
      default: "",
    },
    size: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
    withReset: {
      type: Boolean,
      default: false,
    },
    isOnlyNumber: {
      type: Boolean,
      default: false,
    },
    name: {
      type: [String, Number],
      required: true,
      default: "",
    },
  },
  emits: ["value-change", "update:modelValue"],
  setup(props) {
    const name = toRef(props, "name");

    const { value: inputValue, errorMessage: inputError } = useField(
      name,
      undefined,
      {
        initialValue: props.value,
      }
    );

    function numbersOnly(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }

    // eslint-disable-next-line vue/no-dupe-keys
    return { inputValue, inputError, name, numbersOnly };
  },
  watch: {
    value(newVal) {
      //console.log("Emitted Value")
      this.inputValue = newVal ?? "";
    },
    inputValue(newVal) {
      //console.log("Emitted inputValue: ", newVal);
      this.$emit("value-change", newVal);
      this.$emit("update:modelValue", newVal);
    },
  },
};
</script>

<style scoped>
.form-label {
  opacity: 1;
}
.text-input label {
  font-size: var(--fs-input) !important;
  font-weight: 400;
  color: var(--green);
}
.text-input input {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 10px 10px;
  background: white;
  transition: all 0.4s ease;
  border: 1px solid var(--dark-green-20, #ccd5d4);
  line-height: 173%;
  border-radius: 10px !important;
}
.text-input input.inputError {
  border: 1px solid var(--error);
}
.text-input input:disabled {
  font-size: 0.9rem;
  font-weight: 600;
  border: none;
  padding-left: 0px;
  background: transparent;
  color: var(--green);
}
.text-input input::placeholder {
  color: #99abaa;
  font-weight: 400;
}
.text-input input:hover {
  border-color: var(--sea);
}
.text-input .input-optional-flag {
  font-size: 0.68rem;
  opacity: 0.45;
  padding-left: 5px;
}
input.disableField {
  pointer-events: none;
  color: #002e2a50;
}
input.large-input {
  height: 100%;
  font-size: 1rem;
  border: 1px solid white;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.input--inner-icon {
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: 3%;
  margin: auto;
  transform: translateY(-50%);
  background-color: white;
  padding: 7px;
  width: 25px;
}
</style>
