<template>
  <div
    class="
      d-flex
      flex-grow-1 flex-row flex-wrap
      justify-content-between
      widget-area
      overflow-auto
      h-100
    "
  >
    <!-- bg-danger -->
    <div class="p-2 col-xl-4 col-lg-6 col-md-6 col-sm-12">
      <!-- border -->
      <div class="card p-3 position-relative">
        <ExpensePatternCard
          :metric="metric"
          period="daily"
          :loading="loading.daily"
          :data="data.daily"
          @toggleModal="toggleModal"
        />

        <div v-if="loading.daily" class="loader px-auto py-auto">
          <span class="position-absolute top-50 start-50 translate-middle">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
        </div>
      </div>
    </div>

    <div class="p-2 col-xl-4 col-lg-6 col-md-6 col-sm-12">
      <!-- border -->
      <div class="card p-3 position-relative">
        <ExpensePatternCard
          :metric="metric"
          period="weekly"
          :loading="loading.weekly"
          :data="data.weekly"
          @toggleModal="toggleModal"
        />

        <div v-if="loading.weekly" class="loader px-auto py-auto">
          <span class="position-absolute top-50 start-50 translate-middle">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
        </div>
      </div>
    </div>

    <div class="p-2 col-xl-4 col-lg-6 col-md-6 col-sm-12">
      <!-- border -->
      <div class="card p-3 position-relative">
        <ExpensePatternCard
          :metric="metric"
          period="monthly"
          :loading="loading.monthly"
          :data="data.monthly"
          @toggleModal="toggleModal"
        />

        <div v-if="loading.monthly" class="loader px-auto py-auto">
          <span class="position-absolute top-50 start-50 translate-middle">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
        </div>
      </div>
    </div>

    <div v-if="overlayPeriod" class="bg-transcluscent d-flex fixed-top h-100">
      <div
        class="
          mx-auto
          top-50
          start-50
          w-40
          h-75
          rounded
          bg-white
          row
          justify-content-center
          align-self-center
          p-2
        "
      >
        <ExpensePatternCard
          :metric="metric"
          :period="overlayPeriod"
          :data="data[overlayPeriod]"
          :fullscreen="true"
          @toggleModal="toggleModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ExpensePatternCard from "@/components/ExpensePatternCard.vue";
// import RestService from "@/services/RestService.js";
import TrendsAPI from "@/services/api/TrendsAPI";

export default {
  name: "Trends",
  components: {
    ExpensePatternCard,
  },
  props: {
    fetchKey: {
      default: 1,
    },
    timePeriod: {
      type: String,
      default: "Last 30 days",
    },
    metric: {
      default: "average",
    },
    filters: {
      default: {},
    },
    periodFilter: {
      default: 30,
    },
    selectedProfile: {
      default: {},
    },
  },

  data() {
    return {
      data: {
        daily: [],
        weekly: [],
        monthly: [],
      },
      data_periods: ["daily", "weekly", "monthly"],
      loading: {
        all: false,
        daily: false,
        weekly: false,
        monthly: false,
      },

      overlayPeriod: null,
    };
  },

  watch: {
    // periodFilter() {
    //   this.fetchData();
    // },
    fetchKey() {
      this.fetchData();
    },
  },

  mounted() {
    this.$nextTick(this.onScrolled);

    //fetch onMount
    if (
      this.filters?.tenant_transaction_from_date ||
      this.filters?.tenant_transaction_to_date
    ) {
      this.fetchData();
    }
  },
  unmounted() {
    this.$store.dispatch("CANCEL_PENDING_REQUESTS");
  },

  methods: {
    fetchData() {
      this.$store.dispatch("CANCEL_PENDING_REQUESTS");
      let filters = { ...this.filters };

      this.data_periods.forEach((period) => {
        this.loading[period] = true;
        let periodRoute;

        switch (period) {
          case "daily":
            periodRoute = "day";
            break;
          case "weekly":
            periodRoute = "week";
            break;
          case "monthly":
          default:
            periodRoute = "month";
            break;
        }

        TrendsAPI.getBrandsPattern(filters, periodRoute)
          .then((response) => {
            this.data[period] = response.data?.brands ?? [];
            this.loading[period] = false;
          })
          .catch((error) => {
            if (error !== "canceled") {
              this.data[period] = [];
              this.loading[period] = false;
              console.error("error: ", error);
            }
          });
      });
    },
    toggleModal(period) {
      this.overlayPeriod = period;
    },
  },
};
</script>
<style></style>
