<template>
  <Popper arrow>
    <button class="btn popover-btn m-0">
      <img
        v-if="theme == 'sea'"
        src="../assets/images/svg/info-sea.svg"
        alt="info-popover-icon"
      />
      <img
        v-if="theme == 'white'"
        src="../assets/images/svg/info-white.svg"
        alt="info-popover-icon"
      />
    </button>

    <template #content>
      <div class="popover-info">
        <h5 class="mb-3">
          <small
            ><strong>{{ title }}</strong></small
          >
        </h5>
        <div>
          <p class="info-content-p">
            {{ content }}
          </p>
        </div>
      </div>
    </template>
  </Popper>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: "sea",
    },
    title: {
      type: String,
      default: "Popover Title",
    },
    content: {
      type: String,
      default: "Popover Body",
    },
  },
};
</script>

<style>
:root {
  --popper-theme-background-color: #f8f9fb;
  --popper-theme-background-color-hover: #f8f9fb;
  --popper-theme-text-color: #151515 !important;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 1.1rem;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.15);
}

.popover-info {
  max-width: 250px;
}

.popover-btn {
  background: transparent !important;
  padding: 0 !important;
  padding-left: 12px !important;
}
.info-content-p {
  font-size: 0.87rem !important;
}
</style>
