<template>
  <div
    class="
      no-data-state-container
      d-flex
      justify-content-center
      align-items-center
    "
    :style="`min-height: ${minHeight}vh;`"
  >
    <h5>No Data to show.</h5>
  </div>
</template>

<script>
export default {
  props: {
    minHeight: {
      type: [String, Number],
      default: 29,
    },
  },
};
</script>

<style scoped>
.no-data-state-container {
  min-height: 29vh;
}
.no-data-state-container h5 {
  font-size: 1.1rem !important;
  opacity: 0.4;
}
</style>
