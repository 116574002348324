<template>
  <div class="d-flex justify-content-start align-items-end gap-3">
    <div class="w-60 search-input-base">
      <TextInput
        :id="id"
        v-model="searchQry"
        :with-reset="!!searchQry"
        :name="id"
        type="text"
        :placeholder="placeholder ? placeholder : `Search by ${label}`"
        :label="label"
      />
    </div>
    <ActionButton
      v-if="showButton"
      type="button"
      label="Show results"
      class="btn-primary-normal m-0"
      :icon="require('@/assets/images/svg/components/Search.svg')"
      :loading="loading"
      @click="applySearch"
    />
  </div>
</template>
<script>
import TextInput from "@/components/helpers/TextInput.vue";
import ActionButton from "@/components/helpers/ActionButton.vue";
import { ref, watch } from "vue";

export default {
  components: {
    TextInput,
    ActionButton,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
      default: "",
    },
    showButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue", "updated-search"],
  setup(props, { emit }) {
    const searchQry = ref("");

    //if want to use the search button
    const applySearch = () => {
      emit("updated-search", searchQry.value);
    };

    // options if want to use v-model
    watch(searchQry, (qry) => {
      emit("update:modelValue", qry);
    });

    return { searchQry, applySearch };
  },
};
</script>
<style scoped>
.btn-primary-normal {
  padding-top: 0.68rem;
  padding-bottom: 0.68rem;
}
.search-input-base {
  min-width: 250px;
}
</style>
