<template>
  <div class="d-flex justify-content-start align-items-stretch gap-3">
    <div class="col-3">
      <TextInput
        id="search-users-name"
        v-model="searchQry"
        :with-reset="!!searchQry"
        name="search-users-name"
        type="text"
        placeholder="Search by name"
      />
    </div>
    <ActionButton
      type="button"
      label="Show results"
      class="btn-primary-normal m-0"
      :icon="require('@/assets/images/svg/components/Search.svg')"
      @click="applySearch"
    />
    <!-- <ActionButton
      v-if="searchQry"
      type="button"
      label="Clear"
      class="btn-transparent m-0"
      @click="resetSearch"
    /> -->
  </div>
</template>
<script>
import TextInput from "@/components/helpers/TextInput.vue";
import ActionButton from "@/components/helpers/ActionButton.vue";
import { ref } from "vue";

export default {
  components: {
    TextInput,
    ActionButton,
  },
  emits: ["search", "reset"],
  setup(props, { emit }) {
    const searchQry = ref("");

    const applySearch = () => {
      emit("search", searchQry.value);
    };

    return { searchQry, applySearch };
  },
};
</script>
<style></style>
