<template>
  <div
    class="
      d-flex
      flex-wrap
      justify-content-start justify-content-xl-between
      align-items-center
      py-2
      px-3
      gap-5 gap-xl-0
    "
  >
    <div class="info-box d-flex justify-content-start align-items-center gap-2">
      <h6 class="p-0 m-0">Age:</h6>
      <h6 class="m-0">
        {{ data?.age ?? "--" }}
      </h6>
    </div>
    <div class="info-box d-flex justify-content-start align-items-center gap-2">
      <h6 class="p-0 m-0">Nationality:</h6>
      <h6 class="m-0">
        {{ getCountry(data?.nationality) ?? "--" }}
      </h6>
    </div>
    <div class="info-box d-flex justify-content-start align-items-center gap-2">
      <h6 class="p-0 m-0">CIF:</h6>
      <h6 class="m-0">
        <span v-if="!cifNumberLoading">{{
          customersDetails?.CifNumber ?? "--"
        }}</span>
        <span v-else class="opacity-50">loading...</span>
      </h6>
    </div>
    <div class="info-box d-flex justify-content-start align-items-center gap-2">
      <h6 class="p-0 m-0">Category</h6>
      <h6 class="m-0">
        {{ selectedAccount?.account_category ?? "--" }}
      </h6>
    </div>
    <div class="info-box d-flex justify-content-start align-items-center gap-2">
      <h6 class="p-0 m-0">Segment:</h6>
      <h6 class="m-0">{{ selectedAccount?.segment ?? "--" }}</h6>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  inject: ["getCountry"],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    selectedAccount: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    // const cifNumber = ref("");

    const store = useStore();

    const customersDetails = computed(
      () => store.state.usersViewStore.specificCustomersNBFDetails
    );
    const cifNumberLoading = computed(
      () => store.state.usersViewStore.specificCustomersNBFDetailsLoading
    );

    // const getNBFCustomerDetails = (cloudId) => {
    //   if (cloudId === undefined) return;

    //   cifNumberLoading.value = true;
    //   cifNumber.value = "";

    //   NBFService.getCustomerDetails(cloudId)
    //     .then((res) => {
    //       const response = { ...(res?.data ? res?.data : res) };
    //       cifNumber.value = response?.CifNumber ?? "--";
    //       cifNumberLoading.value = false;
    //     })
    //     .catch((error) => {
    //       if (error !== "canceled") {
    //         cifNumber.value = "--";
    //         cifNumberLoading.value = false;
    //         console.error("error: ", error);
    //       }
    //     });
    // };

    return {
      cifNumberLoading,
      // cifNumber,
      customersDetails,
      // getNBFCustomerDetails,
    };
  },
};
</script>
<style scoped lang="scss">
.info-box {
  & h6:first-child {
    font-size: var(--fs-input) !important;
    color: var(--dark-green-50, #809795);
    font-weight: 400 !important;
  }
  & h6 {
    font-size: var(--fs-input) !important;
    color: var(--green);
    font-weight: 400 !important;
  }
}
</style>
