<template>
  <button
    type="submit"
    class="form-btn btn"
    :class="{ 'btn-loading': loading }"
    :disabled="loading"
  >
    <img
      v-if="loading"
      src="../../assets/images/svg/circle-rolling.svg"
      alt="button-loading"
      class="btn-loading-icon me-2"
    />
    <span>
      {{ label }}
    </span>
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
button.form-btn {
  font-size: 1.15rem !important;
  font-weight: 500;
  color: white;
  border-radius: 10px;
  background-color: var(--sea);
  border-color: var(--sea);
  transition: all 0.4s ease;
}
button.form-btn:disabled,
button.form-btn[disabled] {
  background-color: var(--bluey-grey);
  color: white;
  border: none;
}
button.form-btn:hover {
  background-color: var(--green) !important;
  color: white;
}
.btn-loading {
  opacity: 0.4;
}
.btn-loading .btn-loading-icon {
  width: 20px;
  max-width: 20px;
}
</style>
