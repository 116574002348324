<template>
  <div class="box">
    <div class="vertical-center d-flex align-items-center w-100">
      <div class="form-container container-lg">
        <form
          id="signup-form"
          @submit.prevent="
            signUp();
            track();
          "
        >
          <div class="row justify-content-center align-items-center">
            <div class="col-12 mb-2">
              <label for="email" class="form-label">E-mail</label>

              <div class="input-group">
                <input
                  id="signup-email"
                  v-model="email"
                  type="email"
                  class="form-control border-0 rounded-0 ps-0"
                  aria-label="Email"
                  placeholder=""
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  @blur="checkEmailFormat"
                />

                <span v-if="isEmailValid" class="input-group-text">
                  <img
                    src="@/assets/images/svg/ic-actions-check.svg"
                    alt="mail icon"
                    width="16"
                  />
                </span>
              </div>
            </div>

            <div class="mt-2 mb-4 col-12">
              <label for="password" class="form-label">Password</label>
              <div class="input-group">
                <input
                  id="signup-password"
                  v-model="password"
                  type="password"
                  class="form-control border-0"
                  placeholder=""
                />
              </div>
            </div>

            <div class="mt-1 mb-4 col-12 text-start login-foot">
              <div class="d-flex align-items-center justify-content-start">
                <input
                  id="signup-radio"
                  v-model="radioChecked"
                  type="checkbox"
                  class="form-check-input m-0 p-0"
                  required
                />
                <span class="ms-2"
                  >I agree to Lune’s
                  <a href="https://www.lunedata.io/lune-terms" target="_blank"
                    >Terms & Conditions</a
                  >
                  and
                  <a href="https://www.lunedata.io/lune-privacy" target="_blank"
                    >Privacy Policy.</a
                  ></span
                >
              </div>
            </div>

            <div class="col-12 my-3">
              <button
                type="submit"
                :disabled="!(email && password && radioChecked)"
                class="form-btn btn py-3 col-12 fw-bold"
              >
                Sign up
              </button>
            </div>

            <!-- <div class="col-12 my-2 text-center login-foot">
              <span>Or</span>
            </div> -->

            <div class="my-3 d-flex justify-content-center flex-nowrap">
              <!-- <GoogleLogin id="googleBtn" :callback="googleLogin" /> -->
              <!-- <button
                @click.prevent="googleLogin"
                class="
                  form-btn
                  btn
                  google-sign-in
                  py-3
                  col-12
                  fw-bold
                  d-flex
                  justify-content-center
                  align-items-center
                  gap-3
                "
              >
                <img
                  src="@/assets/images/svg/google-icon.svg"
                  alt="google-icon"
                />
                Sign in with Google
              </button> -->
            </div>

            <div class="col-12 mt-2 text-center login-foot">
              <span
                >Already a user?
                <router-link :to="{ name: 'LoginForm' }"
                  ><span class="login-route-link ms-1">
                    Sign In</span
                  ></router-link
                ></span
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import RestService from "@/services/RestService.js";
export default {
  inject: ["notify", "load"],
  data() {
    return {
      email: "",
      password: "",
      radioChecked: false,
      // alert: null,
    };
  },
  methods: {
    track() {
      this.$gtag.event("click_signup_signup-form", {
        event_category: "Onboarding flow",
        event_label: "click_signup_signup-form",
        value: 1,
      });
    },
    async googleLogin(payload) {
      this.load(true);
      let res = await RestService.googleLogin(payload);
      this.load(false);
      this.handleAuthResponse(res);
    },
    async signUp() {
      let credentials = {
        email: this.email,
        password: this.password,
      };

      this.load(true);
      let res = await RestService.signUp(credentials);

      this.load(false);
      this.handleAuthResponse(res);
    },
    handleAuthResponse(res) {
      if (res.success) {
        console.log(res);

        this.$router.replace({
          name: res.verified
            ? res.user.is_info_form_seen
              ? "DashboardSkeleton"
              : "CompanyDetailsForm"
            : "VerifySignUp",
        });

        // this.notify("Welcome");
      } else {
        console.log(res.err);

        this.notify("Failed to sign you up", {
          success: false,
          header: res.err.message,
        });
      }
    },

    checkEmailFormat() {
      let emailInput = document.getElementById("signup-email");
      return emailInput?.checkValidity();
    },
  },
  computed: {
    isEmailValid() {
      return this.checkEmailFormat();
    },
  },
  //hooks
  created() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("verified");
    this.$store.dispatch("logout");
    //RestService.logout();
  },
};
</script>

<style scoped>
.form-container {
  padding: 45px 40px;
}
.input-group {
  border: none;
  border-bottom: 1px solid var(--bluey-grey) !important;
  border-radius: 0px !important;
}

input[type="email"],
input[type="password"],
input[type="text"] {
  border-radius: 0px !important;
  color: #151515;
  background-color: transparent !important;
  padding: 10px 0 !important;
}

input[type="password"]:focus,
input[type="email"]:focus,
input[type="text"]:focus {
  border-bottom: 1px solid var(--sea) !important;
}

.login-route-link {
  color: var(--sea) !important;
  font-weight: 500;
}

.form-check-input {
  border-radius: 0% !important;
  vertical-align: middle !important;
}
</style>
