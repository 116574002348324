<template>
  <router-view />
  <div class="card-body d-flex flex-column h-100">
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="card-title">
        {{ period[0].toUpperCase() + period.substring(1) }} Trends
      </h4>

      <button
        v-if="fullscreen"
        id="close-modal-icon"
        class="btn btn-sm border-0"
        @click="toggleModal()"
      >
        <i class="bi bi-x-lg"></i>
      </button>

      <InfoPopover
        v-if="!fullscreen"
        class="pb-2"
        theme="sea"
        :title="period[0].toUpperCase() + period.substring(1) + ' Trends'"
        :content="
          'A number of customers made transactions on this brand on a ' +
          period +
          ' basis.'
        "
      />
    </div>

    <div class="d-flex flex-row mt-1">
      <span class="border-bottom py-2 border-dark btn-active">
        <small class="text-dark">Brands</small>
      </span>
      <span class="flex-grow-1 border-bottom py-2"> </span>
    </div>

    <div
      class="flex-grow-1"
      :class="[
        fullscreen ? 'scroll-y' : 'h30v',
        values.length < 1 ? 'pt-5 pb-3' : 'pt-3 pb-2',
      ]"
    >
      <table class="w-100">
        <tr
          v-for="(entry, index) in filteredList"
          :key="entry[0]"
          :class="[index == 0 ? '' : 'border-top border-light']"
        >
          <td class="text-start w-100">
            <div
              class="d-flex justify-content-start align-items-center gap-2 pe-1"
            >
              <LogoIcon
                :src="getIcon(entry)"
                :is-brand-icon="true"
                loading="lazy"
              />
              <small class="ms-1">{{ getLabel(entry) }}</small>
            </div>
          </td>

          <td class="text-start text-nowrap">
            <span class="d-inline-block py-2 mx-2 my-1">
              {{
                numberWithCommas(
                  Math.abs(Number(getCountString(entry[1])).toFixed(0))
                )
              }}
              <small
                ><small>{{
                  `Customer${
                    Math.abs(Number(getCountString(entry[1])).toFixed(0)) > 1
                      ? "s"
                      : ""
                  }`
                }}</small></small
              >
            </span>
          </td>
        </tr>
        <tr v-if="values.length === 0">
          <CardsEmptyState min-height="30" />
        </tr>
      </table>
    </div>

    <div
      v-if="!fullscreen && values.length > 5"
      class="py-0 my-0 pt-2 d-flex justify-content-between align-items-center"
    >
      <p class="m-0 text-end card-foot">
        <small>
          +{{ numberWithCommas(values.length - 5) }} other Brand{{
            values.length > 6 ? "s" : ""
          }}
        </small>
      </p>

      <div
        class="
          card-link
          cp
          d-flex
          justify-content-between
          align-items-center
          gap-2
        "
        @click="toggleModal(period)"
      >
        <small class="view-more-btn">View All Brands</small>
        <img
          class="arrow-right"
          src="@/assets/images/svg/arrow-point-right.svg"
          alt="arrow"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CardsEmptyState from "./CardsEmptyState.vue";
import InfoPopover from "./InfoPopover.vue";
import LogoIcon from "./LogoIcon.vue";

export default {
  components: { InfoPopover, CardsEmptyState, LogoIcon },
  props: {
    metric: {
      type: String,
      default: "average",
    },
    data: {
      type: Array,
      default: () => [],
    },
    period: {
      type: String,
      default: "",
    },
    loading: {
      default: false,
    },
    fullscreen: {
      default: false,
    },
  },
  emits: ["toggleModal"],
  data() {
    return {
      default_brand_logo: "@/assets/images/png/default_brand.png",
    };
  },

  computed: {
    currency() {
      return this.$store.state.currency;
    },
    filteredList() {
      return (
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.entries
          .sort(
            (a, b) =>
              Math.abs(this.getCount(b[1])) - Math.abs(this.getCount(a[1]))
          )

          // .map((v) => v[1])
          .slice(0, this.fullscreen ? undefined : 5)
      );
    },
    values() {
      return Object.values(this.data ?? {});
    },
    entries() {
      let _entries = Object.entries(this.data ?? {});
      return _entries;
    },
  },

  methods: {
    toggleModal(arg) {
      this.$emit("toggleModal", arg);
    },
    toggleButton(btn) {
      this.distButtonStates = btn == "Dist";
      this.spendButtonStates = btn != "Dist";
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getDelta(value) {
      value = (Math.round(value * 100) / 100).toFixed(0);

      if (value == 0) {
        return "+" + 0;
      }
      return (value < 0 ? "" : "+") + (value ?? "NA");
    },
    getCount(entry) {
      let value;

      if (this.metric == "average") {
        value = entry?.tenant_customers_count ?? 0;
      } else {
        value = entry?.tenant_customers_count ?? 0;
      }

      return value ?? 0;
    },
    getCountString(entry) {
      return this.getDelta(this.getCount(entry));
    },
    getIcon(entry) {
      return `${process.env.VUE_APP_IMG_ORIGINAL_BASE_URL}${entry[1]?.logo}`;
    },
    getLabel(entry) {
      return entry[1]?.name ?? "--";
    },
  },
};
</script>

<style scoped>
table tr {
  line-height: 2.2rem;
}
.card-divider {
  border-bottom: 1px solid #dee2e6;
  padding: 5px 0;
}
.card-divider span small {
  color: var(--span-grey);
  font-weight: 500;
}

.content {
  height: 35vh !important;
}

p {
  color: var(--black-two);
  font-size: 0.8rem;
}

.list-group-item {
  border: none !important;
}
.bi-category {
  background-color: var(--bluey-grey-10);
  color: var(--sea);
  padding: 4px 7px;
}
.category-icon {
  filter: invert(54%) sepia(64%) saturate(310%) hue-rotate(126deg)
    brightness(88%) contrast(91%);
}
.brand-icon {
  width: 28px !important;
  border-radius: 4px;
}
.percentage {
  background-color: var(--sea-10);
  color: var(--sea);
  font-size: 0.8rem;
  padding: 3px 7px;
  border-radius: 7px;
}

.negative {
  background-color: rgba(223, 41, 53, 0.1);
  color: #df2935ec;
}

.card-foot {
  font-size: 1rem;
  color: var(--bluey-grey);
}

.scroll-y {
  overflow-y: scroll !important;
}
.view-more-btn {
  font-weight: 500 !important;
}
.card-link:hover .view-more-btn {
  color: var(--black-three);
}
.card-link:hover img {
  filter: invert(0%) sepia(4%) saturate(8%) hue-rotate(0deg) brightness(12%)
    contrast(30%);
}
</style>
