<template>
  <button
    v-bind="{ ...$attrs }"
    class="btn d-flex align-items-center gap-2"
    :class="{ 'btn-loading': loading }"
    :disabled="loading"
  >
    <img
      v-if="loading"
      src="@/assets/images/svg/circle-rolling.svg"
      alt="button-loading"
      class="btn-loading-icon"
    />
    <img v-if="!loading && icon" :src="icon" alt="button-loading" width="22" />
    <span class="text-nowrap">{{ label }}</span>
    <!-- <i v-if="icon && !loading" :class="`pi ${icon} button-icon`"></i> -->
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Label",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
button.btn.btn-error:disabled {
  color: #fff;
  background-color: var(--error);
  border-color: var(--error);
}
button.btn.btn-primary:disabled {
  color: #fff;
  background-color: var(--sea);
  border-color: var(--sea);
}
button.btn.btn-route-link {
  color: var(--sea);
  text-decoration: underline;
  font-size: 0.96rem !important;
}
button.btn.btn-route-link:hover {
  color: var(--green);
}
.btn-loading {
  opacity: 0.8;
}
.btn-loading .btn-loading-icon {
  width: 22px;
}
</style>
<style>
button.btn-transparent {
  background-color: transparent;
  border-color: transparent;
}
button.btn-transparent span {
  color: var(--green);
  font-size: var(--fs-input);
  font-weight: 400;
  text-decoration: underline;
  transition: var(--d-ease);
}
button.btn-transparent:hover span {
  color: var(--sea);
}

button.btn-teal span {
  color: var(--sea);
  font-size: var(--fs-input);
  font-weight: 400;
  text-decoration: underline;
  transition: var(--d-ease);
}
button.btn-teal:hover span {
  color: var(--green);
}
</style>
<style lang="scss">
button.btn-primary-normal {
  padding-left: 1.35rem;
  padding-right: 1.35rem;
  background-color: var(--green);
  transition: var(--d-ease);
  border-radius: 10px;

  &:hover {
    background-color: var(--sea);
  }
  span {
    color: #fff;
    text-align: center;
    font-size: var(--fs-input);
    font-weight: 500;
  }
}
button.btn-warn {
  background-color: var(--error) !important;
  transition: var(--d-ease);
  border-radius: 10px;

  span {
    color: #fff;
    text-align: center;
    font-size: var(--fs-input);
    font-weight: 500;
  }
}
</style>
