<template>
  <div
    class="w-100 d-flex flex-column gap-3 pu-items-scroll-wrapper overflow-auto"
  >
    <div v-if="options.length !== 0">
      <h6 class="pp-count-label py-1">
        Showing
        <strong>{{ (options ?? []).length }} out of {{ count }}</strong>
        {{ computedLabel }}
      </h6>
    </div>
    <div
      class="
        d-flex
        gap-3
        justify-content-start
        align-items-center
        flex-wrap
        mb-3
      "
    >
      <div
        v-for="item in options"
        :key="item?.id"
        class="
          bg-white
          pu-filter-item
          cp
          d-flex
          justify-content-start
          align-items-center
          gap-3
        "
        :class="{ selected: isSelected(item) }"
        @click="toggleItem(item)"
      >
        <LogoIcon
          :src="getIcon(item?.logo)"
          brand-icon-size="32px"
          :is-brand-icon="true"
          loading="lazy"
        />
        <h6>{{ item?.name }}</h6>
      </div>
    </div>
  </div>
</template>

<script>
import LogoIcon from "./LogoIcon.vue";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  components: { LogoIcon },
  props: {
    count: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: "",
    },
    removedItem: {
      type: Object,
      default: () => {},
    },
    clearKey: {
      type: Number,
      default: 0,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update-selection", "clear-all"],
  setup(props, { emit }) {
    const selectedItems = computed(() => {
      return store.state.overViewStore.brandsFilterCurrentState ?? [];
    });
    const store = useStore();

    const computedLabel = computed(() => {
      if (props.count <= 1) return props.label;
      return props.label + "s";
    });

    //    this.$store.commit("updateBrandsFilterCurrentState", [...newSelections]);

    const isSelected = (selectedItem) => {
      return selectedItems.value.find((item) => item?.id === selectedItem?.id);
    };
    const toggleItem = (item) => {
      if (isSelected(item)) {
        //remove if selected
        const removedItemList = selectedItems.value.filter((listItem) => {
          return listItem?.id !== item?.id;
        });
        store.commit("updateBrandsFilterCurrentState", removedItemList);
        return;
      }

      //add if new
      store.commit("updateBrandsFilterCurrentState", [
        ...selectedItems.value,
        item,
      ]);
    };
    const getIcon = (url) => {
      return `${process.env.VUE_APP_IMG_ORIGINAL_BASE_URL}${url}`;
    };

    watch(
      () => props.removedItem,
      (removedItem) => {
        if (isSelected(removedItem)) {
          const removedItemList = selectedItems.value.filter((listItem) => {
            return listItem?.id !== removedItem?.id;
          });

          store.commit("updateBrandsFilterCurrentState", removedItemList);
        }
      },
      {
        deep: true,
      }
    );

    watch(
      () => props.clearKey,
      (clearKey) => {
        // selectedItems.value = [];
        store.commit("updateBrandsFilterCurrentState", []);
        emit("clear-all");
      }
    );

    return { computedLabel, selectedItems, isSelected, toggleItem, getIcon };
  },
};
</script>

<style lang="scss" scoped>
.pu-items-scroll-wrapper {
  // height: 550px;
  max-height: 260px;
}
.pu-filter-item {
  &.selected {
    border: 1px solid var(--sea);
  }
  width: 48.9%;
  padding: 0.92rem;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 40px 0px rgba(204, 213, 212, 0.4);

  h6 {
    font-size: 0.93rem;
  }
}
.pp-count-label {
  color: #809795;
  font-weight: 500;

  strong {
    color: var(--green);
    font-weight: 600;
  }
}
</style>
