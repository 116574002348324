<template>
  <div class="d-flex flex-row gap-2 align-items-center">
    <BaseSmallButton
      :label="showFiltersBar ? 'Hide Filters' : 'Show Filters'"
      :icon="require('@/assets/images/svg/components/Filter.svg')"
      @click="toggleFiltersBar"
    />
    <!-- <img
      v-if="$route.meta?.title !== 'Users'"
      src="@/assets/images/svg/components/v-line-sm.svg"
      alt="v-line"
    /> -->
    <!-- <BaseSmallButton
      v-if="$route.meta?.title !== 'Users'"
      label="Edit View"
      :icon="require('@/assets/images/svg/components/Edit.svg')"
    /> -->
  </div>
</template>
<script>
import BaseSmallButton from "./helpers/BaseSmallButton.vue";
export default {
  components: { BaseSmallButton },
  computed: {
    showFiltersBar() {
      return this.$store.state.overViewStore.showFiltersBar;
    },
  },
  methods: {
    toggleFiltersBar() {
      this.$store.commit("updateShowFiltersBar", !this.showFiltersBar);
    },
  },
};
</script>
<style></style>
