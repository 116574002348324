<template>
  <button
    class="
      form-btn
      btn
      azure-login-btn
      py-3
      col-12
      d-flex
      justify-content-center
      align-items-center
      gap-3
    "
    @click.prevent="loginWithAzure"
  >
    <img
      src="../assets/images/svg/azure-icon.svg"
      alt="azure-ad-icon"
      width="20"
    />
    Login with Azure AD
  </button>
</template>

<script>
export default {
  name: "HeaderBar",
  inject: ["load", "notify"],
  methods: {
    loginWithAzure() {
      const loginRequest = {
        //scopes: ["User.ReadWrite"],
      };

      //this.load(true);
      this.$msalInstance.loginRedirect(loginRequest); //add permissions here if needed
      //this.load(false);
      // .then((res) => {
      //   // console.log("Sucess login:", res);
      //   // localStorage.setItem("azureUserAccount", JSON.stringify(res));
      //   // this.$store.commit("updateAzureUserAccount", { ...res });
      //   // this.$emit("login", res);
      // })
      // .catch((error) => {
      //   this.notify("Failed to login using Azure AD", {
      //     success: false,
      //   });
      //   console.error(`error during authentication: ${error}`);
      // })
      // .finally(() => {
      //   console.log("Azure Auth Finished...");
      //   this.load(false);
      // });
    },
  },
};
</script>

<style scoped>
button.azure-login-btn {
  font-size: 0.95rem !important;
  font-weight: 500;
  color: #7b7b7b;
  border-radius: 10px;
  background-color: white;
  border-color: #e2e2e2;
  transition: all 0.4s ease;
}
button.azure-login-btn:hover {
  background-color: #0078d4 !important;
  color: white !important;
}

button.azure-login-btn:disabled,
button.azure-login-btn[disabled] {
  background-color: var(--bluey-grey);
  color: white;
  border: none;
}
</style>
