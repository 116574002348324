<template>
  <div>
    <div class="w-100 text-center pb-5">
      <h5 class="opacity-75">Set your password</h5>
    </div>
    <form id="set-invite-pass" @submit.prevent="submit()">
      <div class="row justify-content-center align-items-center">
        <div class="mb-3 col-12">
          <AuthInput
            id="set_new_password_1"
            v-model="newPassword"
            type="password"
            aria-label="password"
            label="Set your new password"
            name="set_new_password_1"
          />
        </div>

        <div class="mt-3 mb-4 col-12">
          <AuthInput
            id="set_new_password_2"
            v-model="newPasswordConfirm"
            type="password"
            aria-label="password"
            label="Confirm new password"
            name="set_new_password_2"
          />
        </div>

        <div class="col-12 pt-2 mt-4">
          <FormButton
            class="py-3 col-12"
            label="Set password"
            form="set-invite-pass"
            :loading="loading"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import RestService from "@/services/RestService.js";
import AuthInput from "../helpers/AuthInput.vue";
import FormButton from "../helpers/FormButton.vue";
import { object, string, ref as yupRef } from "yup";
import { inject, onMounted, reactive, ref } from "vue";
import { useForm } from "vee-validate";
import { useRoute, useRouter } from "vue-router";
import { event } from "vue-gtag";

export default {
  components: { AuthInput, FormButton },
  setup() {
    const notify = inject("notify");
    const router = useRouter();
    const route = useRoute();

    const form_key = ref(route.query?.form_key);
    const token = ref(route.query?.token);
    const newPassword = ref("");
    const newPasswordConfirm = ref("");
    const payload = reactive({});
    const loading = ref(false);

    onMounted(() => {
      payload[form_key.value] = token;
    });

    function trackSetInvitedMemberPass() {
      event("MAY23_success_set_invited_member_pass", {
        event_category: "Authentication",
        event_label: "MAY23_success_set_invited_member_pass",
        value: 1,
      });
    }

    async function setNewPassword() {
      const _payload = {
        invite_token: payload[form_key.value],
        password: newPassword.value,
        password_confirmation: newPasswordConfirm.value,
      };
      loading.value = true;
      const res = await RestService.setPassword(_payload, true);
      loading.value = false;

      if (res?.success ?? false) {
        trackSetInvitedMemberPass();
        notify("Your password has been Set", {
          success: true,
        });
        router.replace({
          name: "LoginForm",
        });
      } else {
        notify("Failed to set password", {
          success: false,
        });
      }
    }

    const validationSchema = object({
      set_new_password_1: string()
        .min(8, "Password should be 8 characters minimum")
        .required("New password is required")
        .test(
          "isValidPass",
          "Make sure to include ( Upper case, Lower case, & Numbers )",
          (value, context) => {
            const hasUpperCase = /[A-Z]/.test(value);
            const hasLowerCase = /[a-z]/.test(value);
            const hasNumber = /[0-9]/.test(value);
            const hasSymbole = /[!@#%&]/.test(value);
            let validConditions = 0;
            const numberOfMustBeValidConditions = 3;
            const conditions = [
              hasLowerCase,
              hasUpperCase,
              hasNumber,
              hasSymbole,
            ];
            conditions.forEach((condition) =>
              condition ? validConditions++ : null
            );
            if (validConditions >= numberOfMustBeValidConditions) {
              return true;
            }
            return false;
          }
        ),
      set_new_password_2: string()
        .oneOf(
          [yupRef("set_new_password_1")],
          "Please match the new password above"
        )
        .min(8, "Password should be 8 characters minimum")
        .required("Confirm password is required"),
    });

    const { handleSubmit, errors, resetForm } = useForm({
      validationSchema,
    });

    const submit = handleSubmit((values) => {
      setNewPassword();
    });

    return { newPassword, newPasswordConfirm, loading, submit };
  },
};
</script>

<style scoped></style>
