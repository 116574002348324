import SettingsAPI from "@/services/api/SettingsAPI";

export default {
  state() {
    return {
      teams: {},
      teamsLoading: false,

      roles: {},
      rolesLoading: false,
    };
  },
  // getters: {
  //   // selectedUserCurrency(state) {
  //   //   return state.selectedAccount?.account_currency ?? "AED";
  //   // },
  // },
  mutations: {
    updateTeams(state, payload) {
      state.teams = { ...payload };
    },
    updateTeamsLoading(state, payload) {
      state.teamsLoading = payload;
    },
    updateRoles(state, payload) {
      state.roles = { ...payload };
    },
    updateRolesLoading(state, payload) {
      state.rolesLoading = payload;
    },
  },
  actions: {
    getTeamsList({ commit }) {
      const cachedSegments = JSON.parse(localStorage.getItem("teamsList"));
      if (cachedSegments?.length) {
        commit("updateTeams", cachedSegments);
        commit("updateTeamsLoading", false);
      } else {
        commit("updateTeamsLoading", true);
      }

      SettingsAPI.getTeamsList()
        .then((res) => {
          let data = res.data?.teams ?? [];
          // console.log("Teams List: ", data);
          let teamsList = {};

          if (data.length !== 0)
            data.forEach((item) => {
              teamsList[(item?.name ?? "").toLowerCase()] = {
                id: Number(item?.id),
                label: item?.name,
              };
            });

          localStorage.setItem("teamsList", JSON.stringify(teamsList));
          commit("updateTeams", teamsList);
        })
        .catch((error) => {
          console.error("Failed to fetch: ", error);
          commit("updateTeams", []);
        })
        .finally(() => {
          commit("updateTeamsLoading", false);
        });
    },
    getRolesList({ commit }) {
      const cachedSegments = JSON.parse(localStorage.getItem("rolesList"));
      if (cachedSegments?.length) {
        commit("updateRoles", cachedSegments);
        commit("updateRolesLoading", false);
      } else {
        commit("updateRolesLoading", true);
      }

      SettingsAPI.getRolesList()
        .then((res) => {
          let data = res.data?.roles ?? [];
          let rolesList = {};

          if (data.length !== 0)
            data.forEach((item) => {
              rolesList[(item?.name ?? "").toLowerCase()] = {
                id: Number(item?.id),
                label: item?.name,
              };
            });

          localStorage.setItem("rolesList", JSON.stringify(rolesList));
          commit("updateRoles", rolesList);
        })
        .catch((error) => {
          console.error("Failed to fetch: ", error);
          commit("updateRoles", []);
        })
        .finally(() => {
          commit("updateRolesLoading", false);
        });
    },
  },
};
