<template>
  <div class="periods-calender v-hidden d-flex flex-column">
    <DatePicker
      ref="picker"
      v-model="range"
      :columns="$screens({ default: 2, lg: 2 })"
      transition="slide-h"
      color="teal"
      :select-attribute="selectDragAttribute"
      :drag-attribute="selectDragAttribute"
      is-range
      mode="date"
      :masks="masks"
      popover-visibility="focus"
    >
      <template #day-popover="{ format }">
        <div>
          {{ format(dragValue ? dragValue.start : range.start, "MMM D") }}
          -
          {{ format(dragValue ? dragValue.end : range.end, "MMM D") }}
        </div>
      </template>
    </DatePicker>
  </div>
</template>
<script>
import { DatePicker } from "v-calendar";

import moment from "moment";

export default {
  components: { DatePicker },
  props: {
    selectedOption: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["time-changed"],
  data() {
    return {
      range: {
        start: new Date(),
        end: new Date(),
      },
      masks: {
        input: "YYYY-MM-DD",
      },
      dropDownLabel: null,
      periodFilter: 30,
      rangePeriodFilter: {
        from: null,
        to: null,
      },
    };
  },
  computed: {
    periodInputLabel() {
      const cachedTimeFilters = this.$store.state.timeFilters ?? {};
      return cachedTimeFilters?.dropDownLabel ?? this.periods.month.label; //(this.dropDownLabel ?? this.periods.month.label)
    },
    selectDragAttribute() {
      return {
        popover: {
          visibility: "hidden",
          isInteractive: false, // Defaults to true when using slot
        },
        highlight: {
          start: { fillMode: "solid", color: "teal" },
          base: { fillMode: "light", color: "gray" },
          end: { fillMode: "solid", color: "teal" },
        },
      };
    },
    calenderSelectedDates() {
      return {
        from: moment(this.range?.start).format("YYYY-MM-DD"),
        to: moment(this.range?.end).format("YYYY-MM-DD"),
      };
    },
  },
  watch: {
    range() {
      this.toggleCalender(true);
    },
    selectedOption(selectedOption) {
      if (selectedOption?.label !== "Custom") this.updatePeriod(selectedOption);
    },
  },
  mounted() {
    this.toggleCalender();
  },
  methods: {
    toggleCalender(datesUpdated) {
      let calender = document.querySelector(".periods-calender");

      calender.addEventListener("click", (event) => {
        event.stopPropagation(); //stop propogation of events
      });

      window.addEventListener("click", (event) => {
        if (
          !calender.classList.contains("v-hidden") &&
          event.target.id !== "dd-time-filter-custom"
        )
          calender.classList.add("v-hidden");

        if (
          event.target.id === "dd-time-filter-custom" ||
          (event.target.getAttribute("aria-label") === "Custom" &&
            event.target.nodeName == "LI")
        ) {
          //show calender
          let calender = document.querySelector(".periods-calender");
          calender.classList.remove("v-hidden");
        }
      });

      if (datesUpdated) {
        //dates got updated successfully
        //hide the calender
        calender.classList.add("v-hidden");

        //emit new dates
        this.updatePeriod({
          label: `${moment(this.calenderSelectedDates?.from).format(
            "D MMM, YYYY"
          )}  -  ${moment(this.calenderSelectedDates?.to).format(
            "DD, MMM YYYY"
          )}`,
          days: {
            from: this.calenderSelectedDates?.from,
            to: this.calenderSelectedDates?.to,
          },
        });
      }
    },
    updatePeriod(period) {
      const currentLabel = this.$store.state.timeFilters?.dropDownLabel;

      if (
        (period?.label !== this.dropDownLabel ||
          this.dropDownLabel !== currentLabel) &&
        typeof period?.days == "object"
      ) {
        let numOfDays =
          new Date(period?.days?.to).getTime() -
          new Date(period?.days?.from).getTime(); //in ms

        //label and Num of days
        this.dropDownLabel = period?.label;
        this.periodFilter = numOfDays / (1000 * 3600 * 24) + 1;

        //exact dates range
        this.rangePeriodFilter.from = period?.days?.from;
        this.rangePeriodFilter.to = period?.days?.to;

        //Add date range to filters
        this.$emit("time-changed", {
          rangePeriodFilter: this.rangePeriodFilter,
          dropDownLabel: this.dropDownLabel,
          periodFilter: Math.ceil(this.periodFilter),
        });
      }
    },
  },
};
</script>
<style scoped>
.periods-calender {
  position: absolute;
  right: 1.75rem;
  margin-top: 10px;
  z-index: 2;
  visibility: visible !important;
}
.periods-calender div {
  border: none;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 40px 0px rgba(204, 213, 212, 0.5);
  padding: 0.5rem;
}
.periods-calender.v-visiable {
  visibility: visible !important;
}
.periods-calender.v-hidden {
  visibility: hidden !important;
}
</style>
<style>
.vc-container {
  font-family: "Poppins", sans-serif !important;
}
</style>
