<template>
  <div class="verify-email-container d-flex align-items-center w-100 p-3">
    <div class="text-center">
      <div>
        <h2>Verify your email</h2>
        <p>You must verify your email to continue</p>
      </div>

      <div class="mt-4">
        <span class="login-route-link fs-5 cp" @click="resendEmail"
          >Resend verification mail</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import RestService from "@/services/RestService.js";
export default {
  inject: ["notify", "load"],
  props: {
    form_key: {
      type: String,
    },
    token: {
      type: String,
    },
  },
  data() {
    return {
      payload: {},
    };
  },
  created() {
    if (this.form_key != null && this.token != null) {
      this.payload[this.form_key] = this.token;
      this.verifyEmail();
    }
  },
  methods: {
    async resendEmail() {
      this.load(true);
      let res = await RestService.resendVerificationEmail();
      this.load(false);

      if (res != null && res.success) {
        this.notify("Email has been resent", {
          success: true,
        });
      } else {
        console.log(res.err);
        this.notify("Failed to resend email", {
          success: false,
        });
      }
    },

    async verifyEmail() {
      this.load(true);
      let res = await RestService.verifyEmail(this.payload);
      this.load(false);

      if (res != null && res.success) {
        this.$router.replace({
          name: "LoginForm",
        });
        this.notify("Email has been verified", {
          success: true,
        });
      } else {
        console.log(res.err);
        this.notify("Failed to verify email", {
          success: false,
        });
      }
    },
  },
};
</script>

<style scoped>
.verify-email-container p {
  font-size: 1.2rem !important;
  color: var(--bluey-grey);
}
.verify-email-container span {
  color: var(--sea);
  font-weight: 600;
}
.verify-email-container span:hover {
  text-decoration: underline;
}
</style>
