<template>
  <div>
    <!-- Modal -->
    <div
      :id="id"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
      @click="onClose"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div id="report-tx-form" class="modal-content px-1 py-2">
          <div class="modal-header p-4">
            <h3 class="modal-title">Report Transaction</h3>
          </div>
          <div class="modal-body border-0 p-4 text-center">
            <form id="report-tx-pp-form" @submit.prevent="onReport">
              <div
                class="
                  w-100
                  d-flex
                  gap-3
                  flex-column
                  align-items-start
                  justify-content-start
                  text-start
                "
              >
                <div
                  class="
                    w-100
                    d-flex
                    flex-wrap
                    gap-1
                    align-items-start
                    justify-content-between
                  "
                >
                  <div
                    class="input-box"
                    :class="{
                      'w-100': validatedReportForm.type === 'wrong_logo',
                    }"
                  >
                    <DropDownInput
                      id="report_tx_type"
                      append-to="#report-transactions-modal"
                      :options="reportTxOptions"
                      :value="reportDetails.type"
                      name="report_tx_type"
                      placeholder="Type"
                      label="Report Type"
                      @selected-option="setReportType"
                    />
                  </div>
                  <div
                    v-if="validatedReportForm.type === 'wrong_category'"
                    class="input-box"
                  >
                    <DropDownInput
                      id="report_tx_category_suggestion"
                      append-to="#report-transactions-modal"
                      :options="categoriesOptions"
                      name="report_tx_category_suggestion"
                      placeholder="Select Category"
                      :label="suggestionLabel"
                      @selected-option="setReportSuggested"
                    />
                  </div>
                  <div
                    v-if="validatedReportForm.type === 'wrong_brand'"
                    class="input-box"
                  >
                    <TextInput
                      id="report_tx_brand_suggestion"
                      :value="reportDetails.suggestion ?? ''"
                      name="report_tx_brand_suggestion"
                      placeholder="Suggestion"
                      :label="suggestionLabel"
                      @value-change="setReportSuggested"
                    />
                  </div>
                </div>

                <div class="w-100 mt-2">
                  <TextArea
                    id="report_tx_description"
                    :value="reportDetails.description"
                    class="w-100"
                    rows="5"
                    name="report_tx_description"
                    placeholder="Add more details for your feedback..."
                    label="Additional description"
                    @value-change="setReportDescribtion"
                  />
                </div>
              </div>
            </form>
          </div>

          <div
            class="
              modal-footer
              border-0
              p-3
              pt-4
              d-flex
              justify-content-end
              align-items-center
              gap-1
            "
          >
            <button
              type="button"
              class="btn btn-transparent py-2"
              data-bs-dismiss="modal"
              @click="onCancel"
            >
              Cancel
            </button>
            <ActionButton
              type="submit"
              form="report-tx-pp-form"
              label="Submit"
              class="btn btn-primary"
              :loading="actionLoading"
              :disabled="!validatedReportForm?.type"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import { computed, inject, onMounted, reactive, ref, watch } from "vue";
import { object, string } from "yup";
import { useForm } from "vee-validate";
import { reportTxOptions as txOptions, categories } from "@/data/options.js";
import ActionButton from "./helpers/ActionButton.vue";
import DropDownInput from "./helpers/DropDownInput.vue";
import TextInput from "./helpers/TextInput.vue";
import TextArea from "./helpers/TextArea.vue";
import OverviewAPI from "@/services/api/OverviewAPI";
import store from "@/store";

export default {
  components: { ActionButton, DropDownInput, TextInput, TextArea },
  props: {
    data: {
      type: [Array, Object, String, Number],
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
  },
  emits: ["updateTable", "closeModal"],
  setup(props, ctx) {
    const notify = inject("notify");

    const reportTxOptions = ref(txOptions);
    const actionLoading = ref(false);
    const myModal = ref(null);
    const reportDetails = reactive({
      type: "wrong_brand",
      suggestion: null,
      description: null,
    });

    // computed
    const categoriesOptions = computed(() => {
      const options = [];
      for (const catId in categories) {
        options.push({
          key: categories[catId].label,
          value: categories[catId].label,
        });
      }
      return options;
    });
    const validatedReportForm = computed(() => {
      const payload = {};
      for (const key in reportDetails) {
        if (![null, "", undefined].includes(reportDetails[key]))
          payload[key] = reportDetails[key];
      }

      return payload;
    });
    const transactionsEnv = computed(() => store.state.transactionsEnv);

    const reportPayload = computed(() => {
      let reqPayload = {};

      switch (validatedReportForm.value.type) {
        case "wrong_brand":
          reqPayload = {
            invalid_brand: true,
            invalid_brand_suggestion:
              validatedReportForm?.value.suggestion ?? "",
            ...(validatedReportForm?.value.description && {
              invalid_brand_reason: validatedReportForm?.value.description,
            }),
          };
          break;

        case "wrong_category":
          reqPayload = {
            invalid_category: true,
            invalid_category_suggestion:
              validatedReportForm?.value.suggestion ?? "",
            ...(validatedReportForm?.value.description && {
              invalid_category_reason: validatedReportForm?.value.description,
            }),
          };
          break;

        case "wrong_logo":
          reqPayload = {
            invalid_logo: true,
            ...(validatedReportForm?.value.description && {
              invalid_logo_reason: validatedReportForm?.value.description,
            }),
          };
          break;
        default:
          break;
      }

      return reqPayload;
    });

    const suggestionLabel = computed(() =>
      validatedReportForm.value.type === "wrong_brand"
        ? "Suggested Brand"
        : "Suggested Category"
    );

    // watchers
    watch(
      () => props.showModal, // getter
      (showForm) => {
        if (showForm) {
          myModal.value.show();
        }
      }
    );

    // lifecycle hooks
    onMounted(() => {
      myModal.value = new Modal(document.getElementById(props.id), {
        // backdrop: "static",
        keyboard: false,
      });
      // myModal.value.show();
    });

    // vee-validate
    const validationSchema = object({
      report_tx_type: string()
        .required("Report type field is required")
        .nullable(),

      report_tx_category_suggestion: string().when("report_tx_type", {
        is: (val) => val === "wrong_category",
        then: string().nullable().required("Suggesion field is required"),
      }),

      report_tx_brand_suggestion: string().when("report_tx_type", {
        is: (val) => val === "wrong_brand",
        then: string().nullable().required("Suggesion field is required"),
      }),

      report_tx_description: string()
        // .required("Description field is required")
        .nullable(),
    });

    const { handleSubmit, errors, resetForm } = useForm({
      validationSchema,
      initialValues: {
        report_tx_type: "wrong_brand",
      },
    });

    // methods
    function setReportType(val) {
      reportDetails.type = val;
    }
    function setReportSuggested(val) {
      reportDetails.suggestion = val;
    }
    function setReportDescribtion(val) {
      reportDetails.description = val;
    }
    function onClose(event) {
      if (event.target.id === "report-transactions-modal") {
        ctx.emit("closeModal");
        resetForm();
      }
    }
    function onCancel() {
      ctx.emit("closeModal");
      resetForm();
    }
    const onReport = handleSubmit(() => {
      // console.log("Logggg");
      actionLoading.value = true;

      OverviewAPI.reportTransaction(props.data.global_tx_id ?? props.data.id, {
        ...reportPayload.value,
        // env: transactionsEnv.value,
      })
        .then(() => {
          notify("Reported Transaction");
          ctx.emit("updateTable"); // TODO: Disable this for now
        })
        .catch((err) => {
          console.error(err);
          notify("Failed to report transaction", { success: false });
        })
        .finally(() => {
          resetForm();
          myModal.value.hide();
          ctx.emit("closeModal");
          actionLoading.value = false;
        });
    });

    watch(
      () => reportDetails.type,
      () => {
        reportDetails.suggestion = null;
      }
    );

    return {
      categoriesOptions,
      reportTxOptions,
      actionLoading,
      myModal,
      reportDetails,
      validatedReportForm,
      reportPayload,
      suggestionLabel,
      setReportType,
      setReportSuggested,
      setReportDescribtion,
      onClose,
      onCancel,
      onReport,
      errors,
    };
  },
};
</script>

<style scoped>
.modal-title {
  font-weight: 500;
  font-size: 1.85rem;
}
.modal-dialog {
  width: 600px;
  max-width: 600px;
}
.modal-content {
  border-radius: 20px;
  box-shadow: 0px 4px 40px 0px rgba(204, 213, 212, 0.3);
}
.btn {
  padding: 0.7rem 2.5rem;
}
.input-box {
  width: 47.5%;
}
</style>
