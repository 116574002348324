<template>
  <div type="button" class="h-100 cp no-select sort-filter">
    <div class="d-flex flex-row align-items-center h-100">
      <span :class="{ active: sortIsActive || reverseSorting }">{{
        label
      }}</span>
      <i
        class="bi ms-2"
        :class="{
          'bi-chevron-down': !sortIsActive,
          'bi-chevron-up': sortIsActive,
          active: sortIsActive || reverseSorting,
        }"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    sortIsActive: {
      type: Boolean,
      default: false,
    },
    reverseSorting: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.sort-filter {
  background-color: transparent !important;
}
.sort-filter span {
  font-weight: 500;
  color: var(--bluey-grey);
  font-size: 0.93rem;
}
.active {
  color: var(--eggplant) !important;
}
</style>
