<template>
  <div class="d-flex gap-3 flex-row align-items-center">
    <h5
      class="p-0 m-0"
      :style="`color: ${color}`"
      :class="{ clickable: isClickable }"
    >
      {{ compactNumber(amount, 2) }}
      <span v-if="showCurrency">{{
        " " + (currency ?? $store.state.currency)
      }}</span>
    </h5>
    <div v-if="showTrendSign">
      <img
        v-if="!isTrendingPositively"
        class="trending-icon"
        src="@/assets/images/svg/components/Trending-Down.svg"
        alt="trneding icon"
      />
      <img
        v-else
        class="trending-icon"
        src="@/assets/images/svg/components/Trending-UP.svg"
        alt="trneding icon"
      />
    </div>
  </div>
</template>
<script>
import { inject } from "vue";

export default {
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    showCurrency: {
      type: Boolean,
      default: false,
    },
    showTrendSign: {
      type: Boolean,
      default: false,
    },
    isTrendingPositively: {
      type: Boolean,
      default: true,
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#469B93",
    },
    currency: {
      type: String,
      default: null,
    },
  },
  setup() {
    const compactNumber = inject("compactNumber");

    return { compactNumber };
  },
};
</script>
<style scoped>
h5 {
  color: var(--sea);
  font-size: 1.4rem;
  font-weight: 400 !important;
}
.trending-icon {
  width: 25px;
}
</style>
