<template>
  <div class="d-flex flex-column gap-2 cashflow-label">
    <div class="d-flex gap-2 align-items-center">
      <div class="colored-box" :style="`background: ${color}`"></div>
      <div>
        <h6 class="m-0 p-0">{{ title }}</h6>
      </div>
    </div>

    <div>
      <BaseAmountTitle
        :show-currency="true"
        :currency="currency"
        :show-trend-sign="true"
        :amount="amount"
        :is-trending-positively="diffSign"
        :color="color"
      />
    </div>
  </div>
</template>
<script>
import BaseAmountTitle from "../components/helpers/BaseAmountTitle";

export default {
  components: { BaseAmountTitle },
  props: {
    color: {
      type: String,
      default: "#469B93",
    },
    title: {
      type: String,
      default: "#469B93",
    },
    amount: {
      type: Number,
      default: 0,
    },
    diffSign: {
      type: Boolean,
      default: true,
    },
    currency: {
      type: String,
      default: null,
    },
  },
};
</script>
<style scoped lang="scss">
.cashflow-label {
  .colored-box {
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 4px;
    background-color: red;
  }
}
</style>
