<template>
  <div class="card flex justify-content-center base-multi-select">
    <MultiSelect
      v-model="selectedOptions"
      :options="options"
      :filter="filter"
      :show-toggle-all="false"
      :filter-placeholder="placeHolder"
      option-label="value"
      placeholder="Label"
      :filter-input-props="{ class: 'bms-filter-input' }"
      filter-icon=""
      :auto-filter-focus="false"
      scroll-height="270px"
      :loading="false"
      :selection-limit="selectionLimit"
      @update:modelValue="$emit('update:modelValue', selectedOptions)"
    >
      <template #value>
        <div class="d-flex justify-content-between align-items-center">
          <span>{{ label }}</span>
          <div class="ms-4 w-100 d-flex justify-content-end align-items-center">
            <div
              v-if="
                selectedOptions?.length > 0 ||
                getRange(selectedRange)?.length !== 0
              "
              class="
                bms-dp-counter
                d-flex
                justify-content-center
                align-items-center
              "
            >
              {{
                rangeInputs
                  ? getRange(selectedRange)?.length
                  : selectedOptions?.length
              }}
            </div>
            <img
              class="ms-1"
              src="@/assets/images/svg/dp-down-arrow.svg"
              alt="dp down arrow"
              width="20"
            />
          </div>
        </div>
      </template>

      <template #header="{ value }">
        <div
          class="bms-inner d-flex justify-content-between align-items-center"
        >
          <div class="bms-header-label ps-1">
            <div v-if="!rangeInputs">
              <span> {{ value?.length + " " }}</span>
              <span
                >{{
                  selectedOptions?.length > 1 ? plural : label
                }}
                selected</span
              >
            </div>
            <span v-else>{{
              getRange(selectedRange)?.label
                ? getRange(selectedRange)?.label
                : label + " Range"
            }}</span>
          </div>
          <ActionButton
            type="button"
            label="Clear"
            class="btn-transparent p-0"
            @click="clearInputs"
          />
        </div>
      </template>

      <template v-if="rangeInputs && !loadingAsync" #empty>
        <BaseRangeInputFilter
          :id="label"
          :range-values="rangeValues"
          :reset-range="resetRange"
          :label="label"
          @range-change="handleRangeInput"
        />
      </template>
      <template v-else-if="loadingAsync" #empty>
        <div
          class="d-flex justify-content-center align-items-center py-5 gap-2"
        >
          <i class="pi pi-spin pi-spinner" style="color: var(--green)"></i>
          <small>Loading</small>
        </div>
      </template>
      <template v-else #empty>
        <div>No results found</div>
      </template>

      <template v-if="showFooter" #footer>
        <div class="bms-inner">
          <ActionButton
            type="button"
            label="New Profile"
            class="btn-teal p-0"
          />
        </div>
      </template>
    </MultiSelect>
  </div>
</template>

<script>
import MultiSelect from "primevue/multiselect";
import ActionButton from "./ActionButton.vue";
import BaseRangeInputFilter from "../helpers/BaseRangeInputFilter";
import { inject, ref, watch } from "vue";

export default {
  components: { MultiSelect, ActionButton, BaseRangeInputFilter },
  props: {
    label: {
      type: String,
      required: true,
    },
    plural: {
      type: String,
      required: true,
    },
    placeHolder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    loadingAsync: {
      type: Boolean,
      default: false,
    },
    rangeInputs: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    selectionLimit: {
      type: Number,
      default: null,
    },
    rangeValues: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue", "range-change"],
  setup(props, { emit }) {
    const compactNumber = inject("compactNumber");
    const selectedOptions = ref([]);
    const selectedRange = ref([]);
    const resetRange = ref(0);

    function clearInputs() {
      // props.modelValue = [];
      selectedOptions.value = [];
      selectedRange.value = [];
      resetRange.value += 1;

      emit("update:modelValue", []);
    }
    function handleRangeInput(values) {
      selectedRange.value = [...values];
      emit("range-change", [...selectedRange.value]);
    }
    function getRange(rangeArr) {
      let range = rangeArr[0];
      let label = "";
      let length = 0;

      if (range?.min && range?.max) {
        label = `${props.label} From ${compactNumber(
          range?.min
        )} to ${compactNumber(range?.max)}`;
        length = 2;
      } else if (range?.min) {
        label = `${props.label}: ${compactNumber(range?.min)} and above`;
        length = 1;
      } else if (range?.max) {
        label = `${props.label}: ${compactNumber(range?.max)} and below`;
        length = 1;
      }

      return { label, length };
    }

    // watch(
    //   () => props.modelValue,
    //   (newVal, oldVal) => {
    //     // console.log(newVal === oldVal);
    //     //if (Object.keys(newVal)?.length !== Object.keys(oldVal)?.length) {
    //     if (newVal !== oldVal) {
    //       console.log(newVal, oldVal);
    //       selectedOptions.value = [...newVal];
    //     }
    //   }
    // );
    watch(
      () => props.rangeValues,
      (newVal, oldVal) => {
        if (Object.keys(newVal)?.length !== Object.keys(oldVal)?.length) {
          selectedRange.value = [...newVal];
        }
      }
    );

    watch(
      () => props.modelValue,
      (newVal) => {
        selectedOptions.value = [...props.modelValue];
      },
      { deep: true }
    );

    return {
      selectedOptions,
      selectedRange,
      resetRange,
      clearInputs,
      handleRangeInput,
      getRange,
    };
  },
};
</script>

<style>
.btn-transparent {
  opacity: 0.5;
}
.btn-transparent:hover {
  opacity: 1;
}
.btn-transparent span {
  color: var(--green) !important;
}
.btn-transparent:hover span {
  color: var(--green) !important;
}
/*Dropdown style*/
.p-multiselect {
  border: 1px solid white !important;
  transition: var(--d-ease) !important;
  box-shadow: 0px 4px 40px rgba(204, 213, 212, 0.3) !important;
  border-radius: 10px !important;
  padding: 0.35rem 0.3rem;
  font-size: var(--fs-input) !important;
  font-weight: 400;
}
.p-multiselect:hover {
  /* border: 1px solid var(--sea) !important; */
  box-shadow: none !important;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: var(--green) !important;
}
.p-multiselect-trigger,
.p-multiselect-trigger-icon {
  display: none !important;
}
.bms-dp-counter {
  background-color: var(--sea);
  color: white !important;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  font-size: 0.8rem;
}

/*Panel - BMS*/
.p-multiselect-panel {
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(155, 157, 157, 0.2) !important;
  border-radius: 10px !important;
  /* top: 190px !important; */
  margin-top: 15px;
  min-width: 320px !important;
  padding: 0.75rem 0;
}
.bms-inner {
  padding: 0.5rem 1rem;
}
.bms-header-label {
  color: var(--green-60);
  font-size: var(--fs-input);
  font-weight: 400;
}

/*Header Filter input*/
.p-multiselect-close {
  display: none;
}
.p-multiselect-header {
  background: white !important;
}
.p-multiselect-filter-container {
  background-color: white;
  border-radius: 10px;
}
.p-multiselect-filter-container input {
  background-color: white;
  color: var(--green);
  border-radius: 10px;
  border: 1px solid #ccd5d4 !important;
  transition: all 0.4s ease;
  font-size: var(--fs-input) !important;
  font-weight: 400 !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}
.p-multiselect-filter-container input::placeholder {
  color: #b3c0bf !important;
  opacity: 1 !important;
  font-weight: 400 !important;
}
.p-multiselect-filter-container input:focus {
  box-shadow: none !important;
  border: 1px solid var(--sea) !important;
}

/* Items */
.p-multiselect-item {
  border-bottom: 1px solid var(--teal-10) !important;
  margin: 0rem 1rem !important;
  padding: 0.75rem 0 !important;
}
.p-multiselect-item:last-child {
  border-bottom: none !important;
}
.p-multiselect-item span {
  color: var(--green);
  font-size: var(--fs-input) !important;
  font-weight: 400 !important;
}
.p-multiselect-item .p-checkbox-icon {
  color: var(--green);
  font-size: 0.7rem !important;
  font-weight: 400 !important;
}
.p-checkbox .p-checkbox-box {
  border: 1px solid #ccd5d4 !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: var(--green) !important;
  background: white !important;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--sea) !important;
  background: var(--sea) !important;
}
.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: var(--green) !important;
  background: var(--teal-10) !important;
}
.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
  background-color: transparent !important;
}
</style>
