<template>
  <div class="text-input text-start h-100">
    <div
      v-if="String(label).length > 0 && label"
      class="w-100 d-flex justify-content-between align-items-center"
    >
      <span v-if="!loading" class="">
        <label v-if="label?.length" :for="'input_$' + id" class="form-label">{{
          label
        }}</label>
        <label v-if="isOptional" class="input-optional-flag">- Optional</label>
      </span>

      <span v-if="!loading && !isDisabled">
        <label class="form-label">
          <slot name="label"> </slot>
        </label>
      </span>

      <Skeleton v-if="loading" width="35%" height="0.85rem" />
    </div>

    <div class="h-100">
      <textarea
        v-if="!loading"
        v-bind="{ ...$attrs }"
        :id="'textarea_$' + id"
        v-model="inputValue"
        :disabled="isDisabled"
        :placeholder="!isDisabled ? placeholder : '-'"
        class="form-control"
        :class="{
          inputError: !!inputError,
        }"
      />

      <Skeleton v-else class="my-2" width="100%" height="1.8rem" />
    </div>
    <span v-if="!isDisabled" class="error-message">{{
      inputError ?? "  "
    }}</span>
    <!-- <span>name: {{ name }}</span> -->
  </div>
</template>

<script>
import { useField } from "vee-validate";
import { toRef } from "vue";
import Skeleton from "primevue/skeleton";

export default {
  components: { Skeleton },
  props: {
    label: {
      type: String,
    },
    value: {
      type: [Number, String],
      default: "",
    },
    size: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
    name: {
      type: [String, Number],
    },
  },
  emits: ["value-change"],
  setup(props, context) {
    const name = toRef(props, "name");

    const { value: inputValue, errorMessage: inputError } = useField(
      name,
      undefined,
      {
        initialValue: props.value,
      }
    );

    return { inputValue, inputError, name };
  },
  // data() {
  //   return {
  //     //inputValue: "",
  //   };
  // },
  // mounted() {
  //   this.inputValue = this.value ?? "";
  // },
  watch: {
    value(newVal) {
      //console.log("Emitted Value")
      this.inputValue = newVal ?? "";
    },
    inputValue(newVal) {
      //console.log("Emitted inputValue: ", newVal);
      this.$emit("value-change", newVal);
    },
  },
};
</script>

<style scoped>
.form-label {
  opacity: 1;
}
.text-input label {
  font-size: var(--fs-input) !important;
  font-weight: 400;
  color: var(--green);
}
.text-input textarea {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 9px 10px;
  transition: all 0.4s ease;
  border: 1px solid var(--dark-green-20, #ccd5d4);
}
.text-input textarea:focus,
.text-input textarea:hover {
  border-color: var(--sea);
}
.text-input textarea::placeholder {
  color: #99abaa;
  font-weight: 400;
}
.text-input textarea.inputError {
  border: 1px solid var(--error);
}
.text-input textarea:disabled {
  font-size: 0.9rem;
  font-weight: 600;
  border: none;
  padding-left: 0px;
  background: transparent;
  color: var(--green);
}
.text-input .input-optional-flag {
  font-size: 0.68rem;
  opacity: 0.45;
  padding-left: 5px;
}
textarea.disableField {
  pointer-events: none;
  color: #002e2a50;
}
textarea.large-input {
  height: 100%;
  font-size: 1rem;
  border: 1px solid white;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
/* input.large-input:focus{
      border: 1px solid white;
    } */
</style>
