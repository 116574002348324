<template>
  <div class="d-flex flex-column py-3">
    <div class="d-flex align-items-center w-100">
      <h4 class="lu-base-card-title m-0 p-0">Accounts</h4>
    </div>

    <div class="lu-base-card-content w-100 h-100">
      <div v-if="!loading" class="pe-3">
        <div
          v-if="data?.accounts?.length && data?.accounts?.length !== 0"
          class="
            d-flex
            align-items-center
            justify-content-start
            gap-4
            acc-cards-wrapper
            py-3
          "
        >
          <UserAccountCard
            v-for="account in data?.accounts"
            :key="account?.account_id"
            :class="{
              'selected-account':
                selectedAccount?.account_id === account?.account_id,
            }"
            :account="account"
            @click="toggle(account)"
          />
        </div>

        <BaseEmptyState v-else min-height="15vh" icon-size="70px" />
      </div>

      <div
        v-else
        class="
          loading-container
          d-flex
          justify-content-center
          align-items-center
          w-100
          h-100
        "
      >
        <i class="pi pi-spin pi-spinner" style="color: var(--green)"></i>
      </div>
    </div>
  </div>
</template>

<script>
import BaseEmptyState from "./helpers/BaseEmptyState.vue";
import { useStore } from "vuex";
import UserAccountCard from "./UserAccountCard.vue";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  components: { UserAccountCard, BaseEmptyState },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const selectedAccount = ref({});
    const store = useStore();
    const route = useRoute();

    const toggle = (account) => {
      selectedAccount.value = { ...account };
    };
    watch(
      () => props.data?.accounts,
      (newArr) => {
        if (!newArr) return;

        if (Object.keys(selectedAccount.value ?? {})?.length === 0)
          selectedAccount.value = newArr[0];
        else {
          selectedAccount.value = newArr.filter(
            (acc) => acc?.account_id === selectedAccount.value?.account_id
          )?.[0];

          store.commit("updateSelectedAccount", {
            ...selectedAccount.value,
            user_id: route.params?.id,
          });
        }
      }
    );
    watch(selectedAccount, (newAcc) => {
      //[1] store the account to vuex
      store.commit("updateSelectedAccount", {
        ...newAcc,
        user_id: route.params?.id,
      });
    });

    return { selectedAccount, toggle };
  },
};
</script>
<style scoped>
h4.lu-base-card-title {
  color: var(--green);
  font-size: 1.4rem !important;
  font-weight: 600;
  line-height: 100%;
}
.lu-base-card-content {
  /* padding: 1rem; */
  /* border: 1px solid red; */
  border-radius: 20px;
  /* background-color: white; */
  /* box-shadow: 0px 4px 40px 0px rgba(204, 213, 212, 0.3); */
}
.pi-spin {
  opacity: 0.65;
  font-size: 1.8rem !important;
}
.loading-container {
  min-height: 12vh;
}
.selected-account {
  border-color: var(--green);
}
.acc-cards-wrapper {
  overflow-x: auto;
}
</style>
