<template>
  <div class="text-input">
    <label v-if="label?.length" :for="'input_$' + id" class="form-label">{{
      label
    }}</label>
    <label v-if="isOptional" class="input-optional-flag">- Optional</label>

    <div
      class="
        icon-input-wrapper
        bg-white
        d-flex
        justify-content-start
        align-items-center
      "
    >
      <i
        v-if="iconClass"
        :class="`pi ${loading ? 'pi-spin pi-spinner' : iconClass}`"
      />
      <input
        v-bind="{ ...$attrs }"
        :id="'input_$' + id"
        v-model="inputValue"
        class="w-100"
        :type="type"
        :disabled="isDisabled"
        :placeholder="!isDisabled ? placeholder : '-'"
        autocomplete="off"
      />

      <slot name="end"></slot>
      <i v-if="iconClassEnd" :class="`pi ${iconClassEnd} end-icon`" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    iconClass: {
      type: String,
      default: "pi-search",
    },
    iconClassEnd: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String],
      default: null,
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["value-change"],
  data() {
    return {
      inputValue: "",
    };
  },
  watch: {
    value(newVal) {
      this.inputValue = newVal ?? "";
    },
    inputValue(newVal) {
      this.$emit("value-change", newVal);
    },
  },
  mounted() {
    this.inputValue = this.value ?? "";
  },
};
</script>

<style scoped>
.text-input label {
  font-size: 0.8rem;
  font-weight: 400;
}
.text-input input {
  font-size: 0.95rem !important;
  padding: 8px !important;
  font-weight: 500;
  transition: all 0.4s ease;
}
.text-input input:disabled {
  font-size: 0.9rem;
  font-weight: 600;
  border: none;
  padding-left: 0px;
  background: transparent;
  color: var(--green);
}
.text-input .input-optional-flag {
  font-size: 0.68rem;
  opacity: 0.45;
  padding-left: 5px;
}

.icon-input-wrapper {
  border-radius: 0.375rem;
  border: 1px solid var(--input-border);
  transition: all 0.4s ease;
}

.icon-input-wrapper:focus-within {
  border: 1px solid var(--sea);
}
.icon-input-wrapper:focus-within i.pi {
  color: var(--green);
}
.icon-input-wrapper input {
  border-radius: 0.375rem;
  padding-left: 0px;
  border: none;
}

input[type="text"]:focus {
  border: none;
}

.icon-input-wrapper i.pi {
  padding: 0px 12px;
  color: var(--bluey-grey);
}
.end-icon {
  color: var(--sea) !important;
}
.text-input .icon-input-wrapper input:focus {
  outline: none;
  box-shadow: none;
  border: none;
}
</style>
