<template>
  <div>
    <!-- Modal -->
    <div
      :id="id"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
      @click="onClose"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div
          id="report-tx-feedback-form overflow-auto"
          class="modal-content px-1 py-2"
        >
          <div class="modal-header p-4 border-0 d-flex align-items-start">
            <h4 class="modal-title text-truncate">
              {{ data?.raw_description }}
            </h4>

            <button
              type="button"
              class="btn p-0 m-0 ps-2 pb-1"
              data-bs-dismiss="modal"
              @click="onModalClose"
            >
              <i class="pi pi-times"></i>
            </button>
          </div>
          <div class="border-top mx-4"></div>

          <div class="modal-body border-0 p-4 text-center">
            <div
              class="
                w-100
                d-flex
                gap-2
                flex-column
                align-items-start
                justify-content-start
                text-start
              "
            >
              <FeedbackPopupTextValue
                label="Brand"
                :value="data?.brand_name ?? '--'"
              />
              <FeedbackPopupTextValue
                label="Category"
                :value="
                  data?.category_id ? getCategoryLabel(data?.category_id) : '--'
                "
              />
              <FeedbackPopupTextValue
                label="Logo"
                :brand-logo="data?.brand_logo_url"
                :category-id="data?.category_id"
              />
            </div>

            <div class="border-top w-100 my-4"></div>

            <div
              class="
                w-100
                d-flex
                gap-2
                flex-column
                align-items-start
                justify-content-start
                text-start
              "
            >
              <FeedbackPopupTextValue
                v-if="reportObject?.type"
                label="Report Type"
                :value="capitalizeFirstLetter(reportObject?.type ?? '--')"
              />
              <FeedbackPopupTextValue
                v-if="reportObject?.suggested"
                :label="'Suggested ' + reportObject?.mode"
                :value="
                  reportObject?.mode === 'Category'
                    ? getCategoryLabel(reportObject?.suggested)
                      ? getCategoryLabel(reportObject?.suggested)
                      : reportObject?.suggested
                    : reportObject?.suggested ?? '--'
                "
              />
              <FeedbackPopupTextValue
                label="Description"
                :value="
                  reportObject?.mode
                    ? reportObject?.description ?? '--'
                    : 'Closed by the user'
                "
              />
              <FeedbackPopupTextValue
                label="Reported on"
                :value="formatDate(data?.review?.report_open_date) ?? '--'"
              />
              <FeedbackPopupTextValue
                label="Status"
                :type="data?.review?.status"
                :value="getStatusLabel(data?.review?.status)"
              />
              <FeedbackPopupTextValue
                v-if="data?.review?.report_close_date"
                :label="
                  data?.review?.status === 'updated'
                    ? 'Updated on'
                    : 'Closed on'
                "
                :value="formatDate(data?.review?.report_close_date) ?? '--'"
              />
            </div>

            <div
              v-if="
                data?.review?.status === 'updated' &&
                Object.keys(reportObject).length !== 0
              "
            >
              <div class="border-top w-100 my-4"></div>

              <div
                class="
                  w-100
                  d-flex
                  gap-2
                  flex-column
                  align-items-start
                  justify-content-start
                  text-start
                "
              >
                <FeedbackPopupTextValue
                  v-if="reportObject?.mode !== 'Logo'"
                  :label="'New ' + reportObject?.mode"
                  :value="
                    (reportObject?.mode === 'Brand'
                      ? data?.brand_name
                      : categories[data?.category_id]?.label) ?? '--'
                  "
                />
                <!-- <FeedbackPopupTextValue
                    label="Closed on"
                    :value="formatDate(data?.review?.report_close_date) ?? '--'"
                  /> -->
              </div>
            </div>
          </div>

          <div
            class="
              modal-footer
              border-0
              p-3
              d-flex
              justify-content-end
              align-items-center
              gap-1
            "
          >
            <button
              type="button"
              class="btn btn-transparent py-2"
              data-bs-dismiss="modal"
              @click="onModalClose"
            >
              Cancel
            </button>

            <ActionButton
              v-if="data?.review?.status !== 'pending'"
              type="button"
              label="Report again"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              :loading="loadingAgainReport"
              @click="reportAgain(data)"
            />

            <ActionButton
              v-if="data?.review?.status === 'pending'"
              type="button"
              label="Cancel Report"
              class="btn btn-warn"
              :loading="loadingCancelReport"
              @click="cancelReport(data)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeedbackPopupTextValue from "@/components/FeedbackPopupTextValue.vue";
import { Modal } from "bootstrap";
import moment from "moment";
import { categories } from "@/data/options";
import ActionButton from "@/components/helpers/ActionButton.vue";
import OverviewAPI from "@/services/api/OverviewAPI";

export default {
  components: {
    ActionButton,
    FeedbackPopupTextValue,
  },
  inject: ["notify", "capitalizeFirstLetter"],
  props: {
    data: {
      type: [Array, Object, String, Number],
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
  },
  emits: ["updateTable", "closeModal", "reportAgain"],
  data() {
    return {
      categories,
      loadingCancelReport: false,
      loadingAgainReport: false,
      myModal: null,
    };
  },
  computed: {
    transactionsEnv() {
      return this.$store.state.transactionsEnv;
    },
    reportObject() {
      const report = {};

      switch (true) {
        case this.data?.is_invalid_brand:
          report.mode = "Brand";
          report.type = "Incorrect Brand";
          report.suggested = this.data?.user_recommended_brand_name;
          report.description = this.data?.invalid_brand_reason;
          break;

        case this.data?.is_invalid_category:
          report.mode = "Category";
          report.type = "Incorrect Category";
          report.suggested = this.data?.user_rec_category_id;
          report.description = this.data?.invalid_category_reason;
          break;

        case this.data?.is_invalid_logo:
          report.mode = "Logo";
          report.type = "Incorrect Logo";
          report.suggested = null;
          report.description = this.data?.invalid_logo_reason;
          break;
        default:
          break;
      }

      return report;
    },
  },
  watch: {
    showModal(showForm) {
      if (showForm) {
        this.myModal.show();
      }
    },
  },
  mounted() {
    this.myModal = new Modal(document.getElementById(this.id), {
      // backdrop: "static",
      keyboard: false,
    });
    // this.myModal.show();
  },
  methods: {
    getStatusLabel(status) {
      let label = "";
      label = status === "pending" ? "Reported" : status ?? "--";
      return this.capitalizeFirstLetter(label.trim());
    },
    getCategoryLabel(catId) {
      return categories[catId]?.label;
    },
    formatDate(date) {
      if (!date) return null;
      return moment(date).format("D MMMM, YYYY");
    },
    onClose(event) {
      if (event.target.id === "reported-transactions-feecback-modal") {
        this.$emit("closeModal");
      }
    },
    onModalClose() {
      this.$emit("closeModal");
    },
    reportAgain(tx) {
      // [1] Pass data to report modal
      this.$emit("reportAgain", tx);

      // [2] Close current modal
      this.$emit("closeModal");
    },
    cancelReport(tx) {
      this.loadingCancelReport = true;

      const emptyPayload = {
        // env: this.transactionsEnv,
        invalid_brand: false,
        // invalid_brand_suggestion: null,
        // invalid_brand_reason: null,
        invalid_category: false,
        // invalid_category_suggestion: null,
        // invalid_category_reason: null,
        invalid_logo: false,
        // invalid_logo_reason: null,
      };
      OverviewAPI.reportTransaction(tx?.ref_id ?? tx?.id, emptyPayload)
        .then((res) => {
          this.notify("Reported Cancelled", "success");
          this.$emit("updateTable");
        })
        .catch((err) => {
          console.error(err);
          this.notify("Failed to cancel report", "error");
        })
        .finally(() => {
          this.myModal.hide();
          this.$emit("closeModal");
          this.loadingCancelReport = false;
        });
    },
  },
};
</script>

<style scoped>
h4 {
  font-size: 1.6rem !important;
}
.modal-title {
  font-weight: 500;
  font-size: 1.85rem;
}
.modal-dialog {
  width: 600px;
  max-width: 600px;
}
.modal-content {
  border-radius: 20px;
  box-shadow: 0px 4px 40px 0px rgba(204, 213, 212, 0.3);
}
.modal-dialog .pi.pi-times {
  font-size: 1rem !important;
  opacity: 0.5 !important;
  transition: all 0.4s ease;
}
.modal-dialog .pi.pi-times:hover {
  opacity: 1 !important;
}
.btn {
  padding: 0.7rem 2.5rem;
}
</style>
