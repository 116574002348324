<template>
  <div class="d-flex flex-column align-items-between gap-2">
    <div
      class="w-100 d-flex justify-content-end align-items-center"
      :class="{
        'justify-content-between': topSpendDate && topSpendDate?.data?.y !== 0,
      }"
    >
      <h6 v-if="topSpendDate && topSpendDate?.data?.y !== 0">
        Top Spending Date:
        <span class="ps-2">{{ topSpendDate?.date }}</span>
      </h6>
      <DropDownInput
        id="user-spend-chart-toggle"
        :value="selectedChartType"
        class="me-2"
        :options="chartTypeOpts"
        label=""
        @selected-option="setChartType"
      />
    </div>
    <BaseLineChart
      v-if="selectedChartType === 'line'"
      :tooltip="toolTip"
      class="w-100 h-100"
      :primary-data="chartData?.current"
      :secondary-data="chartData?.relative"
      :data="data"
    />
    <BaseGroupedBarChart
      v-else
      class="w-100 h-100"
      :tooltip="toolTip"
      :primary-data="chartData?.current"
      :secondary-data="chartData?.relative"
      :data="data"
    />
  </div>
</template>
<script>
import { computed, ref } from "vue";
import BaseLineChart from "./charts/BaseLineChart.vue";
import BaseGroupedBarChart from "./charts/BaseGroupedBarChart.vue";
import { useStore } from "vuex";
import DropDownInput from "./helpers/DropDownInput.vue";
import { categorySpendOverTimeTooltip } from "@/composables/ChartTooltips";
import moment from "moment";

export default {
  components: { BaseLineChart, BaseGroupedBarChart, DropDownInput },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    currency: {
      type: String,
      default: "AED",
    },
  },
  emits: ["update:chartSumData"],
  setup(props) {
    const store = useStore();
    const chartTypeOpts = ref([
      { key: "line", value: "Line Chart" },
      { key: "bar", value: "Bar Chart" },
    ]);
    const selectedChartType = ref("line");
    const metricFilters = computed(
      () => store.state.overViewStore.metricFilters
    );
    const timeDaysCount = computed(() => store.getters.timeDaysCount);

    const chartData = computed(() => {
      let data = { current: [], relative: [] };

      //Check if empty data, => exit
      const pointsCount = (props.data ?? [])?.length ?? 0;
      if (pointsCount === 0) return data;

      //Format the data
      for (let dataPoint in props.data) {
        const dataObj = props.data[dataPoint];

        // == [1] set current data points ==
        data.current.push({
          x: dataObj?.tenant_customer_transaction_count_date,
          y:
            metricFilters.value.key === "average"
              ? Math.round(Math.abs(dataObj?.avg_txn_value ?? 0))
              : Math.round(Math.abs(dataObj?.tenant_customer_total_spend ?? 0)),
        });

        // == [2] set relative data points ==
        data.relative.push({
          x: dataObj?.tenant_customer_transaction_count_date,
          y:
            metricFilters.value.key === "average"
              ? Math.round(Math.abs(dataObj?.rel_avg_txn_value ?? 0))
              : Math.round(
                  Math.abs(
                    dataObj?.comparison_period_tenant_customer_total_spend ?? 0
                  )
                ),
        });
      }

      //sort and return
      return {
        current: data.current.sort((a, b) => {
          return new Date(b.x) - new Date(a.x);
        }),
        relative: data.relative.sort((a, b) => {
          return new Date(b.x) - new Date(a.x);
        }),
      };
    });
    const topSpendDate = computed(() => {
      const values = Object.values(chartData.value?.current ?? {});

      if (values.length !== 0) {
        const max = values.reduce((prev, current) => {
          return (prev?.y ?? 0) > Math.abs(current?.y ?? 0) ? prev : current;
        });

        return {
          date: moment(max?.x).format(
            timeDaysCount.value.key === "monthly" ? "MMMM, YYYY" : "D MMM, YYYY"
          ),
          data: max,
        };
      }

      return false;
    });

    function setChartType(selectedValue) {
      selectedChartType.value = selectedValue;
    }

    const toolTip = categorySpendOverTimeTooltip(
      props.data,
      props.currency,
      (clickedData) => {
        console.log("Clicked: ", clickedData);
      }
    );

    return {
      chartData,
      chartTypeOpts,
      selectedChartType,
      setChartType,
      toolTip,
      topSpendDate,
    };
  },
};
</script>
<style scoped lang="scss">
h6 {
  color: var(--dark-green-60, #66827f);
  font-size: 0.88rem !important;
  font-weight: 400;

  span {
    color: var(--dark-green-100, #002e2a);
  }
}
</style>
