<template>
  <div class="w-100 d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center">
      <div class="flex-grow-1 label-div">
        {{ compactNumber(count) }} {{ computedLabel }} selected
      </div>
      <div class="clear-btn-div cp" @click="$emit('clear')">Clear</div>
    </div>
  </div>
</template>

<script>
import ActionButton from "./helpers/ActionButton.vue";
export default {
  components: { ActionButton },
  inject: ["compactNumber"],
  props: {
    count: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: "",
    },
    plurl: {
      type: String,
      default: null,
    },
  },
  emits: ["clear"],
  computed: {
    computedLabel() {
      if ((this.count ?? 0) <= 1) return this.label;

      return this.plurl ? this.plurl : this.label + "s";
    },
  },
};
</script>

<style lang="scss" scoped>
.label-div {
  color: var(--dark-green-60, #66827f);
}
.clear-btn-div {
  color: var(--green);
  text-decoration: underline;

  &:hover {
    color: var(--sea);
    transition: var(--d-ease);
  }
}
</style>
