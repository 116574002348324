<template>
  <div class="rm-name-box">
    <h6>RM: {{ loading ? "Loading..." : data?.RMName ?? "--" }}</h6>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.rm-name-box {
  background-color: #e6eaea;
  padding: 0.65rem 1.1rem;
  border-radius: 15px;

  h6 {
    font-size: 0.88rem;
    font-weight: 500;
  }
}
</style>
