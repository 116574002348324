<template>
  <div class="d-flex justify-content-center align-items-center w-100 h-100">
    <i class="pi pi-spin pi-spinner" style="color: var(--green)"></i>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.pi.pi-spin {
  opacity: 0.65;
  font-size: 2.1rem !important;
}
div {
  min-height: 40vh;
}
</style>
