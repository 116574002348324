<template>
  <div class="d-flex w-100 gap-3 justify-content-start align-items-start my-3">
    <table class="table p-0 m-0 position-relative">
      <tbody>
        <tr
          v-for="(item, index) in data.slice(0, 5)"
          :key="index"
          :class="{ 'td-mm-cell': index < 4 }"
          class="p-0 m-0"
        >
          <td class="col-1 p-0 m-0">
            <LogoIcon
              :src="
                forCategories
                  ? categories[item?.id]?.icon
                  : `${$store.getters.brandLogosBaseURL}${item?.logo}`
              "
              :is-category-icon="forCategories"
              :is-brand-icon="!forCategories"
              loading="lazy"
            />
          </td>
          <td class="col-6">
            <span class="lu-tb-card-text">{{ item?.name ?? "--" }}</span>
          </td>

          <td class="col-1 text-end">
            <div
              class="d-flex flex-nowrap justify-content-end align-items-center"
            >
              <div
                class="
                  text-nowrap
                  d-flex
                  gap-2
                  justify-content-center
                  align-items-center
                  h-100
                  cp
                "
                @click="
                  forCategories
                    ? redirectToTxsByCategory(item)
                    : redirectToTxsByBrand(item)
                "
              >
                <h4 class="lu-tb-card-text-sea text-underline p-0 m-0">
                  {{
                    forCategories
                      ? compactNumber(
                          Math.abs(
                            metric?.key === "average"
                              ? item?.tenant_customers_avg_spend
                              : item?.tenant_customers_total_spend
                          )
                        )
                      : compactNumber(
                          Math.abs(
                            metric?.key === "average"
                              ? item?.tenant_customers_avg_spend
                              : item?.tenant_customers_total_spend
                          )
                        )
                  }}
                </h4>
                <h4 class="lu-tb-card-text-sea p-0 m-0">
                  {{ $store.state.currency }}
                </h4>
                <img
                  v-if="
                    forCategories
                      ? getCategoryDiff(item)?.diffSign ?? true
                      : getBrandDiff(item)?.diffSign ?? true
                  "
                  class="lu-tb-card-icon"
                  src="@/assets/images/svg/components/Trending-UP.svg"
                  alt="trending-icon"
                />
                <img
                  v-else
                  class="lu-tb-card-icon"
                  src="@/assets/images/svg/components/Trending-Down.svg"
                  alt="trending-icon"
                />
              </div>
            </div>
          </td>

          <td class="col-1">
            <div
              class="
                d-flex
                flex-nowrap
                justify-content-between
                align-items-center
              "
            >
              <img
                src="@/assets/images/svg/components/v-line-sm.svg"
                alt="line svg"
                class="pe-2"
              />
              <div
                class="
                  text-nowrap
                  d-flex
                  gap-2
                  justify-content-center
                  align-items-center
                  h-100
                  cp
                "
                @click="
                  forCategories
                    ? redirectToUsersByCategory(item)
                    : redirectToUsersByBrand(item)
                "
              >
                <h4 class="lu-tb-card-text-sea text-underline p-0 m-0">
                  {{ compactNumber(item?.tenant_customers_count) }}
                </h4>
                <img
                  width="14"
                  src="@/assets/images/svg/components/users-sm.svg"
                  alt="Users-icon"
                />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import LogoIcon from "@/components/LogoIcon.vue";
import { categories } from "../data/options";
import { useDiffCalculator } from "@/composables/Helpers";
import { computed, inject } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  components: { LogoIcon },
  inject: ["compactNumber"],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    forCategories: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const metric = computed(() => store.state.overViewStore.metricFilters);
    const getCategoryLabel = inject("getCategoryLabel");
    const router = useRouter();

    const currency = computed(() => store.state.currency.trim());

    function getCategoryDiff(category) {
      if (metric.value?.key === "average") {
        let currentAvg = category?.tenant_customers_avg_spend ?? 0;
        let oldAvg = category?.comparison_period_tenant_customers_avg_spend;
        oldAvg = isFinite(oldAvg) ? oldAvg : 0;

        return useDiffCalculator(currentAvg, oldAvg);
      } else {
        return useDiffCalculator(
          category?.tenant_customers_total_spend,
          category?.comparison_period_tenant_customers_total_spend
        );
      }
    }

    function getBrandDiff(brand) {
      if (metric.value?.key === "average") {
        let currentAvg = brand?.tenant_customers_avg_spend ?? 0;
        let oldAvg = brand?.comparison_period_tenant_customers_avg_spend ?? 0;
        oldAvg = isFinite(oldAvg) ? oldAvg : 0;

        return useDiffCalculator(currentAvg, oldAvg);
      } else {
        return useDiffCalculator(
          brand?.tenant_customers_total_spend,
          brand?.comparison_period_tenant_customers_total_spend
        );
      }
    }
    function redirectToUsersByCategory(item) {
      router.push({
        name: "UsersMainByCategory",
        params: {
          categoryId: item?.id,
          categoryLabel: item?.name,
          filters: JSON.stringify({
            multi_currency: [{ key: currency.value, value: currency.value }],
          }),
        },
      });
    }
    function redirectToUsersByBrand(item) {
      router.push({
        name: "UsersMainByBrand",
        params: {
          brandId: item?.id,
          brandLabel: item?.name,
          filters: JSON.stringify({
            multi_currency: [{ key: currency.value, value: currency.value }],
          }),
        },
      });
    }

    function redirectToTxsByCategory(item) {
      store.commit("updateSelectedCategoriesFilter", [item]);
      router.push({
        name: "MainTransactions",
        params: {
          filters: JSON.stringify({
            multi_currency: [{ key: currency.value, value: currency.value }],
          }),
        },
      });
    }
    function redirectToTxsByBrand(item) {
      store.commit("updateSelectedBrandsFilter", [item]);
      router.push({
        name: "MainTransactions",
        params: {
          filters: JSON.stringify({
            multi_currency: [{ key: currency.value, value: currency.value }],
          }),
        },
      });
    }

    return {
      metric,
      getCategoryDiff,
      getBrandDiff,
      getCategoryLabel,
      redirectToUsersByCategory,
      redirectToUsersByBrand,
      redirectToTxsByCategory,
      redirectToTxsByBrand,
    };
  },
  data() {
    return {
      categories: categories,
    };
  },
};
</script>
<style scoped>
table {
  vertical-align: middle;
}
td {
  line-height: 3.3rem !important;
  border-bottom: none;
}
.td-mm-cell td {
  border-bottom: 1px solid #edf5f4;
}
.lu-tb-card-text {
  color: var(--dark-green-100, #002e2a);
  font-size: 0.98rem !important;
  font-weight: 400;
}
.lu-tb-card-text-sea {
  color: var(--sea);
  font-size: 0.98rem !important;
  font-weight: 400;
}
.lu-tb-card-icon {
  width: 20px;
}
</style>
