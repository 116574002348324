import NBFService from "@/services/NBFService";

export default {
  state() {
    return {
      selectedAccount: {},
      specificCustomersNBFDetails: {},
      specificCustomersNBFDetailsLoading: false,
    };
  },
  getters: {
    selectedUserCurrency(state) {
      return state.selectedAccount?.account_currency ?? "AED";
    },
  },
  mutations: {
    updateSelectedAccount(state, payload) {
      state.selectedAccount = { ...payload };
    },
    updateSpecificCustomersNBFDetails(state, payload) {
      state.specificCustomersNBFDetails = { ...payload };
    },
    updateSpecificCustomersNBFDetailsLoading(state, payload) {
      state.specificCustomersNBFDetailsLoading = payload;
    },
  },
  actions: {
    getCustomerDetails({ commit }, cloudId) {
      commit("updateSpecificCustomersNBFDetailsLoading", true);

      NBFService.getCustomerDetails(cloudId)
        .then((res) => {
          commit("updateSpecificCustomersNBFDetails", {
            ...(res?.data ? res?.data : res),
          });
          commit("updateSpecificCustomersNBFDetailsLoading", false);
        })
        .catch((error) => {
          if (error !== "canceled") {
            commit("updateSpecificCustomersNBFDetails", {
              CustomerName: `User Id: ${cloudId}`,
              Status: "ERROR",
              RMName: "--",
              CifNumber: "--",
            });

            commit("updateSpecificCustomersNBFDetailsLoading", false);
            console.error("error: ", error);
          }
        });
    },
  },
};
