<template>
  <div class="card p-3 mx-2 my-4">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="card-title no-select me-auto text-start mb-3">
          Top brands with highest spending
        </h4>

        <InfoPopover
          data-html2canvas-ignore
          class="pb-2"
          title="Top Brands"
          content="Based on the time period chosen , top brands in the selected category are displayed."
        />
      </div>

      <div class="pt-3 w-100">
        <div class="text-start w-100 overflow-auto top-view-all-table">
          <table v-if="sortedList.length !== 0" class="w-100 h-100">
            <thead>
              <tr>
                <th class="pb-3 text-nowrap">Logo</th>
                <th class="pb-3 ps-4 text-nowrap">Name</th>
                <th class="pb-3 text-nowrap">Total Spend</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(brand, index) in sortedList"
                :key="brand.id"
                :class="[index == 0 ? '' : 'border-top border-light']"
              >
                <td class="w-10">
                  <div>
                    <LogoIcon :src="getIcon(brand)" :is-brand-icon="true" />
                  </div>
                </td>

                <td class="text-start eggplant-text w-80 ps-4">
                  <div class="brand-label py-3">
                    {{ brand.name }}
                  </div>
                </td>

                <td>
                  <div class="w-10 text-nowrap">
                    <small
                      ><small>{{ currency }}</small></small
                    >
                    <span class="ps-1 text-nowrap">{{
                      numberWithCommas(
                        brand?.tenant_customers_total_spend.toFixed(0) ?? 0
                      )
                    }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-else
            class="
              no-data-state-container
              d-flex
              justify-content-center
              align-items-center
            "
          >
            <h5>No Data to show.</h5>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" class="loader px-auto py-auto">
      <span class="position-absolute top-50 start-50 translate-middle">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import LogoIcon from "./LogoIcon.vue";
import InfoPopover from "./InfoPopover.vue";

export default {
  components: { InfoPopover, LogoIcon },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    brandsList: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      invalid_urls: [],
    };
  },
  computed: {
    currency() {
      return this.$store.state.currency;
    },
    sortedList() {
      let list = this.brandsList;

      list = list.sort((a, b) => {
        return (
          (a?.tenant_customers_total_spend ?? 0) -
          (b?.tenant_customers_total_spend ?? 0)
        );
      });

      return list;
    },
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getIcon(brand) {
      return `${process.env.VUE_APP_IMG_ORIGINAL_BASE_URL}/${brand.logo}`;
    },
    getLabel(brand) {
      //console.log(brand.name);
      return brand.name;
    },
    markInvalidUrl(url) {
      this.invalid_urls.push(url);
    },
  },
};
</script>

<style scoped>
td,
li,
ul,
span,
h2 {
  color: var(--eggplant) !important;
}
.brand-icons img {
  width: 31px;
}
.brand-label {
  color: var(--eggplant) !important;
}

.no-data-state-container,
.top-view-all-table {
  min-height: 675px;
}
.no-data-state-container h5 {
  opacity: 0.4;
}
.top-view-all-table table thead th {
  color: var(--green);
  font-weight: 600;
  font-size: 0.93rem;
}
.top-view-all-table table tbody tr {
  border-top: 1px solid var(--span-grey);
}
.top-view-all-table table tbody tr td:first-child {
  padding-top: 1rem;
}
.top-view-all-table table tbody tr td {
  padding: 0.35rem 0;
}
</style>
