<template>
  <div class="box">
    <div class="vertical-center d-flex align-items-center w-100">
      <div class="form-container my-5 p-5 rounded container-lg">
        <form @submit.prevent="submitOTP">
          <div class="container row justify-content-center">
            <div class="my-4 col-12 text-center">
              <span class="form-heading">Verification code</span>
            </div>

            <div class="my-4 col-12">
              <div class="otp-form d-flex gap-4">
                <input
                  id="first"
                  v-model="otpCode[0]"
                  class="flex-grow-1"
                  type="text"
                  inputmode="numeric"
                  maxlength="1"
                  required
                  @keyup="otpAddClick('first', 'second')"
                  @keyup.delete="otpRemoveClick('first', 'first')"
                />
                <input
                  id="second"
                  v-model="otpCode[1]"
                  class="flex-grow-1"
                  type="text"
                  inputmode="numeric"
                  maxlength="1"
                  required
                  @keyup="otpAddClick('second', 'third')"
                  @keyup.delete="otpRemoveClick('second', 'first')"
                />
                <input
                  id="third"
                  v-model="otpCode[2]"
                  class="flex-grow-1"
                  type="text"
                  inputmode="numeric"
                  maxlength="1"
                  required
                  @keyup="otpAddClick('third', 'forth')"
                  @keyup.delete="otpRemoveClick('third', 'second')"
                />
                <input
                  id="forth"
                  v-model="otpCode[3]"
                  class="flex-grow-1"
                  type="text"
                  inputmode="numeric"
                  maxlength="1"
                  required
                  @keyup.delete="otpRemoveClick('forth', 'third')"
                />
              </div>
            </div>

            <div class="my-4 col-12 text-center">
              <span v-if="otpResendTime > 0" class="opt-resend-info">
                Resend code in {{ otpResendTime }} sec
              </span>
              <span v-else
                >Didn't receive the code?
                <a class="ps-1 cp resendOtp-link" @click="countDownTimer"
                  >Resend</a
                ></span
              >
            </div>

            <div class="col-12 my-4">
              <button
                type="submit"
                class="form-btn btn py-3 col-12 fw-bold"
                :disabled="!isOtpValid"
              >
                Verify
              </button>
            </div>

            <div class="col-12 my-3 text-center login-foot">
              <span>Having trouble?</span>
              <a class="ps-2" href="mailto: lune@support.ae">Contact us</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { useRouter } from "vue-router";

export default {
  setup() {
    const otpCode = ref([]);
    const otpResendTime = ref("5");
    const isOtpValid = ref(false);
    const router = useRouter();

    const submitOTP = () => {
      if (isOtpValid.value) {
        //console.log("Valid OTP")
        router.replace({ name: "Overview" });
      } else {
        //console.log("Invalid OTP")
      }
    };

    const countDownTimer = () => {
      let timeLeft = 5;
      let timerId = setInterval(countdown, 1000);

      function countdown() {
        if (timeLeft == -1) {
          clearTimeout(timerId);
          //console.log("time is out")
          otpResendTime.value = "Resend the code";
        } else {
          otpResendTime.value = timeLeft;
          timeLeft--;
        }
      }
    };

    const otpAddClick = (first, last) => {
      if (document.getElementById(first).value) {
        document.getElementById(last).focus();
      }
    };

    const otpRemoveClick = (now, previous) => {
      document.getElementById(now).value = "";
      document.getElementById(previous).focus();
    };

    watchEffect(() => {
      otpCode.value.forEach((element) => {
        if (otpCode.value.length == 4 && !(element == "" || element == null)) {
          isOtpValid.value = true;
        } else {
          isOtpValid.value = false;
        }
      });
    });

    countDownTimer();
    return {
      otpResendTime,
      otpCode,
      isOtpValid,
      submitOTP,
      countDownTimer,
      otpAddClick,
      otpRemoveClick,
    };
  },
};
</script>

<style scoped>
div {
  color: #151515 !important;
}
.otp-form input {
  width: 30px;
  height: 85px;
  padding: 0px !important;
  font-size: 3rem;
  text-align: center;
  background-color: var(--bluey-grey-5);
  border: 1px solid var(--bluey-grey-25);
  border-radius: 10px;
}
.opt-resend-info {
  color: var(--bluey-grey);
}
.resendOtp-link {
  color: var(--sea);
}
</style>
