<template>
  <div>
    <div class="p-2 mb-4 pb-3 d-flex">
      <h2 class="header m-0 me-auto">
        <small><small>Account Details</small></small>
      </h2>
    </div>

    <div class="p-4 d-flex card w-100">
      <form
        id="account-info-form"
        :key="reloadForm"
        class="d-flex flex-column m-2 my-3"
        @submit.prevent="
          saveChanges();
          track();
        "
      >
        <div
          class="
            d-flex
            gap-5
            align-items-center
            justify-content-between
            p-2
            mb-2
            mt-0
            pt-0
            rounded
          "
        >
          <div class="w-100">
            <InputField
              id="firstName"
              label="Name"
              type="text"
              :input-value="accountData.name"
              :is-disabled="userType == 'viewer'"
              @updateInputValue="setFirstname"
            />
          </div>
        </div>

        <div
          class="
            d-flex
            gap-5
            align-items-center
            justify-content-between
            p-2
            my-2
            rounded
          "
        >
          <div class="w-50">
            <InputField
              label="Email address"
              type="email"
              :input-value="accountData.email"
              :is-disabled="true"
              :start-icon="require('../assets/images/svg/ic-contact-mail.svg')"
              :end-icon="require('../assets/images/svg/ic-actions-check.svg')"
              @updateInputValue="setEmail"
            />
          </div>

          <div class="w-50">
            <InputField
              label="Password"
              type="password"
              input-value="********"
              :is-disabled="true"
              start-icon=""
              @changePassword="toggleForm(true)"
            />
          </div>
        </div>

        <div
          class="
            d-flex
            gap-5
            align-items-center
            justify-content-between
            p-2
            my-2
            mb-3
            rounded
          "
        >
          <div v-if="!teamsLoading" class="w-50">
            <FormDropdown
              label="Team"
              :disabled="userType == 'viewer'"
              :selection="selectedTeam"
              :options="teamList"
              @onSelect="onSelectTeam"
            />
          </div>

          <div class="w-50"></div>
        </div>
        <div class="flex-grow-1 mt-3">
          <div class="d-flex align-items-center justify-content-end">
            <div class="mt-4">
              <button class="form-btn btn discard-btn" @click="discardChanges">
                Discard changes
              </button>
            </div>

            <div class="mt-4">
              <ActionButton
                :disabled="!isFormValid"
                type="submit"
                label="Save changes"
                for="account-info-form"
                class="btn-primary py-2 px-5"
              />
            </div>
          </div>
        </div>
      </form>
    </div>

    <div
      v-if="showForm"
      class="overlay fixed-top w-100 h-100 d-flex justify-content-center"
      @click="toggleForm(false)"
    >
      <div
        class="mx-auto my-auto card box p-5 border d-flex flex-column"
        @click="stopPropagation"
      >
        <div class="row">
          <div class="col-12 mx-3">
            <form id="change-pass-st" @submit.prevent="changePassword">
              <div class="container row justify-content-center">
                <div class="my-4 col-12 text-center">
                  <h6 class="form-heading">Change your password</h6>
                </div>

                <div class="mt-3 col-12">
                  <InputField
                    label="Current password"
                    :type="showingPwd.pwd ? 'text' : 'password'"
                    :input-value="pwdResetData.current_password ?? ''"
                    :is-disabled="false"
                    :reactive="true"
                    :show-change-pwd-label="false"
                    :end-icon="showingPwd.pwd ? hidePwdIcon : showPwdIcon"
                    @fieldKeyUp="setOldPwd"
                  />
                </div>

                <div class="pt-2 col-12">
                  <hr />
                </div>

                <div class="my-2 col-12">
                  <InputField
                    label="New Password"
                    :type="showingPwd.new_1 ? 'text' : 'password'"
                    :input-value="pwdResetData.password ?? ''"
                    :is-disabled="false"
                    :reactive="true"
                    :show-change-pwd-label="false"
                    :end-icon="showingPwd.new_1 ? hidePwdIcon : showPwdIcon"
                    @fieldKeyUp="setNewPwd"
                  />
                </div>

                <div class="my-2 col-12">
                  <InputField
                    label="Confirm new password"
                    :type="showingPwd.new_2 ? 'text' : 'password'"
                    :input-value="pwdResetData.password_confirmation ?? ''"
                    :is-disabled="false"
                    :reactive="true"
                    :show-change-pwd-label="false"
                    :end-icon="showingPwd.new_2 ? hidePwdIcon : showPwdIcon"
                    @fieldKeyUp="setNewPwdConfirmation"
                  />
                </div>

                <div
                  class="
                    col-12
                    my-4
                    pt-3
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                >
                  <ActionButton
                    :disabled="!isPwdFormValid"
                    type="submit"
                    label="Change password"
                    for="change-pass-st"
                    class="btn-primary py-3 px-5"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from "../components/InputField.vue";
import FormDropdown from "../components/FormDropdown.vue";
import RestService from "@/services/RestService.js";
import ActionButton from "../components/helpers/ActionButton.vue";

export default {
  components: { InputField, FormDropdown, ActionButton },
  inject: ["notify", "load"],
  data() {
    return {
      userType: "admin", //viewer, admin
      iconPath: require("../assets/images/svg/ic-actions-check.svg"),
      reloadForm: 0,
      showForm: false,
      currentPassword: "",
      newPassword: "",
      newConfirmPassword: "",
      showPwdIcon: require("../assets/images/svg/eye.svg"),
      hidePwdIcon: require("../assets/images/svg/eye-slash.svg"),
      initialAccount: {},
      accountData: {},
      pwdResetData: {},
      showingPwd: {
        old: false,
        new_1: false,
        new_2: false,
      },
    };
  },
  computed: {
    selectedTeam() {
      return this.teamList[this.accountData?.team];
    },
    teamList() {
      return this.$store.state.settingsStore.teams;
    },
    teamsLoading() {
      return this.$store.state.settingsStore.teamsLoading;
    },
    isFormValid() {
      return [
        this.accountData.name,
        this.accountData.email,
        this.accountData.team,
      ].every((_f) => ![null, undefined, ""].includes(String(_f)?.trim()));
    },
    isPwdFormValid() {
      let fieldsAreFilled = [
        this.pwdResetData.current_password,
        this.pwdResetData.password,
        this.pwdResetData.password_confirmation,
      ].every((_f) => ![null, undefined, ""].includes(_f?.trim()));

      let pwdMatches =
        this.pwdResetData.password == this.pwdResetData.password_confirmation;

      let isDifferentPwd =
        this.pwdResetData.current_password != this.pwdResetData.password;

      // console.log(fieldsAreFilled, pwdMatches, isDifferentPwd);

      return fieldsAreFilled && pwdMatches && isDifferentPwd;
    },
  },
  created() {
    this.loadUser(null);
  },
  methods: {
    track() {
      this.$gtag.event("click_save-changes_account-settings", {
        event_category: "Onboarding flow",
        event_label: "click_save-changes_account-settings",
        value: 1,
      });
    },
    showPassword(id) {
      this.showingPwd[id] = !(this.showingPwd[id] ?? false);
    },
    toggleForm(state) {
      this.showForm = state;
    },
    stopPropagation(e) {
      e.stopPropagation();
    },
    discardChanges() {
      //console.log(this.accountData);
      this.accountData = {
        ...this.initialAccount,
        // , password: "********"
      };
      this.reloadForm++;
    },
    async saveChanges() {
      try {
        this.load(true);
        let res = await RestService.updateAccount(this.accountData);
        this.load(false);

        if (res != null) {
          this.loadUser(res);

          this.reloadForm++;
          this.notify("Changes saved");
        }
      } catch (error) {
        this.load(false);
        this.notify("Failed to save changes", { success: false });
      }
    },
    async changePassword() {
      try {
        this.load(true);
        let res = await RestService.changePassword(this.pwdResetData);
        this.load(false);

        // console.log(res);
        if (res !== null) {
          // this.loadUser(res);
          // this.accountData = {
          //   ...this.initialAccount.user,
          // };
          this.reloadForm++;
          this.toggleForm(false);
          this.notify("Password changed");
          this.pwdResetData = {};
        }
      } catch (error) {
        this.load(false);
        this.notify("Error: " + error?.errors?.full_messages?.[0], {
          success: false,
        });
      }
    },
    setFirstname(inputValue) {
      this.accountData.name = inputValue;
    },
    setEmail(inputValue) {
      this.accountData.email = inputValue;
    },
    onSelectTeam(id) {
      this.accountData.team = id;
    },
    setOldPwd(inputValue) {
      this.pwdResetData.current_password = inputValue;
    },
    setNewPwd(inputValue) {
      this.pwdResetData.password = inputValue;
    },
    setNewPwdConfirmation(inputValue) {
      this.pwdResetData.password_confirmation = inputValue;
    },
    loadUser(data) {
      let cache = data ?? JSON.parse(localStorage.getItem("user"));

      if (cache != null) {
        this.initialAccount = {
          ...cache,
        };

        this.accountData = {
          ...this.initialAccount,
          // , password: "********"
        };
      }
    },
  },
};
</script>

<style scoped>
label {
  font-size: 0.755rem;
}
.email-verified {
  font-size: 0.67rem;
  color: var(--sea) !important;
}
button {
  font-size: 0.98rem !important;
  font-weight: 500 !important;
}
.discard-btn {
  background-color: transparent !important;
  color: var(--bluey-grey) !important;
  border: none;
}
.discard-btn:hover {
  text-decoration: underline;
}

button:focus {
  box-shadow: none;
}

.form-btn {
  padding: 10px 60px !important;
  border-radius: 6px !important;
}

.disable {
  background-color: var(--bluey-grey-25) !important;
}

.disable div {
  color: var(--bluey-grey) !important;
}

.box {
  max-width: 40% !important ;
}
hr {
  border: 1px solid #d9dcde;
  margin: 15px 0;
}
</style>
