<template>
  <button
    class="
      px-0
      d-flex
      justify-content-between
      align-items-center
      gap-2
      lu-small-btn
    "
    :class="{ 'text-underlined': underLined }"
  >
    <img v-if="icon" :src="icon" alt="button icon" />
    <span>{{ label }}</span>
    <img v-if="endIcon" :src="endIcon" alt="button end icon" />
  </button>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    endIcon: {
      type: String,
      default: "",
    },
    underLined: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped lang="scss">
.lu-small-btn {
  background-color: transparent;
  border: none;
  color: var(--sea);

  &:hover {
    span {
      text-decoration-line: underline;
      color: var(--green);
    }
    img {
      filter: var(--f-green);
    }
  }
  img {
    width: 18px;
  }
  span {
    transition: var(--d-ease);
    color: var(--sea);
    font-size: 0.88rem;
    text-decoration-line: none;
  }
}
.text-underlined {
  span {
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
