<template>
  <div class="box">
    <div class="vertical-center d-flex align-items-center w-100">
      <div>
        <form id="set-company-details" @submit.prevent="handleSubmit">
          <div class="row justify-content-center align-items-center">
            <div class="col-12 mb-3">
              <AuthInput
                id="company-name"
                v-model="companyDetails.company_name"
                type="text"
                aria-label="company-name"
                label="Company name"
                name="company-name"
              />
            </div>

            <div class="col-12 mb-3">
              <AuthInput
                id="company-website"
                v-model="companyDetails.company_website"
                type="text"
                aria-label="company-website"
                label="Company website"
                name="company-website"
              />
            </div>
            <div class="mt-2 mb-4 col-12 auth-dp">
              <label class="form-label">Country</label>
              <FormDropdown
                :options="computedCountries"
                :border-less="true"
                :show-label="false"
                @onSelect="onSelectCountry"
              />
            </div>

            <div class="col-12 mt-4 mb-2">
              <FormButton
                class="py-3 col-12"
                label="Submit"
                from="set-company-details"
                :loading="loading"
              />
            </div>
            <div class="col-12 mt-4">
              <span
                class="login-route-link text-center d-block cp"
                @click="skipForm"
                >Skip</span
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import RestService from "@/services/RestService.js";
import FormDropdown from "@/components/FormDropdown.vue";
import { countries } from "@/data/options";
import AuthInput from "../helpers/AuthInput.vue";
import FormButton from "../helpers/FormButton.vue";

export default {
  components: { FormDropdown, AuthInput, FormButton },
  inject: ["notify"],
  data() {
    return {
      countries: countries,
      companyDetails: {
        company_name: "",
        company_website: "",
        country: "",
      },
    };
  },
  computed: {
    computedCountries() {
      //format countries to use them as options in Dropdown
      let countriesOptions = {};

      for (let key in this.countries) {
        countriesOptions[key] = {
          id: key,
          label: this.countries[key],
        };
      }
      return countriesOptions;
    },
  },
  methods: {
    onSelectCountry(country) {
      this.companyDetails.country = country;
    },
    async handleSubmit() {
      let details = { ...this.companyDetails };
      await this.submitDetails(details);
    },
    async submitDetails(details) {
      let res = await RestService.updateCompanyDetails(details);

      if (res.success) {
        this.$router.replace({
          name: "Overview",
        });
        this.notify("Welcome", {
          success: true,
        });
      } else {
        console.log(res.err);
        this.notify("Failed to update details", {
          success: false,
        });
      }
    },
    async skipForm() {
      console.log("Skip Form...");

      await this.submitDetails({
        company_name: "skiped_form",
        company_website: "skiped_form",
        country: "skiped_form",
      });
    },
  },
};
</script>

<style scoped>
.auth-dp label.form-label {
  color: var(--bluey-grey);
  font-size: 0.92rem;
}
.login-route-link {
  color: var(--bluey-grey);
}
.login-route-link:hover {
  text-decoration: underline;
  color: var(--sea);
}
</style>
