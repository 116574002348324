<template>
  <div>
    <div
      class="
        border-bottom
        mt-4
        pt-2
        pb-2
        d-flex
        align-items-center
        justify-content-between
      "
    >
      <div>
        <FilterMenu
          label="Name"
          :sort-is-active="sortOption == sortOptions.name"
          :reverse-sorting="sortOption == 'reverse-0'"
          @click="sortData(sortOptions.name)"
        />
      </div>

      <div class="d-flex gap-3">
        <div>
          <FilterMenu
            :label="metric === 'average' ? 'Average/User' : 'Total Spend'"
            :sort-is-active="sortOption == sortOptions.spend"
            :reverse-sorting="sortOption == 'reverse-1'"
            @click="sortData(sortOptions.spend)"
          />
        </div>
        <div>
          <FilterMenu
            label="Change"
            :sort-is-active="sortOption == sortOptions.change"
            :reverse-sorting="sortOption == 'reverse-2'"
            @click="sortData(sortOptions.change)"
          />
        </div>
      </div>
    </div>

    <div v-if="withSearchbar" class="form-group mt-4 mb-3">
      <IconTextInput
        id="search-input-brands"
        class="w-100 my-1"
        placeholder="Search for a brand"
        icon-class="pi-search"
        :value="searchQry"
        :loading="loading"
        @value-change="setSearchQry"
      />
    </div>

    <div class="mt-0 position-relative h-100">
      <table
        v-if="sortedList.length > 0 && !loading"
        :key="sortOption"
        class="w-100"
      >
        <tr
          v-for="entry in sortedList"
          :key="entry[0]"
          class="cp"
          :class="{ 'selected-item': itemIsSelected(entry[0]) }"
          @click="
            toggleItem(entry[0]);
            trackSelectedItem(entry);
          "
        >
          <td class="body-padding first-child">
            <LogoIcon
              :src="getIcon(entry)"
              :is-category-icon="title === 'Categories'"
              :is-brand-icon="title === 'Brands'"
              class="text-start"
              :class="[
                title === 'Categories'
                  ? 'view-all-cat-icon'
                  : 'view-all-brand-icon',
              ]"
            />
          </td>

          <td class="text-start eggplant-text w-100 ps-2">
            {{ getLabel(entry) }}
          </td>

          <td class="text-start eggplant-text">
            <span class="d-inline-block py-2 mx-3 my-1 text-nowrap">
              <small
                ><small>{{ currency }}</small></small
              >
              {{
                numberWithCommas(
                  Math.abs(Number(getSpendString(entry[1])).toFixed(0))
                ) ?? "NA"
              }}</span
            >
          </td>

          <td class="text-center body-padding last-child">
            <span
              class="d-inline-block percentage rounded fw-bold w-100 h-100"
              :class="{ negative: getRelPercentage(entry[1]) < 0 }"
            >
              {{ getDiff(entry[1]) }}%
            </span>
          </td>
        </tr>
      </table>

      <CardsEmptyState
        v-else-if="!loading && sortedList.length === 0"
        min-height="40"
      />
    </div>
  </div>
  <div class="">
    <div v-if="loading" class="loader px-auto py-auto">
      <span class="position-absolute top-50 start-50 translate-middle">
        <div class="spinner-border" role="status"></div>
      </span>
    </div>
  </div>
</template>

<script>
import { categories, periods } from "../data/options";
import CardsEmptyState from "./CardsEmptyState.vue";
import FilterMenu from "./FilterMenu.vue";
import IconTextInput from "./helpers/IconTextInput.vue";
import LogoIcon from "./LogoIcon.vue";

export default {
  components: { FilterMenu, LogoIcon, CardsEmptyState, IconTextInput },
  inject: ["getCategoryLabel"],
  props: {
    title: {
      type: String,
    },
    metric: {
      type: String,
      default: "average",
    },
    initialIndex: {
      default: 1,
    },
    withSearchbar: {
      type: Boolean,
    },
    data: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["dataChange", "periodChange", "search-brand"],
  data() {
    return {
      searchQry: "",
      categories: categories,
      periods: periods,
      isNameSelected: false,
      isSpendSelected: false,
      isChangeSelected: false,
      currentIndex: null,
      sortOption: 1,
      sortOptions: {
        name: 0,
        spend: 1,
        change: 2,
      },
    };
  },
  computed: {
    currency() {
      return this.$store.state.currency;
    },
    sortedList() {
      if (
        this.sortOption == "reverse-0" ||
        this.sortOption == "reverse-1" ||
        this.sortOption == "reverse-2"
      ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.entries.reverse();
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.entries.sort((a, b) => {
          let res = 0;
          if (this.title == "Categories") {
            switch (this.sortOption) {
              case this.sortOptions.name:
                // categories[b[0]] - categories[a[0]];
                if (
                  (categories[a[0]].label?.toLowerCase() ?? "") >
                  (categories[b[0]].label?.toLowerCase() ?? "")
                )
                  res = 1;
                if (
                  (categories[a[0]].label?.toLowerCase() ?? "") <
                  (categories[b[0]].label?.toLowerCase() ?? "")
                )
                  res = -1;

                break;

              case this.sortOptions.spend:
                res =
                  Math.abs(this.getSpend(b[1])) - Math.abs(this.getSpend(a[1]));
                break;

              case this.sortOptions.change:
                res = (this.getDiff(b[1]) ?? 0) - (this.getDiff(a[1]) ?? 0);
                break;

              default:
                if (
                  (categories[b[0]].label?.toLowerCase() ?? "") >
                  (categories[a[0]].label?.toLowerCase() ?? "")
                )
                  res = -1;
                if (
                  (categories[b[0]].label?.toLowerCase() ?? "") <
                  (categories[a[0]].label?.toLowerCase() ?? "")
                )
                  res = 1;
                break;
            }
          } else {
            switch (this.sortOption) {
              case this.sortOptions.name:
                // categories[b[0]] - categories[a[0]];
                if (b[1].name.toLowerCase() < a[1].name.toLowerCase()) res = -1;
                if (b[1].name.toLowerCase() > a[1].name.toLowerCase()) res = 1;
                break;
              case this.sortOptions.spend:
                res =
                  Math.abs(this.getSpend(b[1])) - Math.abs(this.getSpend(a[1]));
                break;
              case this.sortOptions.change:
                res = (this.getDiff(b[1]) ?? 0) - (this.getDiff(a[1]) ?? 0);
                break;
              default:
                if (b[1].name > a[1].name) res = -1;
                if (b[1].name < a[1].name) res = 1;
                break;
            }
          }

          return res;
        });

        return this.entries;
      }

      // .map((v) => v[1])
      // .slice(0, 4);
    },
    entries() {
      return Object.entries(this.data ?? {});
    },
    selectedItem() {
      return this.currentIndex ?? this.initialIndex ?? 1;
    },
  },
  watch: {
    initialIndex(newIndex) {
      this.currentIndex = newIndex;
    },
    searchQry(newQry) {
      this.$emit("search-brand", newQry);
    },
  },
  methods: {
    trackSelectedItem(item) {
      if (this.title === "Categories") {
        this.$gtag.event(
          `MAY23_click_from_categories_view_${this.getCategoryLabel(
            item[1]?.id
          )}`,
          {
            event_category: "Engagement",
            event_label: `MAY23_click_from_categories_view_${this.getCategoryLabel(
              item[1]?.id
            )}`,
            value: 1,
          }
        );
      } else {
        this.$gtag.event("MAY23_click_from_brands_view_selected_brand", {
          event_category: "Engagement",
          event_label: "MAY23_click_from_brands_view_selected_brand",
          selected_brand: item[1]?.name,
          value: 1,
        });
      }
    },
    setSearchQry(val) {
      this.searchQry = val;
    },
    toggleItem(index) {
      this.currentIndex = index;
      this.$emit("dataChange", this.currentIndex);
    },
    itemIsSelected(index) {
      return this.selectedItem == index;
    },
    sortData(option) {
      this.sortOption =
        this.sortOption == option ? `reverse-${option}` : option;
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getDiff(item) {
      const rel =
        this.metric === "average"
          ? item?.rel_percent_avg_spend
          : item?.rel_percent_total_spend;

      return rel;
    },
    getDelta(value) {
      value = (Math.round(value * 100) / 100).toFixed(0);

      if (value == 0) {
        return "+" + 0;
      }
      return (value < 0 ? "" : "+") + (value ?? "NA");
    },
    getSpend(entry) {
      let value;

      if (this.metric == "average") {
        value = entry?.tenant_customers_avg_spend ?? 0;
      } else {
        value = entry?.tenant_customers_total_spend ?? 0;
      }

      return value ?? 0;
    },
    getSpendString(entry) {
      return this.getDelta(this.getSpend(entry));
    },
    getRelPercentage(entry) {
      const rel =
        this.metric === "average"
          ? entry?.rel_percent_avg_spend
          : entry?.rel_percent_total_spend;
      return rel;
    },
    getIcon(entry) {
      return this.title === "Categories"
        ? this.categories[entry[0]].icon
        : `${process.env.VUE_APP_IMG_ORIGINAL_BASE_URL}${entry[1].logo}`;
    },
    getLabel(entry) {
      return this.title === "Categories"
        ? this.getCategoryLabel(entry[1]?.id) ?? "--"
        : entry[1]?.name ?? "--";
    },
  },
};
</script>

<style scoped>
td,
li,
ul,
span,
h2 {
  color: var(--eggplant) !important;
}
table {
  border-collapse: separate;
  border-spacing: 0 1em;
}
tr .first-child,
td .first-child {
  border-top-left-radius: 9px;
}
tr .last-child,
td .last-child {
  border-top-right-radius: 9px;
}
tr .first-child,
td .first-child {
  border-bottom-left-radius: 9px;
}
tr .last-child,
td .last-child {
  border-bottom-right-radius: 9px;
}

tr {
  background: white;
}

tr:hover {
  box-shadow: 0px 0px 0px 1px var(--sea);
  border-radius: 9px;
}
.selected-item {
  box-shadow: 0px 0px 0px 1px var(--sea);
  border-radius: 9px;
}
td {
  padding: 12px 0px;
  color: var(--eggplant) !important;
}
.body-padding {
  padding: 12px;
}
.percentage {
  background-color: var(--sea-10);
  color: var(--sea) !important;
  font-size: 0.8rem;
  padding: 5px 7px;
  border-radius: 7px;
}

.negative {
  background-color: rgba(223, 41, 53, 0.1);
  color: #df2935ec !important;
}
.sort-filter {
  background-color: transparent !important;
  color: var(--bluey-grey) !important;
  font-size: 0.9rem !important;
}
.search-input {
  background-color: white !important;
  padding: 8px !important;
  padding-left: 11px !important;
}

input::placeholder {
  color: var(--bluey-grey) !important;
}

.form-control:focus {
  box-shadow: none !important;
}
</style>
