import moment from "moment";

const countries = {
  AF: "Afghanistan",
  AX: "Åland Islands",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AG: "Antigua and Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AC: "Ascension Island",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  BA: "Bosnia and Herzegovina",
  BW: "Botswana",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  VG: "British Virgin Islands",
  BN: "Brunei",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  CV: "Cape Verde",
  BQ: "Caribbean Netherlands",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos [Keeling] Islands",
  CO: "Colombia",
  KM: "Comoros",
  CD: "Democratic Republic Congo",
  CG: "Republic of Congo",
  CK: "Cook Islands",
  CR: "Costa Rica",
  CI: "Côte d'Ivoire",
  HR: "Croatia",
  CU: "Cuba",
  CW: "Curaçao",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  TL: "East Timor",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  ET: "Ethiopia",
  FK: "Falkland Islands [Islas Malvinas]",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea Conakry",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island and McDonald Islands",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  XK: "Kosovo",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Laos",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macau",
  MK: "Macedonia",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar [Burma]",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  KP: "North Korea",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestinian Territories",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Romania",
  RU: "Russia",
  RW: "Rwanda",
  BL: "Saint Barthélemy",
  SH: "Saint Helena",
  KN: "St. Kitts",
  LC: "St. Lucia",
  MF: "Saint Martin",
  PM: "Saint Pierre and Miquelon",
  VC: "St. Vincent",
  WS: "Samoa",
  SM: "San Marino",
  ST: "São Tomé and Príncipe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SX: "Sint Maarten",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia and the South Sandwich Islands",
  KR: "South Korea",
  SS: "South Sudan",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Suriname",
  SJ: "Svalbard and Jan Mayen",
  SZ: "Swaziland",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syria",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania",
  TH: "Thailand",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad/Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks and Caicos Islands",
  TV: "Tuvalu",
  VI: "U.S. Virgin Islands",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  US: "United States",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VA: "Vatican City",
  VE: "Venezuela",
  VN: "Vietnam",
  WF: "Wallis and Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
  TF: "French Southern Territories",
};

// eslint-disable-next-line no-unused-vars
const locations = {
  AE_AZ: "Abu Dhabi",
  AE_FU: "Fujairah",
  AE_SH: "Sharjah",
  AE_DU: "Dubai",
  AE_RK: "Ras Al Khaimah",
  AE_UQ: "Umm Al Quwain",
  AE_AJ: "Ajman",
};

// eslint-disable-next-line no-unused-vars
const incomeGroups = {
  "5k": { min: 0, max: 5 },
  "10k": { min: 6, max: 10 },
  "15k": { min: 11, max: 15 },
  "20k": { min: 16, max: 20 },
  "30k": { min: 21, max: 30 },
  "50k": { min: 31, max: 50 },
  ">50k": { min: 51, max: "None" },
};

// eslint-disable-next-line no-unused-vars
const ageGroups = {
  tweens: { min: 10, max: 14 },
  teens: { min: 15, max: 19 },
  youth: { min: 20, max: 29 },
  young_adults: { min: 30, max: 39 },
  adults: { min: 40, max: 49 },
  elders: { min: 50, max: "None" },
};

// eslint-disable-next-line no-unused-vars
const genders = {
  male: "Male",
  female: "Female",
  others: "Others",
};

const segements = {
  gold: "Gold",
  silver: "Silver",
  bronze: "Bronze",
};

const account_categories = {
  islamic: "Islamic",
  commerical: "Commercial",
};

const metrics = {
  average: { id: "average", label: "Average spend" },
  total: { id: "total", label: "Total spend" },
};

const nudgeStatus = {
  active: { id: "active", label: "Active" },
  inactive: { id: "inactive", label: "Inactive" },
  both: { id: "both", label: "Active & Inactive" },
};

const roles = {
  owner: { id: "0", label: "Owner" },
  admin: { id: "1", label: "Admin" },
  viewer: { id: "2", label: "Viewer" },
};

const teams = {
  product: { id: 0, label: "Product" },
  sales: { id: 1, label: "Sales" },
  marketing: { id: 2, label: "Marketing" },
  executive: { id: 3, label: "Executive" },
  finance: { id: 4, label: "Finance" },
};

const nudges = {
  "budget-overspending": {
    id: "budget-overspending",
    label: "Budget overspending",
    description:
      "If spending reaches +100% of total monthly budget for 3 consecutive months",
    template:
      "Hi {Name}, you have been spending higher than usual in recent months.",
    active: true,
    enabled: true,
    tags: [
      {
        label: "PFM",
        color: "#469a93",
      },
      {
        label: "Engage",
        color: "#9b7ede",
      },
    ],
  },
  "staycation-offer": {
    id: "staycation-offer",
    label: "Staycation Offer",
    description:
      "If spend is detected with hotels within the UAE outside of their emirate of residence",
    template:
      "Hi {Name}, as a {tier} customer we offer special rates with x hotel, save more with us on your next staycation.",
    active: false,
    enabled: true,
    tags: [
      {
        label: "PFM",
        color: "#469a93",
      },
      {
        label: "Act",
        color: "#ffa17a",
      },
    ],
  },
  "set-budget-reminder": {
    id: "set-budget-reminder",
    label: "Set budget reminder",
    description: "If spending is higher than x",
    template:
      "Hi {Name}, as a {tier} customer we offer special rates with x hotel, save more with us on your next staycation.",
    active: true,
    enabled: false,
    tags: [
      {
        label: "PFM",
        color: "#469a93",
      },
      {
        label: "Engage",
        color: "#9b7ede",
      },
    ],
  },
  "budget-reward": {
    id: "budget-reward",
    label: "Budget Reward",
    description: "If budget is maintained for first months",
    template:
      "Hi {Name}, congratulations on sticking to your budget! We’ve got a surprise to reward you for your hardwork!",
    active: true,
    enabled: true,
    tags: [
      {
        label: "PFM",
        color: "#469a93",
      },
      {
        label: "Engage",
        color: "#9b7ede",
      },
    ],
  },
  "upcoming-bill": {
    id: "upcoming-bill",
    label: "Upcoming bill",
    description: "If there is an upcoming bill payment",
    template: "Hi {Name}, you have an upcoming bill payment.",
    active: true,
    enabled: true,
    tags: [
      {
        label: "PFM",
        color: "#469a93",
      },
      {
        label: "Engage",
        color: "#9b7ede",
      },
    ],
  },
  "double-payment": {
    id: "double-payment",
    label: "Double payment",
    description: "If 2 similar transactions are captured within 5 minutes",
    template:
      "Hi {Name}, you may have been double charged by {Vendor} at {time}",
    active: true,
    enabled: true,
    tags: [
      {
        label: "PFM",
        color: "#469a93",
      },
      {
        label: "Engage",
        color: "#9b7ede",
      },
    ],
  },
};

// eslint-disable-next-line no-unused-vars
const categories = {
  1: {
    id: "1",
    label: "Financial Services",
    quota: 0.015,
    icon: require("../assets/images/svg/new_categories/Financial_Services.svg"),
    iconPNG: require("../assets/images/svg/Categories/png/banking_services.png"),
  },
  2: {
    id: "2",
    label: "Charity & Gifts",
    quota: 0.025,
    icon: require("../assets/images/svg/new_categories/Charity&Gifts.svg"),
    iconPNG: require("../assets/images/svg/Categories/png/gifts_and_charity.png"),
  },
  3: {
    id: "3",
    label: "Education",
    quota: 0.02,
    icon: require("../assets/images/svg/new_categories/Education.svg"),
    iconPNG: require("../assets/images/svg/Categories/png/education.png"),
  },
  4: {
    id: "4",
    label: "Entertainment",
    quota: 0.0775,
    icon: require("../assets/images/svg/new_categories/Entertainment.svg"),
    iconPNG: require("../assets/images/svg/Categories/png/entertainment.png"),
  },
  5: {
    id: "5",
    label: "Government Services",
    quota: 0.05,
    icon: require("../assets/images/svg/new_categories/Government_services.svg"),
    iconPNG: require("../assets/images/svg/Categories/png/government_services.png"),
  },
  6: {
    id: "6",
    label: "Groceries",
    quota: 0.05,
    icon: require("../assets/images/svg/new_categories/Groceries.svg"),
    iconPNG: require("../assets/images/svg/Categories/png/groceries.png"),
  },
  7: {
    id: "7",
    label: "Wellness",
    quota: 0.045,
    icon: require("../assets/images/svg/new_categories/Wellness.svg"),
    iconPNG: require("../assets/images/svg/Categories/png/wellness.png"),
  },
  8: {
    id: "8",
    label: "Savings & Investments",
    quota: 0,
    icon: require("../assets/images/svg/new_categories/Savings_&_Investments.svg"),
    iconPNG: require("../assets/images/svg/Categories/png/savings_and_investments.png"),
  },
  9: {
    id: "9",
    label: "Household",
    quota: 0.3,
    icon: require("../assets/images/svg/new_categories/Household.svg"),
    iconPNG: require("../assets/images/svg/Categories/png/household.png"),
  },
  10: {
    id: "10",
    label: "Dining",
    quota: 0.1,
    icon: require("../assets/images/svg/new_categories/Dining.svg"),
    iconPNG: require("../assets/images/svg/Categories/png/dining.png"),
  },
  11: {
    id: "11",
    label: "Shopping",
    quota: 0.0625,
    icon: require("../assets/images/svg/new_categories/Shopping_Bag.svg"),
    iconPNG: require("../assets/images/svg/Categories/png/shopping.png"),
  },
  12: {
    id: "12",
    label: "Income",
    quota: 0,
    icon: require("../assets/images/svg/new_categories/Income.svg"),
    iconPNG: require("../assets/images/svg/Categories/png/income.png"),
  },
  13: {
    id: "13",
    label: "Transfer",
    quota: 0.0,
    icon: require("../assets/images/svg/new_categories/Transfers.svg"),
    iconPNG: require("../assets/images/svg/Categories/png/transfers.png"),
  },
  14: {
    id: "14",
    label: "Transportation",
    quota: 0.085,
    icon: require("../assets/images/svg/new_categories/Transportation.svg"),
    iconPNG: require("../assets/images/svg/Categories/png/transport.png"),
  },
  15: {
    id: "15",
    label: "Travel",
    quota: 0.0,
    icon: require("../assets/images/svg/new_categories/Travel.svg"),
    iconPNG: require("../assets/images/svg/Categories/png/travel.png"),
  },
  16: {
    id: "16",
    label: "Loans",
    quota: 0.1,
    icon: require("../assets/images/svg/new_categories/Loan.svg"),
    iconPNG: require("../assets/images/svg/Categories/png/loans.png"),
  },
  17: {
    id: "17",
    label: "Insurance",
    quota: 0.025,
    icon: require("../assets/images/svg/new_categories/Insurance.svg"),
    iconPNG: require("../assets/images/svg/Categories/png/insurance.png"),
  },
  18: {
    id: "18",
    label: "Services",
    quota: 0.025,
    icon: require("../assets/images/svg/new_categories/Services.svg"),
    iconPNG: require("../assets/images/svg/Categories/png/services.png"),
  },
  19: {
    id: "19",
    label: "Miscellaneous",
    quota: 0.025,
    icon: require("../assets/images/svg/new_categories/Miscellaneous.svg"),
    iconPNG: require("../assets/images/svg/Categories/png/miscellaneous.png"),
  },
};

// eslint-disable-next-line no-unused-vars
const profiles = {
  all_users: {
    id: "all_users",
    label: "All Users",
    description:
      "Users with the highest average balance and spend and usually shop with luxury brands.",
    filter: {
      age: [],
      incomes: [],
      nationality: [],
      genders: [],
      locations: [],
      days_filter: 30,
      profile: "all_users",
    },
    metrics: {
      users: 42069,
      delta: 25,
      quota: 1,
      avg_spend_delta: 7,
    },
  },

  high_net_worth: {
    id: "high_net_worth",
    label: "High net-worth",
    description:
      "Users with the highest average balance and spend and usually shop with luxury brands.",
    filter: {
      age: ["tweens", "youth"],
      incomes: ["20k", "30k"],
      nationalities: ["AE", "SA", "KW"],
      genders: ["Male", "Female"],
      locations: ["AE_AZ", "AE_FU"],
      days_filter: 30,
      profile: "high_net_worth",
    },
    metrics: {
      users: 500,
      delta: -15,
      quota: 1.75,
      avg_spend_delta: -10,
    },
  },

  travel_enthusiasts: {
    id: "travel_enthusiasts",
    label: "Travel Enthusiasts",
    description:
      "Users with the highest average balance and spend and usually shop with luxury brands.",
    filter: {
      age: [],
      incomes: ["50k"],
      nationality: ["AE", "SA", "KW"],
      genders: [],
      locations: ["AE_FU", "AE_AZ", "AE_AJ"],
      days_filter: 30,
      profile: "travel_enthusiasts",
    },
    metrics: {
      users: 750,
      delta: -75,
      quota: 0.95,
      avg_spend_delta: -45,
    },
  },
  luxury_shoppers: {
    id: "luxury_shoppers",
    label: "Luxury Shoppers",
    description:
      "Users with the highest average balance and spend and usually shop with luxury brands.",
    filter: {
      age: [],
      incomes: ["20k"],
      nationalities: ["AE", "SA", "KW"],
      genders: [],
      locations: ["AE_FU", "AE_AZ"],
      days_filter: 30,
      profile: "luxury_shoppers",
    },
    metrics: {
      users: 450,
      delta: -5,
      quota: 1.2,
      avg_spend_delta: -2,
    },
  },
  school_students: {
    id: "school_students",
    label: "School Students",
    description:
      "Users with the highest average balance and spend and usually shop with luxury brands.",
    filter: {
      age: ["adults", "young_adults", "youth"],
      incomes: ["20k", "10k", "5k", "15k"],
      nationality: [],
      genders: ["F", "X"],
      locations: ["AE_AZ", "AE_FU", "AE_SH"],
      days_filter: 30,
      profile: "school_students",
    },
    metrics: {
      users: 200,
      delta: 2,
      quota: 0.45,
      avg_spend_delta: 3,
    },
  },
};

function formateDate(date) {
  return moment(date).format("YYYY-MM-DD");
}
//Current Month Dates
function getCurrentMonthDates() {
  const date = new Date();

  const from = new Date(
    date.getFullYear(),
    date.getMonth(),
    1 //representing first day in current month
  );

  const to = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0 // 0 is used to go back to previous month last day
  );

  return {
    from: formateDate(from),
    to: formateDate(to),
  };
}

//Previous Month Dates
function getPreviousMonthDates() {
  const date = new Date();

  const from = new Date(date.getFullYear(), date.getMonth() - 1, 1);

  const to = new Date(date.getFullYear(), date.getMonth(), 0);

  return {
    from: formateDate(from),
    to: formateDate(to),
  };
}

//Current Year Dates
function getCurrentYearDates() {
  const date = new Date();

  const from = new Date(date.getFullYear(), 0, 1);

  const to = new Date(date.getFullYear() + 1, 0, 0);

  return {
    from: formateDate(from),
    to: formateDate(to),
  };
}

//Get Dates from number of last days
function datesFromLastDaysPeriod(lastDays = 1) {
  const dates = [...Array(lastDays)].map((_, i) => {
    const d = new Date(); //current date
    d.setDate(d.getDate() - i); //specific date "DD" [0(First date) to -1 (LastDate)]
    return d;
  });

  let from = formateDate(dates.at(-1)); //from date
  let to = formateDate(dates.at(0)); //today's date

  return { from, to };
}

const periods = {
  current_month: { label: "Current Month", days: getCurrentMonthDates() },
  previous_month: { label: "Previous Month", days: getPreviousMonthDates() },
  current_year: { label: "Current Year", days: getCurrentYearDates() },
  week: { label: "Last 7 Days", days: datesFromLastDaysPeriod(7) },
  month: { label: "Last 30 Days", days: datesFromLastDaysPeriod(30) },
  quarter: { label: "Last 3 Months", days: datesFromLastDaysPeriod(90) },
  half_year: { label: "Last 6 Months", days: datesFromLastDaysPeriod(180) },
  year: { label: "Last Year", days: datesFromLastDaysPeriod(365) },
  custom: { label: "Custom", days: null },
};

const routes = {
  analytics: {
    id: "analytics",
    name: "Analytics",
    children: {
      newOverview: {
        path: "/overview",
        id: "Overview",
        name: "Overview",
        label: "Overview",
        icon: require("../assets/images/svg/new_sidebar/Dashboard.svg"),
        showOnTrial: false,
      },
      demography: {
        path: "/demography",
        id: "demography",
        name: "Demographics",
        label: "Demographics",
        icon: require("../assets/images/svg/new_sidebar/Insights.svg"),
        showOnTrial: false,
      },
      trends: {
        path: "/trends",
        id: "trends",
        name: "Trends",
        label: "Trends",
        icon: require("../assets/images/svg/new_sidebar/Engagement.svg"),
        showOnTrial: false,
      },
    },
  },
  integration: {
    id: "integration",
    path: "/integration",
    name: "Integration",
    children: {
      transfers: {
        path: "/transfers",
        id: "transfers",
        name: "TransfersInsights",
        label: "Transfers",
        icon: require("../assets/images/svg/new_sidebar/Transfers.svg"),
        showOnTrial: false,
      },
      documentation: {
        id: "documentation",
        path: "/documentation",
        name: "Documentation",
        label: "Documentation",
        icon: require("../assets/images/svg/new_sidebar/API Documentation.svg"),
      },
    },
  },
  misc: {
    id: "misc",
    name: "Miscellaneous",
    children: {
      settings: {
        id: "settings",
        path: "/settings",
        name: "Settings",
        label: "Settings",
        icon: require("../assets/images/svg/new_sidebar/Settings.svg"),
        children: {
          account: {
            id: "account",
            path: "/settings/account",
            name: "Account",
            label: "Account",
            admin_only: false,
            rolesHasAccess: [1, 2, 3],
            description: "Change or update your profile details.",
            icon: require("../assets/images/svg/settings-account.svg"),
          },
          team: {
            id: "team",
            path: "/settings/team",
            name: "Team",
            label: "Team",
            admin_only: false,
            rolesHasAccess: [1, 2, 3],
            description: "Add or manage your team members.",
            icon: require("../assets/images/svg/team.svg"),
          },
        },
      },
    },
  },
};

const productTypes = {
  accounts: {
    id: "accounts",
    label: "Accounts",
    children: {
      current: {
        id: "current",
        label: "Current Account",
      },
      savings: {
        id: "savings",
        label: "Savings Account",
      },
      fixed: {
        id: "fixed",
        label: "Fixed Deposit Account",
      },
    },
  },
  credit_cards: {
    id: "credit_cards",
    label: "Credit Cards",
    children: {
      elite: {
        id: "elite",
        label: "Elite Card",
      },
      deluxe: {
        id: "deluxe",
        label: "Deluxe Card",
      },
      platinum: {
        id: "platinum",
        label: "Platinum Card",
      },
    },
  },
};

const documentTypes = {
  passport: "Passport",
  emirates_id: "Emirates ID",
  prood_of_address: "Proof of address",
  residence_visa: "Residence Visa",
  salary_certificate: "Salary certificate",
};

const nudge_types = {
  act: {
    id: "act",
    label: "Act",
    color: "#ffa17a",
  },
  engage: {
    id: "engage",
    label: "Engage",
    color: "#9b7ede",
  },
};

const nudge_categories = {
  pfm: {
    id: "pfm",
    label: "PFM",
    color: "#469a93",
  },
};

const logic_gates = {
  and: {
    id: "and",
    label: "AND",
  },
  or: {
    id: "or",
    label: "OR",
  },
};

const list_operators = {
  in: {
    id: "in",
    label: "IN",
  },
  "not-in": {
    id: "not-in",
    label: "NOT IN",
  },
};

const num_operators = {
  gt: {
    id: "gt",
    label: ">",
  },
  lt: {
    id: "lt",
    label: "<",
  },
  gte: {
    id: "gte",
    label: ">=",
  },
  lte: {
    id: "lte",
    label: "<=",
  },
  eq: {
    id: "eq",
    label: "=",
  },
  not: {
    id: "not",
    label: "!=",
  },
};

const nudge_fields = {
  spending: {
    id: "spending",
    label: "Spending",
    type: "numeric",
    unit: "د.إ",
  },
  "transaction-date": {
    id: "transaction-date",
    label: "Transaction Date",
    type: "list",
    options: { ...periods },
  },
  category: {
    id: "category",
    label: "Category",
    type: "list",
    options: { ...categories },
  },
};

const currencies = {
  AED: "AED",
  AFN: "AFN",
  ALL: "ALL",
  AMD: "AMD",
  ANG: "ANG",
  AOA: "AOA",
  ARS: "ARS",
  AUD: "AUD",
  AWG: "AWG",
  AZN: "AZN",
  BAM: "BAM",
  BBD: "BBD",
  BDT: "BDT",
  BGN: "BGN",
  BHD: "BHD",
  BIF: "BIF",
  BMD: "BMD",
  BND: "BND",
  BOB: "BOB",
  BRL: "BRL",
  BSD: "BSD",
  BTC: "BTC",
  BTN: "BTN",
  BWP: "BWP",
  BYN: "BYN",
  BZD: "BZD",
  CAD: "CAD",
  CDF: "CDF",
  CHF: "CHF",
  CLF: "CLF",
  CLP: "CLP",
  CNH: "CNH",
  CNY: "CNY",
  COP: "COP",
  CRC: "CRC",
  CUC: "CUC",
  CUP: "CUP",
  CVE: "CVE",
  CZK: "CZK",
  DJF: "DJF",
  DKK: "DKK",
  DOP: "DOP",
  DZD: "DZD",
  EGP: "EGP",
  ERN: "ERN",
  ETB: "ETB",
  EUR: "EUR",
  FJD: "FJD",
  FKP: "FKP",
  GBP: "GBP",
  GEL: "GEL",
  GGP: "GGP",
  GHS: "GHS",
  GIP: "GIP",
  GMD: "GMD",
  GNF: "GNF",
  GTQ: "GTQ",
  GYD: "GYD",
  HKD: "HKD",
  HNL: "HNL",
  HRK: "HRK",
  HTG: "HTG",
  HUF: "HUF",
  IDR: "IDR",
  ILS: "ILS",
  IMP: "IMP",
  INR: "INR",
  IQD: "IQD",
  IRR: "IRR",
  ISK: "ISK",
  JEP: "JEP",
  JMD: "JMD",
  JOD: "JOD",
  JPY: "JPY",
  KES: "KES",
  KGS: "KGS",
  KHR: "KHR",
  KMF: "KMF",
  KPW: "KPW",
  KRW: "KRW",
  KWD: "KWD",
  KYD: "KYD",
  KZT: "KZT",
  LAK: "LAK",
  LBP: "LBP",
  LKR: "LKR",
  LRD: "LRD",
  LSL: "LSL",
  LYD: "LYD",
  MAD: "MAD",
  MDL: "MDL",
  MGA: "MGA",
  MKD: "MKD",
  MMK: "MMK",
  MNT: "MNT",
  MOP: "MOP",
  MRU: "MRU",
  MUR: "MUR",
  MVR: "MVR",
  MWK: "MWK",
  MXN: "MXN",
  MYR: "MYR",
  MZN: "MZN",
  NAD: "NAD",
  NGN: "NGN",
  NIO: "NIO",
  NOK: "NOK",
  NPR: "NPR",
  NZD: "NZD",
  OMR: "OMR",
  PAB: "PAB",
  PEN: "PEN",
  PGK: "PGK",
  PHP: "PHP",
  PKR: "PKR",
  PLN: "PLN",
  PYG: "PYG",
  QAR: "QAR",
  RON: "RON",
  RSD: "RSD",
  RUB: "RUB",
  RWF: "RWF",
  SAR: "SAR",
  SBD: "SBD",
  SCR: "SCR",
  SDG: "SDG",
  SEK: "SEK",
  SGD: "SGD",
  SHP: "SHP",
  SLL: "SLL",
  SOS: "SOS",
  SRD: "SRD",
  SSP: "SSP",
  STD: "STD",
  STN: "STN",
  SVC: "SVC",
  SYP: "SYP",
  SZL: "SZL",
  THB: "THB",
  TJS: "TJS",
  TMT: "TMT",
  TND: "TND",
  TOP: "TOP",
  TRY: "TRY",
  TTD: "TTD",
  TWD: "TWD",
  TZS: "TZS",
  UAH: "UAH",
  UGX: "UGX",
  USD: "USD",
  UYU: "UYU",
  UZS: "UZS",
  VEF: "VEF",
  VES: "VES",
  VND: "VND",
  VUV: "VUV",
  WST: "WST",
  XAF: "XAF",
  XAG: "XAG",
  XAU: "XAU",
  XCD: "XCD",
  XDR: "XDR",
  XOF: "XOF",
  XPD: "XPD",
  XPF: "XPF",
  XPT: "XPT",
  YER: "YER",
  ZAR: "ZAR",
  ZMW: "ZMW",
  ZWL: "ZWL",
};

const reportTxOptions = [
  {
    key: "wrong_brand",
    value: "Wrong Brand",
  },
  {
    key: "wrong_category",
    value: "Wrong Category",
  },
  {
    key: "wrong_logo",
    value: "Wrong Logo",
  },
];

const feedbackStatusOptions = [
  { key: "reported,updated,closed", value: "All Reported" },
  { key: "reported", value: "Reported" },
  { key: "updated", value: "Updated" },
  { key: "closed", value: "Closed" },
];

export {
  countries,
  genders,
  locations,
  categories,
  profiles,
  incomeGroups,
  segements,
  account_categories,
  ageGroups,
  periods,
  routes,
  teams,
  roles,
  productTypes,
  documentTypes,
  metrics,
  nudgeStatus,
  nudges,
  nudge_types,
  nudge_categories,
  list_operators,
  num_operators,
  nudge_fields,
  logic_gates,
  currencies,
  reportTxOptions,
  feedbackStatusOptions,
};
