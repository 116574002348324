<template>
  <form :id="id + '_range_input_form'">
    <div class="d-flex flex-column gap-3 w-100">
      <TextInput
        :id="id + '_min_filter'"
        name="min_filter_range"
        :value="minVal"
        :label="'Min ' + label"
        type="text"
        class="filter-input"
        :is-only-number="true"
        @value-change="setMin"
      />
      <TextInput
        :id="id + '_max_filter'"
        name="max_filter_range"
        :value="maxVal"
        :label="'Max ' + label"
        type="text"
        class="filter-input"
        :is-only-number="true"
        @value-change="setMax"
      />
    </div>
  </form>
</template>
<script>
import TextInput from "../helpers/TextInput";
import { object, number, ref as yupRef } from "yup";
import { useForm } from "vee-validate";

export default {
  components: { TextInput },
  props: {
    id: {
      type: String,
      require: true,
      default: "",
    },
    label: {
      type: String,
      require: true,
      default: "",
    },
    rangeValues: {
      type: Array,
      default: () => [],
    },
    resetRange: {
      type: Number,
      default: 0,
    },
  },
  emits: ["range-change"],
  setup() {
    const validationSchema = object({
      min_filter_range: number("Please enter valid number")
        .integer("Min should be an integer")
        .transform((value) => (isNaN(value) ? null : value))
        .nullable(),
      max_filter_range: number("Please enter valid number")
        .integer("Min should be an integer")
        .transform((value) => (isNaN(value) ? null : value))
        .nullable()
        .min(
          yupRef("min_filter_range"),
          "Max must be greater than or equal to 'Min'"
        ),
    });

    useForm({
      validationSchema,
    });
  },
  data() {
    return {
      minVal: null,
      maxVal: null,
    };
  },
  computed: {
    range() {
      let range = [];

      if (this.minVal || this.maxVal) {
        range.push({
          ...(this.minVal && { min: this.minVal }),
          ...(this.maxVal && { max: this.maxVal }),
        });
      }
      return range;
    },
  },
  watch: {
    resetRange() {
      //reset inputs on clear clicked
      this.minVal = null;
      this.maxVal = null;
    },
    range(newVal) {
      this.$emit("range-change", newVal);
    },
  },
  mounted() {
    if (this.rangeValues.length !== 0) {
      this.minVal = this.rangeValues[0]?.min ?? null;
      this.maxVal = this.rangeValues[0]?.max ?? null;
    }
  },
  methods: {
    setMin(minVal) {
      let min = parseInt(Math.round(minVal));
      let max = parseInt(Math.round(this.maxVal ?? 0));
      min = isNaN(min) ? 0 : min;

      //[1] if max is empty
      if (max === 0 && min > 0) {
        this.minVal = min;
        return;
      } else if (min === 0) {
        this.minVal = null;
        return;
      }

      //[2] if min less than max else reset min to null
      if (min <= max) {
        this.minVal = min;
      } else {
        this.minVal = null;
      }
      return;
    },
    setMax(maxVal) {
      let min = parseInt(Math.round(this.minVal ?? 0));
      let max = parseInt(Math.round(maxVal ?? 0));
      max = isNaN(max) ? 0 : max;

      //[1] if only max or empty
      if (min === 0 && max > 0) {
        this.maxVal = max;
        return;
      } else if (max === 0) {
        this.maxVal = null;
        return;
      }

      // //[2] if max less than min (reset) else set max
      if (max < min) {
        this.maxVal = null;
      } else {
        this.maxVal = max;
      }
      return;
    },
  },
};
</script>
<style>
.filter-input input {
  font-size: var(--fs-input) !important;
  color: var(--green) !important;
  font-weight: 400 !important;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}
</style>
