<template>
  <div class="d-flex flex-column h-100">
    <div class="p-2 mb-3 d-flex">
      <h2 class="header m-0 me-auto">
        <small><small>Team Members</small></small>
      </h2>
      <span
        class="
          d-flex
          flex-row
          align-items-center
          justify-content-center
          card
          px-3
          py-2
          cp
          border
        "
        @click="displayForm"
        ><span>Add team member</span>
        <span><i class="bi bi-plus ms-2"></i></span
      ></span>
    </div>

    <div class="d-flex flex-column h-100 justify-content-start overflow-auto">
      <div class="p-2 mb-3 mt-3 position-relative">
        <MembersTable
          v-if="!loadingMembers"
          :members="membersList"
          @stageMemberForEdit="stageMemberForEdit"
          @deleteMember="deleteMember"
          @sendPasswordResetEmail="sendPasswordResetEmail"
        />

        <div v-else>
          <div class="loader px-auto py-auto my-5 py-3 bg-transparent">
            <span class="position-absolute top-50 start-50 translate-middle">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="modalIsActive"
      class="overlay fixed-top w-100 h-100 d-flex justify-content-center"
    >
      <div
        class="mx-auto my-auto h-75 w-50 card p-5 d-flex flex-column"
        @click="stopPropagation"
      >
        <h4 class="mb-3">{{ editMode ? "Edit" : "Invite" }} a team member</h4>
        <form class="flex-grow-1" @submit.prevent="submitForm">
          <div class="d-flex flex-column justify-content-between h-100">
            <div class="flex-grow-1 d-flex flex-column justify-content-around">
              <div class="w-100 h-100 py-3">
                <div class="d-flex flex-row justify-content-between my-4">
                  <div class="w-100">
                    <InputField
                      id="name"
                      label="Name"
                      type="text"
                      :input-value="editMode ? activeUser?.name : ''"
                      @updateInputValue="setFirstname"
                    />
                  </div>
                </div>
                <div class="my-4">
                  <InputField
                    label="Email"
                    type="email"
                    :input-value="editMode ? activeUser?.email : ''"
                    :is-disabled="false"
                    :start-icon="
                      require('../assets/images/svg/ic-contact-mail.svg')
                    "
                    @updateInputValue="setEmail"
                  />
                  <!-- :endIcon="
                      require('../assets/images/svg/ic-actions-check.svg')
                    " -->
                </div>
                <div class="d-flex flex-row justify-content-between my-4">
                  <div v-if="!teamsLoading" class="w-45">
                    <FormDropdown
                      id="team-dropdown"
                      label="Team"
                      :force-close="true"
                      :disabled="false"
                      :selection="selectedTeam"
                      :options="teamList"
                      @onSelect="onSelectTeam"
                    />
                  </div>
                  <div v-if="!roleLoading" class="w-45">
                    <FormDropdown
                      id="role-dropdown"
                      label="Role"
                      :force-close="true"
                      :disabled="false"
                      :selection="selectedRole"
                      :options="roleList"
                      @onSelect="onSelectRole"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <button
                class="ms-auto btn btn-light"
                @click="
                  toggleModal(false);
                  activeUser = {};
                "
              >
                Cancel
              </button>
              <button :disabled="!isFormValid" class="ms-3 btn btn-primary">
                {{ editMode ? "Save Changes" : "Send Invitation" }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import FormDropdown from "../components/FormDropdown.vue";
import MembersTable from "../components/MembersTable.vue";
import InputField from "../components/InputField.vue";
import { roles, teams } from "../data/options";
import RestService from "@/services/RestService.js";

export default {
  components: {
    FormDropdown,
    InputField,
    MembersTable,
  },
  inject: ["notify", "load"],
  data() {
    return {
      contextKey: 1,
      modalIsActive: false,
      editMode: false,
      teams: teams,
      roles: roles,
      activeUser: {},
      columns: [
        {
          label: "Name",
          field: "name",
          sortable: false,
          thClass: "text-start fw-normal text-muted",
        },

        {
          label: "Date added",
          field: "date_joined",
          // type: "date",
          // dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS",//date_joined
          // dateOutputFormat: "MMM d, yyyy",
          thClass: "text-start fw-normal text-muted",
          tdClass: "text-start",
          sortable: false,
        },
        {
          label: "Team Role",
          field: "team",
          thClass: "text-start fw-normal text-muted",
          tdClass: "text-start",
          sortable: false,
        },
        {
          label: "Actions",
          field: "actions",
          type: "percentage",
          thClass: "text-center fw-normal text-muted",
          tdClass: "text-center",
          sortable: false,
        },
      ],
      membersList: [],
      loadingMembers: false,
    };
  },
  computed: {
    selectedTeam() {
      return this.teamList[this.activeUser?.team];
    },
    teamList() {
      return this.$store.state.settingsStore.teams;
    },
    teamsLoading() {
      return this.$store.state.settingsStore.teamsLoading;
    },
    selectedRole() {
      return this.roleList[this.activeUser?.role];
    },
    roleList() {
      return this.$store.state.settingsStore.roles;
    },
    roleLoading() {
      return this.$store.state.settingsStore.rolesLoading;
    },
    isFormValid() {
      return [
        this.activeUser.name,
        this.activeUser.email,
        this.activeUser.role,
        this.activeUser.team,
      ].every((_f) => !["null", "undefined", ""].includes(String(_f)?.trim()));
    },
  },

  created() {
    this.getMembers();
  },

  methods: {
    toggleModal(state) {
      this.modalIsActive = state == null ? !this.modalIsActive : state;

      if (!this.modalIsActive) {
        this.editMode = false;
      }
    },
    displayForm() {
      this.toggleModal(true);
    },
    editMember() {
      let payload = {
        name: this.activeUser.name,
        email: this.activeUser.email,
        role: this.activeUser.role,
        team: this.activeUser.team,
      };

      this.load(true);
      RestService.updateMembers(this.activeUser.id, payload)
        .then(() => {
          this.getMembers();
          this.editMode = false;
          this.notify("Team member details updated successfully");
        })
        .catch(() => {
          this.notify("Failed to Save Changes", { success: false });
        })
        .finally(() => {
          this.activeUser = {};
          this.load(false);
          this.toggleModal(false);
        });
    },
    sendInvitation() {
      this.load(true);

      let payload = {
        name: this.activeUser.name,
        email: this.activeUser.email,
        role: this.activeUser.role ? parseInt(this.activeUser.role) : null,
        team: this.activeUser.team,
      };

      RestService.inviteUser(payload)
        .then(() => {
          this.getMembers().then(() => {
            this.toggleModal(false);
            this.notify("Invitation sent");
          });
        })
        .catch(() => {
          this.notify("Failed to invite user", { success: false });
        })
        .finally(() => {
          this.activeUser = {};
          this.load(false);
          this.toggleModal(false);
        });
    },
    submitForm() {
      let isNewMember = [null, undefined, ""].includes(this.activeUser.id);
      return isNewMember ? this.sendInvitation() : this.editMember();
    },

    stopPropagation(e) {
      e.stopPropagation();
    },
    async getMembers() {
      this.loadingMembers = true;
      try {
        let res = await RestService.getMembers();
        this.membersList = res.data?.users ?? [];

        this.loadingMembers = false;
      } catch (error) {
        console.log("Failed to fetch team: ", error);
        this.membersList = [];
        this.loadingMembers = false;
      }
    },
    stageMemberForEdit(user) {
      this.contextKey++;
      this.editMode = true;

      this.activeUser = { ...user };
      this.displayForm();
    },
    deleteMember(id) {
      this.contextKey++;
      this.load(true);
      RestService.deleteUser(id)
        .then(() => {
          this.getMembers();
          this.load(false);
          this.notify("User has been deleted");
        })
        .catch((error) => {
          this.load(false);
          this.notify(error?.error, { success: false });
        });
    },
    sendPasswordResetEmail(email) {
      this.contextKey++;
      this.load(true);
      RestService.sendPasswordResetEmail(email)
        .then(() => {
          this.load(false);
          this.notify("Password reset email sent");
        })
        .catch(() => {
          this.load(false);
          this.notify("Failed to send password reset email", {
            success: false,
          });
        });
    },
    setFirstname(inputValue) {
      this.activeUser.name = inputValue;
    },
    // setLastname(inputValue) {
    //   this.activeUser.last_name = inputValue;
    // },
    setEmail(inputValue) {
      this.activeUser.email = inputValue;
    },
    onSelectTeam(id) {
      this.activeUser.team = id;
    },
    onSelectRole(role) {
      this.activeUser.role = role;
    },
  },
};
</script>

<style>
.overlay {
  background-color: var(--bluey-grey-50) !important;
}
.dropdown {
  border: 1px solid var(--bluey-grey) !important;
}
.dropdown-item:hover {
  color: #151515 !important;
}
</style>
