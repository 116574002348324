<template>
  <div
    class="
      h-100
      w-100
      pb-4
      px-4
      hide-scrollbar
      mt-3
      d-flex
      justify-content-between
      align-items-start
      gap-5
    "
  >
    <div class="w-20 d-flex flex-column">
      <div v-for="route in subRoutes" :key="route.id" class="d-flex">
        <router-link
          id="setting-link"
          :to="{ name: route.label }"
          class="w-100"
        >
          <div
            class="
              p-2
              py-4
              sub-route-tile
              d-flex
              flex-row
              align-items-stretch
              cp
            "
            :class="{ 'sub-route-active': selectedRoute == route.label }"
          >
            <div class="d-flex justify-content-center align-items-center">
              <div class="px-auto rounded me-3">
                <img
                  :src="route.icon"
                  alt="none"
                  class="settings-icon align-self-center"
                />
              </div>
            </div>

            <div class="d-flex align-items-center flex-grow-1">
              <h5 class="p-0 m-0">
                <small>{{ route.label }}</small>
              </h5>
            </div>

            <div class="d-flex align-items-center sub-route-chevron ms-3">
              <h5 class="m-0 p-0"><i class="bi bi-chevron-right"></i></h5>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <router-view class="flex-grow-1" />
  </div>
</template>

<script>
import { routes } from "../data/options";

export default {
  data: function () {
    return {
      initialParent: null,
      isAdmin: false,
    };
  },
  computed: {
    selectedRoute() {
      return this.$route.name;
    },
    user() {
      return JSON.parse(localStorage.getItem("user")) ?? {};
    },
    subRoutes() {
      return Object.values(routes["misc"].children["settings"].children);
      // .filter(
      //   (_r) => _r.rolesHasAccess.includes(this.user?.role ?? 3  ) //|| this.isAdmin
      // );
    },
  },
  created() {
    //[1] fetch teams and roles
    this.$store.dispatch("getTeamsList");
    this.$store.dispatch("getRolesList");

    this.$router.push({ name: "Account" });

    this.isAdmin =
      JSON.parse(localStorage.getItem("user"))["is_dashboard_superuser"] ??
      false;
  },
};
</script>

<style scoped>
.router-link-active {
  border-bottom: unset;
  padding-bottom: unset;
}
</style>

<style>
.settings-icon {
  width: 20px;
  height: 20px;
}

.sub-route-tile:hover,
.sub-route-active {
  color: #151515;
}

.sub-route-active .route-icon,
.sub-route-tile:hover .route-icon {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(49deg)
    brightness(103%) contrast(103%) !important;
}

.sub-route-active .icon-box,
.sub-route-tile:hover .icon-box {
  background-color: var(--sea);
}

.sub-route-active .sub-route-chevron,
.sub-route-tile:hover .sub-route-chevron {
  color: #151515 !important;
}
.sub-route-tile {
  /* font-size: 28px; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1515158a;
}
.sub-route-active .settings-icon,
#setting-link:hover .settings-icon {
  opacity: 1 !important;
}
</style>
