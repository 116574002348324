<template>
  <div>
    <div
      :id="id"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
      @click="onClose"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div :id="id" class="modal-content py-3">
          <!--Title-->
          <div class="modal-header border-0 d-flex align-items-start">
            <h4 class="modal-title px-4 text-truncate">Brands</h4>
            <!-- {{ $store.state.overViewStore.brandsFilterCurrentState }} -->
            <button
              type="button"
              class="btn p-0 m-0 ps-2 pb-1 px-4"
              data-bs-dismiss="modal"
              @click="onModalClose"
            >
              <i class="pi pi-times"></i>
            </button>
          </div>
          <!--Title-->

          <!--Body-->
          <div class="modal-body border-0 py-0 text-center">
            <div
              class="
                w-100
                d-flex
                gap-3
                flex-column
                align-items-start
                justify-content-start
                text-start
              "
            >
              <PopUpFilterTopBar
                :count="newSelectedItems.length ?? 0"
                label="Brand"
                class="px-4"
                @clear="clearAllSelections"
              />
              <PopUpFilterSelectionsBar
                v-if="newSelectedItems.length !== 0"
                :selected-objects="selectedObjects"
                class="px-4"
                @remove-item="removeItem"
              />
              <PopUpFilterSearchBar
                id="brand-pu-filter-search-field"
                class="px-4"
                @search="search"
              />
              <div v-if="!loadingOptions" class="w-100">
                <BrandsPopUpFilterItemsScroll
                  v-if="(options?.brands ?? []).length !== 0"
                  :clear-key="clearKey"
                  class="px-4"
                  :options="options?.brands ?? []"
                  :count="options?.pagination?.total_entries ?? 0"
                  label="Brand"
                  :removed-item="removedItem"
                  @clear-all="clearAllSelectionsWithDefault"
                />
                <div
                  v-if="
                    'brands' in options && (options?.brands ?? []).length === 0
                  "
                  class="px-4 w-100 text-center py-4"
                >
                  <h6 class="opacity-50">Sorry we couldn't find any results</h6>
                </div>
              </div>

              <BaseLoadingCard
                v-else
                min-height="23vh"
                icon-size="2rem"
                class="px-4 w-100"
              />
            </div>
          </div>
          <!--Body-->

          <!--Footer-->
          <div
            class="
              modal-footer
              border-0
              p-3
              d-flex
              justify-content-between
              align-items-center
              gap-1
              px-4
              mt-2
            "
          >
            <div class="ps-2">
              <Paginator
                v-if="!totalRecordsLoading && totalRecords !== 0"
                box-padding="0rem"
                :total-records="totalRecords"
                :rows="50"
                @page="changePage"
              />
            </div>

            <ActionButton
              type="button"
              label="Show results"
              class="btn-primary filter-btn"
              @click="apply"
            />
          </div>

          <!-- <div>selectedObjects: {{ selectedObjects }}</div>
          <div>brandsFilter: {{ brandsFilter }}</div>
          <div>
            overViewStore.selectedBrandsFilter:
            {{ $store.state.overViewStore.selectedBrandsFilter }}
          </div> -->
          <!--Footer-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrandsPopUpFilterItemsScroll from "./BrandsPopUpFilterItemsScroll.vue";
import Paginator from "./Paginator.vue";
import BaseLoadingCard from "./helpers/BaseLoadingCard.vue";
import PopUpFilterSearchBar from "./PopUpFilterSearchBar.vue";
import PopUpFilterSelectionsBar from "./PopUpFilterSelectionsBar.vue";
import PopUpFilterTopBar from "./PopUpFilterTopBar.vue";
import { Modal } from "bootstrap";
import ActionButton from "@/components/helpers/ActionButton.vue";
import OverviewAPI from "@/services/api/OverviewAPI";

export default {
  components: {
    ActionButton,
    PopUpFilterTopBar,
    PopUpFilterSelectionsBar,
    PopUpFilterSearchBar,
    BaseLoadingCard,
    Paginator,
    BrandsPopUpFilterItemsScroll,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
  },
  emits: ["closeModal"],
  data() {
    return {
      myModal: null,
      // newSelectedItems: [],
      removedItem: {},
      clearKey: 0,
      searchQry: "",
      options: {},
      loadingOptions: false,
      selectedPage: 1,
      totalRecords: 0,
      totalRecordsLoading: true,
    };
  },
  computed: {
    selectedObjects() {
      return this.$store.state.overViewStore.brandsFilterCurrentState ?? [];
    },
    newSelectedItems() {
      return this.$store.state.overViewStore.brandsFilterCurrentState ?? [];
    },
    brandsFilter() {
      return this.$store.getters.brandsFilter;
    },
  },
  watch: {
    showModal: {
      handler(showForm) {
        if (showForm) {
          this.$store.commit(
            "updateBrandsFilterCurrentState",
            this.$store.state.overViewStore.selectedBrandsFilter
          );

          // this.newSelectedItems = [
          //   ...this.$store.state.overViewStore.selectedBrandsFilter,
          // ];

          this.myModal.show();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.myModal = new Modal(document.getElementById(this.id), {
      // backdrop: "static",
      keyboard: false,
    });
    // this.myModal.show();
  },
  methods: {
    changePage(pageObj) {
      //[1] abort previous requests
      this.$store.dispatch("CANCEL_PENDING_REQUESTS");

      //[2] set new page
      this.selectedPage = pageObj?.page + 1;

      //[3] fetch new
      this.fetchItems();
    },
    fetchItems() {
      this.$store.dispatch("CANCEL_PENDING_REQUESTS");
      this.loadingOptions = true;
      this.options = {};
      OverviewAPI.getBrandsList({
        ...(this.searchQry && { search: this.searchQry }),
        page: this.selectedPage ?? 1,
        per_page: 50,
      })
        .then((res) => {
          if (res?.data) this.options = res?.data ?? {};
          else this.options = {};
          this.totalRecords = res.data?.pagination?.total_entries ?? 0;
          this.totalRecordsLoading = false;
          this.loadingOptions = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            this.options = {};
            this.loadingOptions = false;
            console.error("error: ", error);
          }
        });
    },
    removeItem(item) {
      if (item?.id) {
        //filter out the removed item
        this.removedItem = this.newSelectedItems.find(
          (selectedItem) => selectedItem.id === item?.id
        );

        //reove an item
        const removedItemList = this.newSelectedItems.filter(
          (selectedItem) => selectedItem.id !== item?.id
        );
        this.$store.commit("updateBrandsFilterCurrentState", removedItemList);
      }
    },
    clearAllSelections() {
      this.clearKey += 1;
      // this.newSelectedItems = [];
      this.$store.commit("updateBrandsFilterCurrentState", []);
    },
    clearAllSelectionsWithDefault() {
      // this.newSelectedItems = [];
      this.$store.commit("updateBrandsFilterCurrentState", []);
    },
    search(qry) {
      //[1] update search qry
      this.searchQry = qry;

      //[2] reset paginator
      this.selectedPage = 1;
      this.totalRecords = 0;
      this.totalRecordsLoading = true;

      //[3] fetch
      this.fetchItems();
    },
    onClose(event) {
      if (event.target.id === this.id) {
        this.$emit("closeModal");
        this.$store.dispatch("CANCEL_PENDING_REQUESTS");
        this.loadingOptions = false;
        // this.newSelectedItems = [];
        this.$store.commit("updateBrandsFilterCurrentState", []);
        this.clearKey += 1;
      }
    },
    onModalClose() {
      // this.newSelectedItems = [];
      this.$store.commit("updateBrandsFilterCurrentState", []);
      this.clearKey += 1;
      this.$emit("closeModal");
      this.$store.dispatch("CANCEL_PENDING_REQUESTS");
      this.loadingOptions = false;
    },
    apply() {
      this.$store.commit("updateSelectedBrandsFilter", this.newSelectedItems);
      this.myModal.hide();
      this.$store.dispatch("CANCEL_PENDING_REQUESTS");
      this.loadingOptions = false;
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped>
.filter-btn {
  font-size: 0.93rem !important;
  padding: 0.9rem 1.5rem !important;
}
h4 {
  font-size: 1.6rem !important;
}
.modal-title {
  font-weight: 500;
  font-size: 1.85rem;
}
.modal-dialog {
  width: 740px;
  max-width: 740px;
}
.modal-content {
  border-radius: 20px;
  box-shadow: 0px 4px 40px 0px rgba(204, 213, 212, 0.3);
}
.modal-dialog .pi.pi-times {
  font-size: 1rem !important;
  opacity: 0.5 !important;
  transition: all 0.4s ease;
}
.modal-dialog .pi.pi-times:hover {
  opacity: 1 !important;
}
.btn {
  padding: 0.7rem 2.5rem;
}
</style>
