<template>
  <div class="row no-select">
    <div class="col">
      <div>
        <div
          class="card-body d-flex justify-content-between align-items-center"
        >
          <h4 class="card-title">Customers</h4>
          <InfoPopover
            data-html2canvas-ignore
            theme="white"
            title="Customers"
            :content="
              metric == 'average'
                ? `Based on the time period chosen, the average spending of all customers is displayed`
                : `Based on the time period chosen, the total spending of all customers is displayed`
            "
          />
        </div>

        <div class="card-body pt-4">
          <h5 class="h5 text-start">Total Customers</h5>
          <div class="d-flex align-items-center justify-content-between">
            <h2>
              {{ compactNumber(Math.abs(data.tenant_customers_count ?? 0)) }}
            </h2>

            <h6
              :class="{
                'negative-green-card': !customerCountDiff.diffSign,
              }"
            >
              {{ customerCountDiff?.diffValue }}%
            </h6>
          </div>
        </div>

        <div class="card-body pt-2">
          <h5 class="h5 text-start">
            {{ metric === "average" ? "Avg. Daily Spend" : "Total Spend" }}
          </h5>
          <div class="d-flex align-items-center justify-content-between">
            <h2>
              {{ currency }}
              {{
                compactNumber(
                  Math.abs(
                    (metric === "average"
                      ? data.tenant_customers_avg_daily_spend
                      : data.tenant_customers_total_spend) ?? 0
                  ),
                  2
                )
              }}
            </h2>

            <h6
              :class="{
                'negative-green-card': !customerSpendDiff.diffSign,
              }"
            >
              {{ customerSpendDiff.diffValue }}%
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoPopover from "../components/InfoPopover.vue";

export default {
  components: {
    InfoPopover,
  },
  inject: ["compactNumber"],
  props: {
    metric: {
      type: String,
      default: "average",
    },
    data: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currency() {
      return this.$store.state.currency;
    },
    customerCountDiff() {
      return this.useDiffCalculator(
        this.data?.tenant_customers_count ?? 0,
        this.data?.comparison_period_tenant_customers_count ?? 0
      );
    },
    customerSpendDiff() {
      if (this.metric === "average")
        return this.useDiffCalculator(
          this.data?.tenant_customers_avg_daily_spend ?? 0,
          this.data?.comparison_period_tenant_customers_avg_daily_spend ?? 0
        );
      else
        return this.useDiffCalculator(
          this.data?.tenant_customers_total_spend ?? 0,
          this.data?.comparison_period_tenant_customers_total_spend ?? 0
        );
    },
  },
  methods: {
    useDiffCalculator(newValArg, oldValArg) {
      let diffValue = 0;
      let diffSign = true;

      let newVal = parseFloat(newValArg);
      let oldVal = parseFloat(oldValArg);

      if (!isNaN(newVal) && !isNaN(oldVal)) {
        //calculate the diff %
        diffValue = (Math.abs(newVal) - Math.abs(oldVal)) / Math.abs(oldVal);
        diffValue = isFinite(diffValue) ? diffValue * 100 : 0;
        diffValue = Math.round(diffValue);

        //check if the diff is postive or negative
        diffSign = Math.sign(diffValue) < 0 ? false : true;
      }

      return { diffValue, diffSign };
    },
  },
};
</script>

<style scoped>
* {
  color: white !important;
}
.user-card-currency {
  font-size: 1.2rem;
}
.card-link {
  text-decoration: none !important;
  border-radius: 10px;
  padding: 5px 12px;
  color: var(--sea-two) !important;
  background-color: white;
  font-weight: 500;
}
.card-body h5 {
  font-weight: 500;
}
.card-body h2 {
  font-weight: 600;
}
.card-body h6 {
  background-color: var(--sea);
  border-radius: 8px;
  padding: 6px 9px;
  font-weight: 500;
}
.negative-green-card {
  background-color: rgba(223, 41, 53, 0.55) !important;
  color: white !important;
}
</style>
