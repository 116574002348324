<template>
  <div class="d-flex justify-content-start align-items-center">
    <div
      v-if="hasValidImage"
      class="profile-img-container"
      :class="{ small: size === 'small' }"
      :style="`background-image: url(${imageURL}) !important`"
    ></div>

    <div
      v-else
      class="profile-img-container default-user-img"
      :class="{ small: size === 'small' }"
    >
      <span>{{ nameLabel }}</span>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["checkIfImageExists"],
  props: {
    imageURL: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: "", // "small", ""
    },
    name: {
      type: String,
      default: "NA", // "small", ""
    },
  },
  data() {
    return {
      isValidImage: false,
      hasValidImage: false,
    };
  },
  computed: {
    nameLabel() {
      return this.getLabel(this.name);
    },
  },
  watch: {
    imageURL: {
      handler(newVal) {
        // [1] Check if image exist and valid
        this.checkIfImageExists(newVal, (exists) => {
          if (exists) {
            this.hasValidImage = true;
          } else {
            this.hasValidImage = false;
          }
        });
      },
      immediate: true,
    },
  },
  methods: {
    getLabel(userName) {
      if (userName !== null && userName.trim()) {
        let name = userName.trim().split(" ");
        name = name.filter((name) => name != "");

        if (name.length > 1) {
          const firstName = name?.[0]?.[0] ?? "";
          const lastName = name?.[1]?.[0] ?? "";

          return firstName?.toUpperCase() + lastName?.toUpperCase();
        }

        return (name?.[0]?.[0] ?? "")?.toUpperCase();
      }

      return "NA";
    },
  },
};
</script>

<style scoped>
.profile-img-container {
  display: inline-block;
  width: 150px;
  height: 150px;
  max-width: 150px;
  max-height: 150px;
  border-radius: 50%;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.profile-img-container.small {
  width: 34px;
  height: 34px;
  max-width: 34px;
  max-height: 34px;
}
.profile-img-container.small.default-user-img span {
  font-size: 1.1rem;
  font-weight: 400;
}

.default-user-img {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(151, 170, 189, 0.15);
}
.default-user-img span {
  opacity: 0.9;
  color: var(--sea);
  font-weight: 300;
  font-size: 5.5rem;
}
/* .has-img {
    background-image: url("https://media.licdn.com/dms/image/D4D03AQFQcbMrdG-LTQ/profile-displayphoto-shrink_400_400/0/1670094774138?e=1679529600&v=beta&t=otuiDOSc_8K4YYPJkt--1yWiPnwqobTQhky0M_kByZc");
  } */
</style>
