<template>
  <div
    ref="sideBar"
    class="
      sideBar
      d-flex
      flex-column
      justify-content-center
      align-items-center
      h-100
      pt-5
    "
  >
    <div class="d-flex">
      <div v-if="showSideBar">
        <img
          id="logo"
          src="@/assets/images/svg/logo.svg"
          class="full-logo cp"
          alt="lune-logo"
          @click="$router.push({ name: 'Overview' })"
        />
      </div>

      <div
        class="side-bar-toggle cp pb-5 px-4 ms-1"
        :class="{ show: showSideBar }"
        @click="toggleSideBar"
      >
        <img
          src="../assets/images/svg/new_sidebar/Expand.svg"
          alt="sideBar-btn"
        />
      </div>
    </div>

    <div
      class="
        w-100
        flex-grow-1
        d-flex
        flex-column
        justify-content-start
        align-items-stretch
      "
    >
      <div
        v-for="(routeGroup, groupIndex) in Object.values(routeGroups)"
        :key="routeGroup.id"
        class="
          w-100
          d-flex
          flex-column
          justify-content-start
          align-items-stretch
        "
      >
        <SideBarLink
          v-for="route in Object.values(routeGroup.children)"
          :key="route.id"
          :route="route"
          :show-side-bar="showSideBar"
        />

        <div
          v-if="groupIndex < Object.values(routeGroups).length - 1"
          class="h-line"
          :class="{ show: showSideBar }"
        ></div>
      </div>
      <div class="pb-2 flex-grow-1 d-flex flex-column justify-content-end">
        <LogOutRouteButton :show-side-bar="showSideBar" />
      </div>
    </div>
  </div>
</template>

<script>
import { routes } from "../data/options";
import SideBarLink from "../components/SideBarLink.vue";
import LogOutRouteButton from "./LogOutRouteButton.vue";

export default {
  components: { SideBarLink, LogOutRouteButton },
  data: function () {
    return {
      showSideBar: false,
      routeGroups: routes,
    };
  },
  methods: {
    toggleSideBar() {
      this.showSideBar = !this.showSideBar;
      this.$store.commit("updateShowSideBar", this.showSideBar);

      if (this.showSideBar) {
        this.$refs.sideBar.style.width = "225px";
      } else {
        this.$refs.sideBar.style.width = "85px";
      }
    },
  },
};
</script>

<style scoped>
.sideBar {
  width: 85px;
  /* border-right: 2px solid green; */
  background-color: white;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 4px 40px 0px rgba(204, 213, 212, 0.4);
  border-radius: 0px 30px 30px 0px;
  overflow-y: auto;
  overflow-x: hidden;
}
.side-link-icon {
  width: 38px !important;
}
.side-bar-toggle img {
  width: 18px;
  transition: var(--d-ease);
}
.side-bar-toggle.show img {
  transform: rotate(-180deg);
}
.h-line {
  height: 2px;
  background-color: #e6eaea;
  border-radius: 20px;
  margin: 0.35rem 1.5rem;
}
.h-line.show {
  margin: 0.35rem 1.3rem;
}
.full-logo {
  width: 9rem;
}
</style>
