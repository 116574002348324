<template>
  <PopUpFilterButton
    label="Brands"
    :count="$store.state.overViewStore.selectedBrandsFilter.length ?? 0"
    @click="togglePopUp()"
  />
  <BrandsPopupFiltersModal
    id="brands-popup-filter-modal"
    :show-modal="showPopUp"
    @closeModal="closeModal"
  />
</template>

<script>
import { ref } from "vue";
import BrandsPopupFiltersModal from "./BrandsPopupFiltersModal.vue";
import PopUpFilterButton from "./PopUpFilterButton.vue";

export default {
  components: {
    PopUpFilterButton,
    BrandsPopupFiltersModal,
  },
  setup() {
    const showPopUp = ref(false);

    // Feedback
    const closeModal = () => {
      showPopUp.value = false;
    };

    // =======
    const togglePopUp = () => {
      // as a param tx
      if (showPopUp.value) {
        showPopUp.value = false;
        return;
      }
      showPopUp.value = true;
    };
    // ======

    return { showPopUp, closeModal, togglePopUp };
  },
};
</script>

<style lang="scss" scoped>
.filter-button {
  // box-shadow: 0px 4px 40px rgba(204, 213, 212, 0.3) !important;
  background: white !important;
  border: 1px solid white !important;
  transition: var(--d-ease) !important;
  box-shadow: 0px 4px 40px rgba(204, 213, 212, 0.3) !important;
  border-radius: 10px !important;
  padding: 0.35rem 0.8rem;
  font-size: var(--fs-input) !important;
  font-weight: 400;

  &:hover {
    box-shadow: 0px 4px 40px rgba(155, 157, 157, 0.2) !important;
  }

  button {
    background-color: transparent;
    border: none;
  }
}
</style>
